import type { RangeKeyDict } from "react-date-range";

import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { DateRangePicker, defaultStaticRanges } from "@storybook";
import { endOfDay, isSameDay } from "date-fns";

import { SelectedDateRangeState } from "states";
import { useLocalStorage, useOutsideClick } from "hooks";

import { getDate } from "utils";

import "./date-picker.scss";
import { createdAt } from "constant";
import classNames from "classnames";

interface IDatePicker {
  onSubmit?: any;
  stateType?: any;
}
export const DatePicker: FC<IDatePicker> = ({ onSubmit, stateType }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [selectedDateRange, setSelectedDateRange] = useRecoilState<any>(
    stateType ?? SelectedDateRangeState
  );

  interface IDateRange {
    startDate: Date;
    endDate: Date;
    key: string;
  }
  const [range, setRange] = useState<IDateRange[]>();
  const { set: setLocalStorage } = useLocalStorage();

  useEffect(() => {
    setRange(selectedDateRange);
  }, [selectedDateRange]);

  useEffect(() => {
    if (range) {
      const isSame = isSameDay(range[0].startDate, range[0].endDate);
      if (!isSame || count > 1) {
        setLocalStorage("date-range", range[0]);
        setCount(0);
      }
    }
  }, [count, range, setLocalStorage]);

  const handleOpenDatePicker = useCallback(() => {
    setIsVisible(true);
  }, []);

  const handleChangeDateRange = useCallback(
    ({ selection }: RangeKeyDict) => {
      if (selection) {
        setRange([selection]);
        setCount((prev) => prev + 1);
      }
    },
    [setRange]
  );

  const handleApplyDateRange = useCallback(() => {
    setIsVisible(false);
    setSelectedDateRange(range);
    setLocalStorage("date-range", range[0]);
    if (onSubmit) onSubmit();
  }, [range, setLocalStorage, setSelectedDateRange, onSubmit]);

  const tilldate = endOfDay(new Date());

  const renderedDate = useMemo(() => {
    const { endDate, startDate } = selectedDateRange[0];
    const selectedRange = defaultStaticRanges(createdAt).find(
      date =>
        date.range().startDate === startDate && date.range().endDate === endDate
    );

    if (
			JSON.stringify(startDate) === JSON.stringify(createdAt) &&
			JSON.stringify(endDate) === JSON.stringify(tilldate)
		) {
			return 'ALL';
		}
    
    if (selectedRange?.label) {
      return selectedRange.label;
    }
    const isSame = isSameDay(startDate, endDate);
    const fromDate = getDate(startDate, "MMM dd");
    const toDate = getDate(endDate, "MMM dd");
    if (isSame) {
      return fromDate;
    }
    return (
			<>
				<div className="selected-range--date">{fromDate}</div>
				<div className="selected-range--to">to</div>
				<div className="selected-range--date">{toDate}</div>
			</>
		);

  }, [selectedDateRange, tilldate]);

  const selectedClass = classNames('calender-btn', {
		'calender-btn--active': isVisible,
	});

	const arrowIconClass = classNames('ri-arrow-down-s-line', {
		'arrow-icon--active': isVisible,
	});

	const ref: any = useRef();
	useOutsideClick(ref, () => {
		setIsVisible(false);
	});

  return (
    <div>
      <div onClick={handleOpenDatePicker} className={selectedClass}>
        <i className="ri-calendar-event-fill Date-picker--calendar-icon" />
        <span className="selected-range">
          {renderedDate}
        </span>
        <i className={arrowIconClass} />
      </div>
      <div className="picker-container" ref={ref}>
        {isVisible && (
          <DateRangePicker
            range={range}
            rangeColor={["#3c65d6"]}
            handleChangeRange={handleChangeDateRange}
            handleSubmit={handleApplyDateRange}
            setIsVisible={setIsVisible}
          />
        )}
      </div>
    </div>
  );
};
