import { useRecoilState, useSetRecoilState } from 'recoil';
import { useCallback, useMemo, useState } from 'react';

import { API_URL, message } from 'constant';
import { useNetwork, useNotification } from 'hooks';
import { IPermission } from 'views';
import {
	IRolePermissions,
	PermissionTableDataState,
	TemplateRoleState,
	UserRoleState,
} from '../store';

export const useRolepermission = () => {
	const {
		get: getTemplate,
		post: postUserRole,
		remove: removeUserRole,
	} = useNetwork();
	const { get: getPermissionList } = useNetwork();

	const { successNotification, errorNotification } = useNotification();

	const setTemplateRoleState = useSetRecoilState(TemplateRoleState);
	const setUserRoleState = useSetRecoilState(UserRoleState);
	const [permissionListTableData, setPermissionListTableData] = useRecoilState(
		PermissionTableDataState
	);
	const [inviteUserLoader, setInviteUserLoader] = useState(false);

	const fetchTemplates = async () => {
		const resp = await getTemplate(`${API_URL.ROLES}?type=template`);

		if (resp?.message === 'ok') {
			const { data = [] } = resp ?? {};
			setTemplateRoleState(data);
		}
	};

	const deleteUserRole = async (userId: string) => {
		const resp = await removeUserRole(`${API_URL.ROLES}/${userId}`);
		return resp;
	};

	const fetchUserRoles = async () => {
		const resp = await getTemplate(API_URL.USERS);

		if (resp?.message === 'ok') {
			const { data = [] } = resp ?? {};
			setUserRoleState(data);
		}
	};

	const createUserRole = async (payload) => {
		const resp = await postUserRole(API_URL.ROLES, payload);

		if (resp?.data) {
			await fetchUserRoles();
			return resp.data;
		} else {
			errorNotification(resp?.message);
		}
	};

	const inviteUser = async <T>(payload: T) => {
		setInviteUserLoader(true);
		const resp = await postUserRole(API_URL.USERS, payload);

		if (resp?.data?._id) {
			successNotification(message.ROLE_INVITE_SUCCESS);
		} else {
			errorNotification(resp?.message);
		}
		setInviteUserLoader(false);
		return resp?.data;
	};

	const fetchPermissionList = async () => {
		const resp = await getPermissionList(API_URL.PERMISSION_LIST);
		if (resp?.data?.length) {
			setPermissionListTableData(resp.data);
		}
	};

	const fullAccessCount = useMemo(() => {
		const sumOfSubModulesLengths = permissionListTableData.reduce(
			(sum, item) => {
				return sum + (item.subModules ? item.subModules.length : 0);
			},
			0
		);

		const totalPermission =
			(permissionListTableData.length + sumOfSubModulesLengths) * 2;

		return totalPermission;
	}, [permissionListTableData]);

	const getAllPermissionTag = useCallback(
		(
			role: IRolePermissions[] | IPermission[],
			isFullAccess: 'fullAccess' | 'list'
		) => {
			const permissionList: string[] = [];

			const processPermission = (permission) => {
				if (permission?.view) {
					const permissionName = `${permission?.name || permission?.module?.name} Read`;
					permissionList.push(permissionName);
				}
				if (permission?.edit) {
					const permissionName = `${permission?.name || permission?.module?.name} Write`;
					permissionList.push(permissionName);
				}
			};

			role?.forEach((permission) => {
				processPermission(permission);

				if (permission.subModules?.length) {
					permission.subModules.forEach((subModule) => {
						processPermission(subModule);
					});
				}

				if (permission.module?.subModules?.length) {
					permission.module.subModules.forEach((subModule) => {
						processPermission(subModule);
					});
				}
			});

			if (isFullAccess === 'fullAccess') {
				return permissionList.length === fullAccessCount;
			} else {
				return permissionList;
			}
		},
		[fullAccessCount]
	);

	return {
		fetchTemplates,
		fetchUserRoles,
		createUserRole,
		deleteUserRole,
		inviteUser,
		inviteUserLoader,
		fetchPermissionList,
		getAllPermissionTag,
	};
};
