import { API_URL, InviteEmailMessage } from "constant";
import { useNetwork } from "hooks";
import { useState } from "react";

export const useAcceptInvite = () => {
    const { get } = useNetwork();
    const [message, setMessage] = useState<string>('Loading');

    const verifyInvite = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const verificationCode = urlParams.get('verificationCode');

        if (verificationCode) {
            const invite = await get(`${API_URL.EMAIL_INVITE}/${verificationCode}`);

            if (invite.message === 'ok' || invite.message === 'Invite already accepted') {
                setMessage(invite.message === 'ok' ? InviteEmailMessage.InviteVerified : InviteEmailMessage.InviteAlreadyVerified);
                localStorage.setItem('token', JSON.stringify(verificationCode));
                setTimeout(() => {
                    window.location.href = "/login";
                }, 2000);
            } else {
                setMessage(InviteEmailMessage.InviteError);
            }
        } else {
            setMessage(InviteEmailMessage.InviteCodeMissing);
        }
    };

    return { message, verifyInvite };
};
