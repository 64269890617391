import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Button, Loader } from '@storybook';
import { onlyNumber } from 'utils';
import {
	IsLoginEmailVerifiedState,
	LoginCurrentStepState,
	LoginMaskPhoneCredState,
	LoginEmailCredState,
	useLogin,
	ICredentialPayload,
} from 'views';

import './otp-verify.scss';

interface IOtpObject {
	otp1: string;
	otp2: string;
	otp3: string;
	otp4: string;
}

const preOtpValue = {
	otp1: '',
	otp2: '',
	otp3: '',
	otp4: '',
};

export const OtpVerify = () => {
	// globle states
	const loginPhoneNumber = useRecoilValue(LoginMaskPhoneCredState);
	const loginEmail = useRecoilValue(LoginEmailCredState);
	const isLoginEmailVerified = useRecoilValue(IsLoginEmailVerifiedState);
	const setLoginStep = useSetRecoilState(LoginCurrentStepState);

	// local states
	const [otpValue, setOtpValue] = useState<IOtpObject>(preOtpValue);
	const [isVerifying, setIsVerifying] = useState(false);
	const [isResending, setIsResending] = useState(false);

	const { verifyEmailOtp, verifyEmail } = useLogin();

	const { phone: phoneNumber, countryCode, userId } = loginPhoneNumber ?? {};
	const { otp1, otp2, otp3, otp4 } = otpValue ?? {};
	const isInvalidOtp = !(otp1 && otp2 && otp3 && otp4);

	const otpLength = useMemo(
		() => Object.values(otpValue ?? {}).filter((el) => el !== '')?.length,
		[otpValue]
	);
	const handleSubmitOtp = useCallback(async () => {
		const otp = !isInvalidOtp && otp1 + otp2 + otp3 + otp4;
		setIsVerifying(true);
		if (!isLoginEmailVerified) {
			const payload: ICredentialPayload = {
				type: 'emailCode',
				email: loginEmail.trim().toLowerCase(),
				code: otp,
			};

			await verifyEmailOtp(payload);
		} else {
			const payload: ICredentialPayload = {
				type: 'phoneCode',
				code: otp,
				choice: 'accept',
				phone: phoneNumber,
				countryCode: countryCode,
			};
			await verifyEmailOtp(payload);
		}
		setIsVerifying(false);
	}, [
		isInvalidOtp,
		verifyEmailOtp,
		isLoginEmailVerified,
		countryCode,
		phoneNumber,
		loginEmail,
	]);

	const onHandleOtp = useCallback(
		(name: string, e: ChangeEvent<HTMLInputElement>) => {
			e.preventDefault();
			const { value } = e.target;
			if (value.length > 1) return;

			if (!onlyNumber(value)) {
				setOtpValue((prev: IOtpObject) => ({
					...prev,
					[name]: '',
				}));
			} else {
				setOtpValue((prev: IOtpObject) => ({
					...prev,
					[name]: value,
				}));
			}
		},
		[]
	);

	const handleResend = useCallback(async () => {
		if (isResending) return;
		setOtpValue(preOtpValue);
		setIsResending(true);
		if (!isLoginEmailVerified) {
			await verifyEmail();
		} else {
			const payload: ICredentialPayload = {
				type: 'verifyPhone',
				countryCode: countryCode,
				phone: phoneNumber,
				userId: userId,
			};
			await verifyEmailOtp(payload);
		}
		setIsResending(false);
	}, [isResending, isLoginEmailVerified, countryCode, phoneNumber, userId]);

	const inputFocus = useCallback(
		(event: any) => {
			if (event.target.value === '' || onlyNumber(event.target.value)) {
				if (event.key === 'Delete' || event.key === 'Backspace') {
					const next = event.target.tabIndex - 2;
					if (next > -1) {
						event.target.form.elements[next].focus();
					}
				} else if (!onlyNumber(event.target.value)) {
					return;
				} else {
					const next = event.target.tabIndex;
					if (next < 4) {
						event.target.form.elements[otpLength].focus();
					} else {
						handleSubmitOtp();
					}
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[otpLength]
	);

	const onModifyDetails = () => {
		setLoginStep('CREDS');
		setOtpValue(preOtpValue);
	};

	// Helper to return value with the right type: 'text' or 'number'
	const getRightValue = useCallback((str: string) => {
		const changedValue = str;
		return Number(changedValue) >= 0 ? changedValue : '';
	}, []);

	const handleOnPaste = useCallback(
		(e: React.ClipboardEvent<HTMLInputElement>) => {
			e.preventDefault();
			const pastedData = e.clipboardData
				.getData('text/plain')
				.trim()
				.slice(0, 4)
				.split('');
			if (pastedData) {
				Object.entries(otpValue).forEach(([key, value], index) => {
					if (index >= 0) {
						const changedValue = getRightValue(pastedData.shift() || value);
						if (changedValue) {
							setOtpValue((prev: IOtpObject) => ({
								...prev,
								[key]: changedValue,
							}));
						}
					}
				});
			}
		},
		[getRightValue, otpValue]
	);

	const otpInput = useMemo(() => {
		return Object.entries(otpValue).map(([key, value], index) => (
			<input
				type="tel"
				key={key}
				id={key}
				className={'OtpVerify--otpInput'}
				name={key}
				value={value}
				onChange={(e) => onHandleOtp(key, e)}
				onKeyUp={(e) => inputFocus(e)}
				maxLength={1}
				minLength={1}
				tabIndex={index + 1}
				onFocus={(e) => e.target.select}
				autoFocus={index === 0}
				onPaste={handleOnPaste}
			/>
		));
	}, [handleOnPaste, inputFocus, onHandleOtp, otpValue]);

	const renderMainComponent = useMemo(
		() => (
			<form className="OtpVerify--form">
				<div className="OtpVerify--otp">
					<div className="OtpVerify--otpDetail">OTP verification</div>
					<div className="OtpVerify--edit">
						We have sent you a confirmation code on :{' '}
						<div className="OtpVerify--edit__email">
							{isLoginEmailVerified
								? `${countryCode} ${phoneNumber}`
								: loginEmail}{' '}
							<span
								className="OtpVerify--edit__editBtn"
								onClick={onModifyDetails}
							>
								Edit
							</span>
						</div>
					</div>
					<div className="OtpVerify--container">
						<div className="OtpVerify--line">{otpInput}</div>
					</div>

					<Button
						handleClick={handleSubmitOtp}
						disabled={isInvalidOtp || isVerifying}
						label={isVerifying ? <Loader dimension={32} /> : 'Next'}
						type="button button__filled--primary OtpVerify--verifyButton"
					/>
					<div className="OtpVerify--resend">
						Didn’t received the code?
						<div
							onClick={handleResend}
							className={`OtpVerify--btn ${
								isResending ? 'OtpVerify--disabled' : ''
							}`}
						>
							Resend
						</div>
					</div>
				</div>
			</form>
		),
		[
			countryCode,
			phoneNumber,
			otpInput,
			isResending,
			isInvalidOtp,
			isVerifying,
			isLoginEmailVerified,
			loginEmail,
		]
	);

	return <>{renderMainComponent}</>;
};
