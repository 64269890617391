import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useCallback, useEffect, useState } from 'react';

import { Loader } from '@storybook';
import { useNetwork, useNotification } from 'hooks';
import { API_URL } from 'constant';
import {
	ApprovalStatusState,
	LoginCurrentStepState,
	LoginMaskPhoneCredState,
	VerificationIDState,
	useWebLogin,
} from 'views';

export const BiometricApprovalSent = () => {
	// globel state
	const maskPhoneCred = useRecoilValue(LoginMaskPhoneCredState);
	const setStep = useSetRecoilState(LoginCurrentStepState);
	const verificationID = useRecoilValue(VerificationIDState);
	const setVerificationStatus = useSetRecoilState(ApprovalStatusState);

	// local state
	const [checkStatus, setCheckStatus] = useState(true);
	const [isResending, setIsResending] = useState(false);

	// hooks
	const { get } = useNetwork();
	const { errorNotification } = useNotification();
	const { startRegisterDevice } = useWebLogin();

	const verifyPhone = useCallback(async () => {
		const verficationUrl = `${API_URL.CLIENT_VERIFY_PHONE}/${verificationID}`;
		const res = await get(verficationUrl);

		const { status } = res?.data ?? {};
		if (status === 'rejected' || status === 'approved') {
			setStep('CHOOSE_METHOD');
		}
		if (res?.errorCode === 400 && res?.errorData?.message) {
			errorNotification(res.errorData.message);
			setStep('CHOOSE_METHOD');
		}
		setVerificationStatus(status);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [get, setStep, setVerificationStatus, verificationID]);

	useEffect(() => {
		if (verificationID !== '') {
			verifyPhone();
		}
		setTimeout(() => {
			setCheckStatus((pre) => !pre);
		}, 4000);
		// eslint-disable-next-line
	}, [checkStatus, verificationID]);

	const handleResendLink = useCallback(async () => {
		if (isResending) return;
		setIsResending(true);
		await startRegisterDevice();
		setIsResending(false);
	}, [isResending]);

	return (
		<div className="mobile-link">
			<div className="mobile-link__wrapper">
				<div className="mobile-link__row">
					<div className="mobile-link__row mobile-link__row-1 mobile-link__vertical-space">
						<Loader className="loader-blue" dimension={60} />
					</div>
					<div className="mobile-link__title">Waiting for approval</div>
					<div className="mobile-link__description">
						We have sent you a link for approval on{' '}
						<span>
							{maskPhoneCred.countryCode} {maskPhoneCred.phone}
						</span>
					</div>
					<div className="mobile-link__description">
						Kindly click on the link provided in the text message and complete
						the verification process from there.
					</div>
				</div>
				<div className="mobile-link__description">
					Didn’t received the text message?{' '}
					<span
						className={`mobile-link__resend ${
							isResending ? 'mobile-link__resend_stop' : ''
						}`}
						onClick={handleResendLink}
					>
						Resend
					</span>
				</div>
			</div>
		</div>
	);
};
