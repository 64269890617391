import type { IIconBtn } from "./type";

import { FC } from "react";

import "./icon-btn.scss";

export const IconBtn: FC<IIconBtn> = ({
  btnClassName,
  handleClickIcon,
  isDisabled = false,
}) => {
  return (
    <button
      className={`icon-btn`}
      onClick={handleClickIcon}
      disabled={isDisabled}
    >
      <i className={`${btnClassName} icon icon-btn${isDisabled ? "--disabled" : ""}`} />
    </button>
  );
};
