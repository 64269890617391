import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import { REACT_APP_ENVIRONMENT } from 'envs';

import Modal from '@storybook/new-modal/modal';
import { Button, ReactSwitch, Loader } from '@storybook';
import { useNetwork } from 'hooks';
import { API_URL } from 'constant';
import { SessionTreeGraph } from '../businessSessionList';

import './session-details-model.scss';
import {IUserDetailsState, UserDetailsState} from "../../../dashboard";
import {useRecoilValue} from "recoil";

declare global {
	// eslint-disable-next-line @typescript-eslint/no-namespace
	namespace JSX {
		interface IntrinsicElements {
			'session-details': any;
			session_id: string;
			token: string;
			comments: string;
			env: string;
			type: string;
			compliance_data: string;
		}
	}
}

interface Props {
	isOpen: boolean;
	handleCloseViewModal: () => void;
	className: string;
	modalName: string;
	handleDownloadClick: () => void;
	isDownloadPending: boolean;
	sessionId: string;
	accessToken: string;
	sessionItemSelected?: {
		design?: {
			nodes: any;
			edges: any;
			conditions: any;
		};
		nodes?: any[];
		configuration?: Record<string, boolean>;
		type?: string;
	};
}

export const SessionDetailsModal: FC<Props> = ({
	isOpen,
	handleCloseViewModal,
	className,
	modalName,
	handleDownloadClick,
	isDownloadPending,
	sessionId = '',
	sessionItemSelected = {},
}) => {
	const [isComplexFlow, setIsComplexFlow] = useState(false);
	const [sessionToken, setSessionToken] = useState('');
	const userDetails = useRecoilValue(UserDetailsState);

	const { firstName, lastName } = useMemo(
		() => userDetails as IUserDetailsState,
		[userDetails]
	);

	const { get } = useNetwork();

	const getSessionToken = useCallback(async () => {
		const response = await get(API_URL.SIMPLICI_TOKEN);

		if (response?.data) {
			setSessionToken(response.data);
		}
	}, []);

	useEffect(() => {
		getSessionToken();
	}, []);

	const handleClick = () => {
		handleDownloadClick();
	};

	return (
		<Modal
			isOpen={isOpen}
			closeModal={handleCloseViewModal}
			className={className}
			modalName={modalName}
		>
			<>
				{!isComplexFlow ? (
					<div
						dangerouslySetInnerHTML={{
							__html: `<session-details
							session_id=${sessionId}
							token=${sessionToken}
							comments="true"
							env=${REACT_APP_ENVIRONMENT}
							type="sessions"
							compliance_data="false"
							url="/super-admin-session-details"
							super_user_name=${firstName + lastName}
							is_show_videos="true"
							is_pdf="false"
							action_disable="true"
                            only_activity="true"
							/>`,
						}}
					></div>
				) : (
					<div className="complex-session-view">
						<div className="complex-session-view_chart">
							<SessionTreeGraph details={sessionItemSelected} isSessionModal />
						</div>
					</div>
				)}

				<div className="session-download-btn">
					{sessionItemSelected?.type === 'complex' && (
						<div className="session-download-btn_toggle">
							<div className="session-download-btn_text">Tree Flow</div>
							<ReactSwitch
								id={''}
								onColor="#33b87a"
								checked={isComplexFlow}
								handleChange={() => setIsComplexFlow((prev: boolean) => !prev)}
							/>
						</div>
					)}
					<Button
						label={
							isDownloadPending ? (
								<Loader className="loader-white" dimension={20} />
							) : (
								'Download PDF'
							)
						}
						type="button button__filled--primary"
						handleClick={handleClick}
						disabled={isDownloadPending}
					/>
				</div>
			</>
		</Modal>
	);
};
