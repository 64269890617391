import type { IDateRangePicker } from "./type";

import { FC, useCallback, MouseEvent } from "react";
import { DateRange, DefinedRange, RangeKeyDict } from "react-date-range";
import { Button } from "@storybook";

import { defaultStaticRanges } from "./constants";

import "./date-range-picker.scss";
import { createdAt } from "constant";

export const DateRangePicker: FC<IDateRangePicker> = ({
  handleSubmit,
  range,
  handleChangeRange,
  rangeColor,
  setIsVisible,
}) => {
  const handleClose = useCallback(
    (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
      e.stopPropagation();
      setIsVisible(false);
    },
    [setIsVisible]
  );

  return (
    <>
      <div className="date-range-picker-overlay" onClick={handleClose} />
      <div className="date-range-picker">
        <DateRange
          className="calender"
          editableDateInputs={true}
          onChange={(item: RangeKeyDict) => handleChangeRange(item)}
          moveRangeOnFirstSelection={false}
          ranges={range}
          showMonthAndYearPickers={false}
          rangeColors={rangeColor}
          retainEndDateOnFirstSelection={false}
          fixedHeight={false}
          maxDate={new Date()}
          showDateDisplay={false}
          showPreview={true}
        />
        <div className="overlay-container">
          <DefinedRange
            className="overlay"
            onChange={(item: RangeKeyDict) => handleChangeRange(item)}
            ranges={range}
            rangeColors={rangeColor}
            inputRanges={[]}
            staticRanges={defaultStaticRanges(createdAt)}
          />
          <Button
            label="Apply"
            handleClick={handleSubmit}
            type="button__filled button__filled--primary button__large"
          />
        </div>
      </div>
    </>
  );
};
