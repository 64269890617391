import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Image } from '@storybook';

import { RoleAndPermissionNavigation } from '../../constants';
import {
	PermissionNavigation,
	SelectedUserRoleState,
	TemplateRoleState,
} from '../../store';

import './create-new-role-card.scss';
import { PermissionTag } from '../permission-tag';

export const CreateNewRoleCard = () => {
	const [selectedRole, setSelectedRole] = useState('');
	const setNavigation = useSetRecoilState(PermissionNavigation);
	const setSelectedUserRole = useSetRecoilState(SelectedUserRoleState);
	const templateRoleState = useRecoilValue(TemplateRoleState);

	const getPermission = useCallback((permission: any) => {
		return permission.map((item) => {
			const { module, view, edit, create } = item;
			const subModules = item.module.subModules.map((subModule) => ({
				subModuleId: subModule._id,
				view: subModule.view,
				edit: subModule.edit,
				create: subModule.create,
			}));

			return {
				module: module._id,
				subModules,
				view,
				edit,
				create,
			};
		});
	}, []);

	const handleSelectRole = useCallback(
		(role: any) => {
			setSelectedRole(role?._id);
			setSelectedUserRole((pre) => ({
				...pre,
				...role,
				permissions: getPermission(role?.permissions),
			}));
		},
		[setSelectedUserRole]
	);

	const selectedRoleActive = useCallback((isActive: boolean) => {
		return classNames('CreateNewRoleCard--container__card hover', {
			'section-border': isActive,
		});
	}, []);

	const defaultRoleActive = useCallback((isActive: boolean) => {
		return classNames('CreateNewRoleCard--container__addcard hover', {
			'section-border': isActive,
		});
	}, []);

	const handleOnClickAddNewRole = () => {
		setNavigation(RoleAndPermissionNavigation.AddNewRole);
		setSelectedUserRole(null);
	};

	return (
		<div className="CreateNewRoleCard--container">
			<div
				onClick={handleOnClickAddNewRole}
				className={defaultRoleActive(!selectedRole)}
			>
				<div className="CreateNewRoleCard--container__icon">
					<Image fileName="PlusIcon.svg" />
				</div>
				<div className="CreateNewRoleCard--container__label">Add New Role</div>
			</div>
			{(templateRoleState ?? [])?.map((role: any) => {
				const isActive = selectedRole === role?._id;
				return (
					<div
						className={selectedRoleActive(isActive)}
						key={role?._id}
						onClick={() => handleSelectRole(role)}
					>
						<div className="CreateNewRoleCard--container__card--radio">
							<input type="radio" checked={isActive} />
						</div>
						<div className="CreateNewRoleCard--container__card--data">
							<div className="CreateNewRoleCard--container__card--text">
								<div className="CreateNewRoleCard--container__card--header">
									{role?.name ?? 'NA'}
								</div>
								<div className="CreateNewRoleCard--container__card--desc">
									{role?.description ?? 'NA'}
								</div>
							</div>
							<PermissionTag role={role?.permissions} />
						</div>
					</div>
				);
			})}
		</div>
	);
};
