 /* eslint-disable */
export const isValidMobileNumber = (mobileNumber: string) => {
  const mob_regex = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/;
  const isValid = mob_regex.test(mobileNumber);
  if (isValid) {
    return true;
  } else {
    return false;
  }
};

export const isNumber = (number: string) => {
  const number_regex = /^[0-9]*$/;
  const isValid = number_regex.test(number);
  if (isValid) {
    return true;
  } else {
    return false;
  }
};

export const formatPhoneNumber = (mobileNumber: string) => {
  const cleaned = ('' + mobileNumber).replace(/\D/g, '');
  const match1 = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  const match2 = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/);
  const match3 = cleaned.match(/^(\d{3})(\d{3})(\d{2})$/);
  const match4 = cleaned.match(/^(\d{3})(\d{3})(\d{1})$/);
  const match5 = cleaned.match(/^(\d{3})(\d{2})(\d{1})$/);
  const match6 = cleaned.match(/^(\d{3})(\d{3})(\d{5})$/);
  const match7 = cleaned.match(/^(\d{3})(\d{3})(\d{6})$/);
  if (match1) {
    return `${match1[1]} ${match1[2]} ${match1[3]}`;
  } else if (match2) {
    return `${match2[1]} ${match2[2]} ${match2[3]}`;
  } else if (match3) {
    return `${match3[1]} ${match3[2]} ${match3[3]}`;
  } else if (match4) {
    return `${match4[1]} ${match4[2]} ${match4[3]}`;
  } else if (match5) {
    return `${match5[1]} ${match5[2]} ${match5[3]}`;
  } else if (match6) {
    return `${match6[1]} ${match6[2]} ${match6[3]}`;
  } else if (match7) {
    return `${match7[1]} ${match7[2]} ${match7[3]}`;
  }
  return mobileNumber;
};

export const validateInput = (name: string) => {
  return !!name.match(/(.*[a-zA-Z]){3}/i);
};


export const isValidURL = (str: string) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

// export const isUserLinkedInURLValid = (str: string) => {
//   var res = str?.match(
//     /^http[s]?:\/\/www\.linkedin\.com\/(in|pub|public-profile\/public-profile\/pub)\/([\w-#!:.?+=&%@!/]+)$/gm
//   );
//   if (res == null) return false;
//   else return true;
// };

// export const isCompanyLinkedInURLValid = (str: string) => {
//   var res = str.match(
//     /^http[s]?:\/\/www\.linkedin\.com\/(company)\/([\w-#!:.?+=&%@!/]+)$/gm
//   );
//   if (res == null) return false;
//   else return true;
// };

export const documentTypeRegex = /^application\/(pdf|msword|vnd\.openxmlformats-officedocument\.wordprocessingml\.document)$/;
