import { FC } from 'react';

import { Image } from '@storybook/image';

interface ITableEmptyMessage {
	height: string;
}

export const TableEmptyMessage: FC<ITableEmptyMessage> = ({ height }) => {
	return (
		<div className="empty-table" style={{ height }}>
			<Image fileName="illustration-1.svg" className="empty-table__image" />
			<div className="empty-table__message">No Data Available!</div>
		</div>
	);
};
