import { ICountries } from '@storybook/country-code';

import { ChangeEvent, FC, KeyboardEvent } from 'react';
import { CountryCode, Button } from '@storybook';

import './input.scss';

interface IInput {
	label: string;
	inputType: 'text' | 'number' | 'password';
	placeholder: string;
	handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
	value?: string | number;
	isCountryCodeVisible?: boolean;
	handleChangeCountry?: (e: ICountries) => void;
	isShowButton?: boolean;
	handleClickBtn?: () => void;
	errorMessage?: string;
	isError?: boolean;
	handleClearField?: () => void;
	handleKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
	allowClear?: boolean;
	id?: string;
	handleBlur?: () => void;
	prefixIcon?: string;
	disabled?: boolean;
	suffixIcon?: string;
	handleSuffixIcon?: () => void;
	inputName?: string;
	autofocus?: boolean;
	isRequired?: boolean;
}

export const Input: FC<IInput> = ({
	label,
	inputType,
	id,
	placeholder,
	handleChange,
	value,
	handleKeyPress,
	allowClear = false,
	handleClearField,
	isError = false,
	errorMessage,
	isShowButton,
	handleClickBtn,
	isCountryCodeVisible,
	handleChangeCountry,
	handleBlur,
	prefixIcon,
	disabled = false,
	suffixIcon,
	handleSuffixIcon,
	inputName,
	autofocus,
	isRequired = false,
}) => {
	return (
		<div className="input">
			{label && (
				<label htmlFor={id} className="input__label">
					{label}
					{isRequired && <span className="input__isRequired"> *</span>}
				</label>
			)}
			<div className="input__value-container">
				<div className="input__group">
					<div
						className={`input__text-field input__text-field${
							isError ? '--error' : ''
						}`}
					>
						{isCountryCodeVisible && (
							<CountryCode handleChangeCountry={handleChangeCountry} />
						)}
						{prefixIcon && <i className={prefixIcon} />}
						<input
							placeholder={placeholder}
							type={inputType}
							onBlur={handleBlur}
							id={id}
							className="input__field"
							onChange={handleChange}
							value={value}
							onKeyPress={handleKeyPress}
							disabled={disabled}
							name={inputName}
							autoFocus={autofocus}
						/>
						{allowClear && (
							<i
								className="ri-close-line input__delete"
								onClick={handleClearField}
							/>
						)}
						{suffixIcon && (
							<i
								className={`${suffixIcon} input__view`}
								onClick={handleSuffixIcon}
							/>
						)}
					</div>
					{isShowButton && (
						<Button
							label="Add"
							handleClick={handleClickBtn}
							type="button__outline button__large"
						/>
					)}
				</div>

				{isError && errorMessage && (
					<span className="input__error">{errorMessage}</span>
				)}
			</div>
		</div>
	);
};
