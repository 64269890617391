import { useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { DetailedRevenue } from 'components';
import { API_URL, OverheadConstant, overheadIcons } from 'constant';
import { useNetwork, useNotification } from 'hooks';
import { TransactionsTotalSummaryState } from 'views';

export const OverheadTransaction: React.FC<{ id: string }> = ({ id }) => {
	const [transactionsTotalSummary, setTransactionsTotalSummary] =
		useRecoilState(TransactionsTotalSummaryState);
	const { errorNotification } = useNotification();
	const {
		get: getOverHeadCard,
		data: overheadData,
		loading: overHeadDataLoading,
		error: overheadDataError,
		statusCode: overHeadStatusCode,
	} = useNetwork();

	useEffect(() => {
		if (!Object.keys(transactionsTotalSummary).length && id) {
			getOverHeadCard(`${API_URL.ANALYTICS}?business=${id}&type=overhead`);
		}
	}, [transactionsTotalSummary, id]);

	useEffect(() => {
		if (overheadData?.data?.length) {
			setTransactionsTotalSummary(overheadData?.data?.[0]);
		}
	}, [overheadData?.data]);

	const reformOverHeadDetail = useMemo(() => {
		const arr = [];
		const arrObject = Object.keys(transactionsTotalSummary);
		if (arrObject) {
			arrObject.forEach((items) => {
				if (items !== 'transactionsCount') {
					const val = OverheadConstant[items];
					const newObject = {
						title: val,
						total: transactionsTotalSummary[items],
						icon: overheadIcons[items],
					};
					arr.push(newObject);
				}
			});
		}

		return arr ?? [];
	}, [transactionsTotalSummary]);

	useEffect(() => {
		if (overHeadStatusCode && overHeadStatusCode !== 200) {
			errorNotification('We found some unexpected error');
		}

		if (overheadDataError) {
			errorNotification('We found some unexpected error');
		}
	}, [overHeadStatusCode, overheadDataError]);

	return (
		<DetailedRevenue
			loading={overHeadDataLoading}
			data={reformOverHeadDetail}
		/>
	);
};
