import { atom } from 'recoil';

interface ISubModule {
	name: string;
	isActive: boolean;
	_id: string;
	view: boolean;
	edit: boolean;
	create: boolean;
}

interface IModule {
	_id: string;
	name: string;
	subModules: ISubModule[];
	isActive: boolean;
	createdAt: string;
	updatedAt: string;
}

export interface IPermission {
	module: IModule;
	view: boolean;
	edit: boolean;
	create: boolean;
	_id: string;
}
interface IRole {
	_id: string;
	name: string;
	description: string;
	permissions: IPermission[];
	isActive: boolean;
	visibility: boolean;
	createdAt: string;
	updatedAt: string;
}
interface IRoles {
	role: IRole;
}
export interface IUserDetailsState {
	_id: string;
	firstName: string;
	lastName: string;
	email: string;
	countryCode: string;
	phone: string;
	roles: IRoles[];
}

export const UserDetailsState = atom<IUserDetailsState>({
	key: 'user-details-state-key',
	default: null,
});
