import { FC, useCallback, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { ReactResponsiveTable } from '@storybook';

import { Header } from 'components';
import { useNetwork } from 'hooks';
import { getDate, getTime, useFormatNumber } from 'utils';
import { API_URL, HEADER_TITLE } from 'constant';
import {
	TOTAL_TRANSACTION_LIST_TABLE,
	TransactionDetailListState,
} from 'views';

import './business-transaction-list.scss';

interface IOptionsRow {
	action: () => void;
	createdAt: string;
	name: string;
	_id: string;
}

interface IUserList {
	selectedId: string;
}

export const BusinessTransactionList: FC<IUserList> = ({ selectedId }) => {
	const [transactionListRows, setTransactionListRows] = useRecoilState(
		TransactionDetailListState
	);
	const {
		get: fetchBusinessTransactions,
		data: transactionData,
		loading: transactionDataLoading,
		isLoaded: transactionDataLoaded,
	} = useNetwork();

	const { numberDecimal } = useFormatNumber();

	useEffect(() => {
		fetchBusinessTransactions(`${API_URL.REVENUE}?business=${selectedId}`);
	}, [selectedId]);

	useEffect(() => {
		if (transactionData?.data?.length) {
			setTransactionListRows(transactionData.data);
		}
	}, [transactionData]);

	const convertToUpperCase = (str: string) => {
		const value = str?.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
		return value;
	};

	const paymentMethod = useCallback((method: string) => {
		if (!method) return '--';
		if (method === 'credit' || method === 'debit') return method + ' card';
		return method;
	}, []);

	const renderTransactions = useMemo(() => {
		const rows: IOptionsRow[] = [];
		transactionListRows?.forEach((item) => {
			if (item) {
				const row: any = {};
				TOTAL_TRANSACTION_LIST_TABLE.HEADER.forEach(({ key }) => {
					row.transactionId = item.transactionId;
					if (key === 'card') {
						const value = () => (
							<div className="status-wrapper">
								<span>{paymentMethod(item?.card) ?? '--'}</span>
							</div>
						);
						return (row[key] = value);
					}
					if (key === 'status') {
						const value = () => (
							<div className="status-wrapper">
								<span className={item[key]?.toLowerCase?.()} />
								<span>{item[key] ?? '--'}</span>
							</div>
						);
						return (row[key] = value);
					}

					if (key === 'type') {
						const value = () => (
							<div>
								<span>{convertToUpperCase(item[key]) ?? '--'}</span>
							</div>
						);
						return (row[key] = value);
					}

					if (key === 'amount') {
						const value = () => (
							<div>
								<span>${numberDecimal(item[key]) ?? '--'}</span>
							</div>
						);
						return (row[key] = value);
					}

					if (key === 'time') {
						const value = () => (
							<div className="row_content_wrapper">
								<div className="row_content_wrapper__title">
									{getDate(item[key])}
								</div>
								<div className="row_content_wrapper__sub_title">
									{getTime(item[key])}
								</div>
							</div>
						);
						return (row[key] = value);
					}
				});
				rows.push(row);
			}
		});

		return rows ?? [];
	}, [transactionListRows]);

	const renderBusinessTableModal = useMemo(() => {
		return (
			<ReactResponsiveTable
				isLoaded={transactionDataLoaded}
				isLoading={transactionDataLoading}
				column={TOTAL_TRANSACTION_LIST_TABLE.HEADER}
				rows={renderTransactions}
				height="calc(100vh - 285px)"
				emptyHeight={'calc(100vh - 255px)'}
				showSorting={true}
			/>
		);
	}, [renderTransactions, transactionDataLoading]);

	return (
		<div className="business-transaction-list">
			<Header title={HEADER_TITLE.TOTAL_TRANSACTION} />
			<div className="business-transaction-list--container">
				{renderBusinessTableModal}
			</div>
		</div>
	);
};
