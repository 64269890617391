export const validateEmail = (email) => {
	const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
	return !(!email || regex.test(email) === false);
};

export const isValidateEmail = (email: string) => {
	const forbiddenDomains =
	/(satschel)/i;
	return forbiddenDomains.test(email?.toLocaleLowerCase());
};
