import { useCallback } from 'react';

import { useNotification } from 'hooks';
import { ITreeGraphChart } from '../types';
import { sessionActionStatus } from './constants';

export const useTreeGraphChart = () => {
	const { errorNotification } = useNotification();

	const getTreeGraphConfig = useCallback(
		({
			isLoading,
			seriesData,
			title,
			isInverted,
			seriesLabelName,
			nodesColor,
			isInvite,
			onClick,
			isSessionModal,
		}: ITreeGraphChart) => {
			try {
				const chart =
					(window as any).Highcharts?.chart(title, {
						chart: {
							inverted: isInverted,

							height:
								isInvite || isSessionModal
									? seriesData.length === 1
										? 500
										: 600
									: seriesData.length > 6
									? 150
									: seriesData.length === 1
									? 34
									: 76,

							backgroundColor: 'transparent',
							overflow: 'auto',
							scrollbar: {
								enabled: true,
							},

							width:
								isInvite || isSessionModal
									? seriesData.length > 2
										? seriesData.length > 5
											? seriesData.length * 240
											: seriesData.length * 310
										: 700
									: seriesData.length > 2
									? seriesData.length * 110
									: seriesData.length === 1
									? 202
									: 330,

							marginTop:
								isInvite || isSessionModal
									? seriesData.length === 1
										? 220
										: 100
									: seriesData.length > 6
									? 40
									: 0,

							marginBottom: 0,
							marginRight: 0,

							marginLeft:
								isInvite || isSessionModal
									? seriesData.length === 1 && seriesData[0]?.length === 1
										? 270
										: 50
									: 0,
						},

						credits: {
							enabled: false,
						},
						title: {
							text: null,
						},

						exporting: { enabled: false },

						accessibility: {
							enabled: false,
						},

						plotOptions: {
							series: {
								states: {
									inactive: {
										opacity: 1,
									},
								},
							},
						},

						tooltip: {
							//@shivangi to show the tooltip on each nd every node in CF
							outside: false,
							useHTML: true,
							formatter: function (this: any) {
								if (this.point.formatPrefix === 'node') {
									return seriesLabelName(this.key);
								} else {
									return `${seriesLabelName(
										this.point.fromNode.id
									)} →  ${seriesLabelName(this.point.toNode.id)}`;
								}
							},
							fontSize: 5,
							style: {
								fontWeight: 600,
								textOutline: 'none',
							},

							crop: false,
							overflow: 'auto',
							delayForDisplay: 0,
							hideDelay: 0,
							split: true,
							shape: 'square',
						},

						series: [
							{
								type: 'organization',
								keys: ['from', 'to', 'linkColor'],
								data: seriesData,
								nodes: nodesColor,

								nodeWidth:
									isInvite || isSessionModal
										? 182
										: seriesData.length === 1 && seriesData[0]?.length === 1
										? 182
										: 70,

								height: isInvite || isSessionModal ? 60 : 30,
								colorByPoint: false,
								color: '#8c97b8',
								borderRadius: 0,
								borderColor: '',
								animation: false,
								linkLineWidth: 2,

								dataLabels: {
									color: '#ffffff',
									nodeFormatter: function (this: any) {
										const color = this.point.color;
										const isConfigured =
											color === sessionActionStatus.completed;

										return isInvite
											? `<div style="font-weight:500;font-size:12px;display:flex;flex-direction:column;justify-content: center;align-items:center;gap:8px;height:100%; width: 174px;"> 

										<div style=" width: 174px;white-space: nowrap;
										text-overflow: ellipsis;text-align:center;">${seriesLabelName(this.key)} </div>
											
											${
												seriesLabelName(this.key) === 'Sign Doc.'
													? `	<div style="font-weight:500;text-align:center;font-size:12px;background-color:#ffffff;cursor:pointer;width:174px;color:blue;padding:2px 0px;">${
															isConfigured ? 'Re-upload' : 'Upload'
													}</div>
											</div>`
													: ``
											}
											${
												seriesLabelName(this.key) === 'Fund (Pay In)' ||
												seriesLabelName(this.key) === 'Fund (Pay Out)'
													? `	<div style="font-weight:500;text-align:center;font-size:12px;background-color:#ffffff;cursor:pointer;width:174px;color:blue;padding:2px 0px;">${
															isConfigured ? 'Re-configure' : 'Configure'
													}</div>
											</div>`
													: ``
											}
									
									`
											: `<div style="font-weight:500;font-size:12px;display: flex;align-items: center;text-align:center;width:100%;height:100%"> 
									<div style="text-align:center;width:100%;">
									${seriesLabelName(this.key)} <div>
								</div>
							`;
									},
									style: {
										fontWeight: 500,
										fontSize: 1,
										textOutline: 'none',
										textOverflow: 'ellipsis',
									},
								},

								point: {
									events: {
										click: function (event: any) {
											event.preventDefault();
											onClick?.(event.point.id);
										},
									},
								},
							},
						],
					}) ?? {};

				if (isLoading) {
					chart.showLoading();
				} else {
					chart.hideLoading();
				}
			} catch (error) {
				errorNotification('Oops! Something went wrong.');
			}
		},
		[]
	);

	return { getTreeGraphConfig };
};
