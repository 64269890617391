import { FC, useEffect, useMemo } from 'react';

import { NumberWithCommas } from 'utils';
import { ChartXAxisActivity } from 'views';

const chartWidth = '100%';

interface IBarChart {
	isLoading: boolean;
	title: string;
	yAxis: number[];
	xAxis: string[];
	totalAmount: any;
}

export const BarChart: FC<IBarChart> = ({
	isLoading,
	title,
	yAxis,
	xAxis,
	totalAmount,
}) => {
	const totalNo = useMemo(() => {
		const value = yAxis?.reduce((a: number, b: number) => a + b, 0);
		return !value ? 10 : Math.max(...yAxis) + Math.max(...yAxis) / 10;
	}, [yAxis]);

	useEffect(() => {
		const charts =
			(window as any).Highcharts?.chart(title, {
				chart: {
					type: 'column',
					height: 300,
				},
				title: {
					text: null,
					align: 'left',
				},
				colors: ['#2269D3'],
				exporting: {
					enabled: false,
				},
				credits: {
					enabled: false,
				},

				xAxis: {
					categories: xAxis,
					crosshair: false,
					accessibility: {
						description: 'Steps',
					},
					labels: {
						style: {
							color: '#7C8398',
							fontWeight: 500,
						},
					},
				},
				yAxis: {
					min: 0,
					max: totalNo,
					title: {
						text: null,
					},
					labels: {
						style: {
							color: '#7C8398',
							fontWeight: 500,
						},
					},
				},
				tooltip: {
					formatter: function (this): string {
						return (
							`<span style="color:${this.color}">` +
							this.key +
							' : </span><b>' +
							NumberWithCommas(this.y) +
							'</b>'
						);
					},
				},
				plotOptions: {
					series: {
						maxPointWidth: 30,
					},
					column: {
						showInLegend: false,
						pointPadding: 0.2,
						borderWidth: 0,
						dataLabels: {
							enabled: true,
							formatter: function (): string {
								return (
									'$' +
									NumberWithCommas(
										totalAmount?.[ChartXAxisActivity?.[this?.x]]?.amount || 0
									)
								);
							},
							style: {
								color: '#7C8398',
								fontWeight: 600,
								fontSize: 12,
								textOutline: 'none',
							},
						},
					},
				},
				series: [
					{
						name: 'Total',
						data: yAxis,
					},
				],
			}) ?? {};

		if (isLoading) {
			charts?.showLoading();
		} else {
			charts?.hideLoading();
		}
	}, [yAxis, isLoading, title, xAxis, totalAmount]);

	return (
		<div
			style={{
				backgroundColor: 'transparent',
				width: chartWidth,
				overflowX: 'auto',
			}}
		>
			<div id={title} />
		</div>
	);
};
