export const activityAction = {
	LoggedIn: 'ri-logoin-circle-line',
	LoggedOut: 'ri-logout-circle-line',
	Locked: 'ri-lock-line',
	Unlocked: 'ri-lock-unlock-line',
	Removed: 'ri-user-unfollow-line',
	Approved: 'ri-checkbox-circle-line',
	Rejected: 'ri-indeterminate-circle-line',
	Invited: 'ri-user-add-line',
	Created: 'ri-user-line',
	priceUpdate: 'ri-user-settings-line',
	searchBusiness: 'ri-user-search-line',
	roleDelete: 'ri-delete-bin-6-line',
	roleCreate: 'ri-add-circle-line',
	sessionView: 'ri-eye-line',
	sessionUpdate: 'ri-swap-line',
	roleUpdate: 'ri-user-settings-fill',
	Unblocked: 'ri-lock-unlock-fill',
	Blocked: 'ri-lock-fill',
	completed: 'ri-checkbox-circle-line',
	rejected: 'ri-indeterminate-circle-line',
	deleted: 'ri-delete-bin-6-line'
};

export const activityActionBackground = {
	LoggedIn: '#7935DE0F',
	LoggedOut: '#E89C2C0F',
	Locked: '#2269D30F',
	Unlocked: '#2269D30F',
	Removed: '#C431310F',
	Approved: '#389F750F',
	Rejected: '#C431310F',
	Invited: '#7935DE0F',
	Created: '#7935DE0F',
	priceUpdate: '#E89C2C0F',
	searchBusiness: '#2269D30F',
	roleDelete: '#C431310F',
	roleCreate: '#389F750F',
	sessionView: '#E89C2C0F',
	sessionUpdate: '#2269D30F',
	roleUpdate: '#7935DE0F',
	Unblocked: '#389F750F',
	Blocked: '#C431310F',
	completed: '#389F750F',
	rejected: '#C431310F',
	deleted:'#C431310F',

};

export const ShowViewReport = ['completed','rejected']
