import { IBusinessStatus, ICompanyList } from 'views/businesses/types';

import { useCallback, useMemo, useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { Button, Image, Loader, TextArea } from '@storybook';

import { getDate, getTime } from 'utils';
import {
	BusinessComplianceDataLoadedState,
	BusinessComplianceDataState,
	OpenApprovalModalState,
} from '../states';
import { useNetwork, useNotification } from 'hooks';
import { API_URL, message } from 'constant';
import { PERMISSION_SCREEN, useDashboard } from 'views/dashboard';
import { NoData } from 'views/businesses/components/kyc-details';
import { useSessionApproval } from './useSessionApproval';
import { AccountType, BusinessStatus } from './constant';

export const useApprovalModal = ({
	SuperAdminData,
	setsuperAdminData,
	selectedItem,
}) => {
	// local states
	const [reason, setReason] = useState<string>('');
	const [isRejected, setIsRejected] = useState<string>(BusinessStatus.Pending);

	// globle states
	const [isOpenModal, setIsOpenModal] = useRecoilState(OpenApprovalModalState);
	const resetBusinessCompliance = useResetRecoilState(
		BusinessComplianceDataState
	);
	const resetBusinessComplianceLoaded = useResetRecoilState(
		BusinessComplianceDataLoadedState
	);

	// hooks
	const { get,loading } = useNetwork();
	const { patch: actionBusinessList, loading: isApproveRejectLoading } =
		useNetwork();
	const { errorNotification, successNotification } = useNotification();
	const { checkUserWritePermission } = useDashboard();
	const { approvalSessionPage, businessCompliance, businessComplianceLoaded } =
		useSessionApproval();

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(false, PERMISSION_SCREEN.Businesses),
		[checkUserWritePermission]
	);

	const { approveStatus, _id} = selectedItem ?? {};
	const { sessionId, token } = businessCompliance ?? {};

	const handleBack = useCallback(() => {
		setIsRejected(BusinessStatus.Pending);
	}, []);

	const handleAfterComplete = useCallback(() => {
		setIsOpenModal(false);
	}, []);

	const handleCloseModal = useCallback(() => {
		setIsOpenModal(false);
		setIsRejected(BusinessStatus.Pending);
		setReason('');
		setTimeout(() => {
			resetBusinessCompliance();
			resetBusinessComplianceLoaded();
		}, 1000);
	}, [isRejected]);

	const modalTitle = useMemo(
		() => (
			<div className="client-details-modal__header">
				<div className="client-details-modal__title">
					{selectedItem?.companyName as any}
				</div>
				{
					<div className="client-details-modal__sub-title">
						{getDate(selectedItem?.createdAt ?? '')} |{' '}
						{getTime(selectedItem?.createdAt ?? '')}
					</div>
				}
			</div>
		),
		[selectedItem]
	);

	const handleStatus = useCallback(
		async (statusCode: 'approved' | 'rejected') => {
			let payload: IBusinessStatus;
			if (statusCode === 'approved') {
				payload = {
					approveStatus: 'APPROVED',
				};
			} else {
				payload = {
					approveStatus: 'REJECTED',
					rejectReason: reason ?? '',
				};
			}
			if (selectedItem?._id) {
				await actionBusinessList(
					`${API_URL.BUSINESSES}/${selectedItem?._id}`,
					payload
				)
					.then((res) => {
						if (res.approveStatus) {
							setsuperAdminData(
								SuperAdminData?.map((items: ICompanyList) => {
									if (items._id === selectedItem._id) {
										return { ...items, approveStatus: res.approveStatus };
									} else {
										return items;
									}
								})
							);
							successNotification(`${res.message} successfully!`);
						} else {
							errorNotification(
								res.message ?? message.SomethingWentWrongMessage
							);
						}
					})
					.catch((error) => {
						errorNotification(error);
					})
					.finally(() => {
						setReason('');
						handleCloseModal();
					});
				handleAfterComplete();
			}
		},
		[
			SuperAdminData,
			actionBusinessList,
			handleAfterComplete,
			reason,
			selectedItem,
		]
	);

	const handleApproveBusiness = useCallback(async () => {
		const sessionResp = await get(
			`${API_URL.BUSSINESS_COMPLIIANCE}?businessId=${_id}`
		);
		const { physicalVerificationReportPath, accountType=''  } = sessionResp?.data[0] ?? {};
		if (!physicalVerificationReportPath && sessionResp?.message === 'ok' && accountType === AccountType.Entity) {
			setIsRejected(BusinessStatus.Completed);
		} else {
			handleStatus('approved');
		}
	}, [_id]);

	const renderApprovalRejectInterface = useCallback(() => {
		return (
			<div className="client-details-modal__rejected_wrapper">
				<div className="textArea_">
					<TextArea
						label={''}
						handleChange={(e) => setReason(e.target.value)}
						value={reason}
						placeholder="Write a note...!!"
						row={12}
						style={{ height: '50vh' }}
					/>
				</div>
				<div className="client-details-modal__btn_wrapper">
					<Button
						type="button button__filled--secondary"
						label="Back"
						handleClick={handleBack}
					/>
					<Button
						type="button button__filled--primary"
						label={
							isApproveRejectLoading ? (
								<Loader className="loader-white" dimension={20} />
							) : (
								'Save'
							)
						}
						handleClick={() => handleStatus('rejected')}
						disabled={!reason.length || isApproveRejectLoading}
					/>
				</div>
			</div>
		);
	}, [isRejected, reason,isApproveRejectLoading]);

	const approvalFooter = useMemo(
		() =>
			isUserPermissionWrite && (
				<div className="ApprovalModal__btn_wrapper">
					<Button
						type="button button__filled--danger"
						label={
							approveStatus?.toLowerCase() === 'rejected'
								? 'Rejected'
								: 'Reject'
						}
						handleClick={() => setIsRejected(BusinessStatus.Rejected)}
						disabled={approveStatus?.toLowerCase() === 'rejected'}
					/>
					<Button
						type="button button__filled--success"
						label={
							(isApproveRejectLoading || loading)? (
								<Loader className="loader-white" dimension={20} />
							) : approveStatus?.toLowerCase() === 'approved' ? (
								'Approved'
							) : (
								'Approve'
							)
						}
						handleClick={handleApproveBusiness}
						disabled={approveStatus?.toLowerCase() === 'approved' || isApproveRejectLoading || loading}
					/>
				</div>
			),
		[isApproveRejectLoading,loading]
	);

	const renderNoDocumentUploaded = useCallback(() => {
		return (
			<div className="ApprovalModal--container">
				<div className="NoPhysicalDocUploaded">
					<div>
						<Image fileName="NoDocumentUploaded.svg" />
					</div>
					<div className="NoPhysicalDocUploaded--header">
						Physical verification report is not uploaded{' '}
					</div>
					<div className="NoPhysicalDocUploaded--subHeader">
						The physical verification report for this user has not been
						uploaded. Do you still want to proceed with the approval.
					</div>
					<div className="NoPhysicalDocUploaded--btn">
						<Button
							type="button button__filled btn-danger"
							label="Reject"
							handleClick={() => setIsRejected(BusinessStatus.Rejected)}
						/>
						<Button
							type="button button__filled btn-success"
							label={
								isApproveRejectLoading ? (
									<Loader className="loader-white" dimension={20} />
								) : 
									'Approve'
							}
							handleClick={() => handleStatus('approved')}
						/>
					</div>
				</div>
			</div>
		);
	}, [isApproveRejectLoading]);

	const modalBody = useMemo(() => {
		if (!businessComplianceLoaded) {
			return (
				<div className="ApprovalModal__loader-wrapper">
					<Loader />
				</div>
			);
		} else if (!sessionId || !token) {
			return (
				<div className="ApprovalModal__no-data">
					<NoData />
				</div>
			);
		} else if (isRejected === BusinessStatus.Rejected) {
			return renderApprovalRejectInterface();
		} else if (isRejected === BusinessStatus.Completed) {
			return renderNoDocumentUploaded();
		} else {
			return (
				<>
					{approvalSessionPage}
					{approvalFooter}
				</>
			);
		}
	}, [
		isRejected,
		renderApprovalRejectInterface,
		approvalSessionPage,
		approvalFooter,
		businessComplianceLoaded,
		businessCompliance,
	]);

	return {
		isRejected,
		modalTitle,
		renderApprovalRejectInterface,
		modalBody,
		handleCloseModal,
		isOpenModal,
	};
};
