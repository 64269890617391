export interface IBusinessStatus {
    Completed: string;
    Pending: string;
    Rejected: string;
  }

export const BusinessStatus:IBusinessStatus = {
    Completed:'completed',
    Pending:'Pending',
    Rejected:'rejected'
}

export const AccountType = {
  Individual: 'individual',
  Entity: 'entity',
}