import type {
  ILoginState,
  IUserState,
  ICompanyDetails,
  IPayment,
  IInvite,
} from ".";

import { atom } from "recoil";

import { ROLE } from "constant";

const getCookie = (key: string) => {
  const name = key + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const decodeCookie = decodedCookie.split(";");
  for (let i = 0; i < decodeCookie.length; i++) {
    let decodeKey = decodeCookie[i];
    while (decodeKey.charAt(0) === " ") {
      decodeKey = decodeKey.substring(1);
    }
    if (decodeKey.indexOf(name) === 0) {
      return decodeKey.substring(name.length, decodeKey.length);
    }
  }
  return "";
};

const getUser = () => {
  try {
    const localStorageData = JSON.parse((getCookie("user") as any) ?? {});
    const {
      accessToken,
      role,
      loggedIn,
      developer,
      plan,
      business,
      status,
      linkedInUrl,
    } = localStorageData ?? {};
    const { OWNER } = ROLE;
    const checkoutStorageData = JSON.parse(
      localStorage.getItem("checkout") as any
    );
    const { id, paymentStatus, paymentIntent } = checkoutStorageData ?? {};
    return {
      user: {
        accessToken: accessToken ?? "",
        loggedIn: loggedIn ?? false,
        role: role ?? OWNER,
        developer: developer ?? ",",
        plan: plan ?? "",
        webToken: "",
        business: business,
        status,
        linkedInUrl: linkedInUrl ?? "",
      },
      payment: {
        id: id ?? "",
        paymentStatus: paymentStatus ?? "",
        paymentIntent: paymentIntent ?? "",
      },
    };
  } catch (error) {
    // DOMException: Failed to read the 'localStorage' property from 'Window': Access is denied for this document.
    if (/Access is denied/gi.test(error.message)) {
      // TODO: @awadhesh add logic to get user data (API call)
    }
    return {
      user: {
        accessToken: "",
        loggedIn: false,
        role: ROLE.OWNER,
        developer: false,
        business: "",
        plan: "",
        webToken: "",
        status: "",
        linkedInUrl: "",
      },
      payment: {
        id: "",
        paymentStatus: "",
        paymentIntent: "",
      },
    };
  }
};

export const loginState = atom<ILoginState>({
  key: "login",
  default: getUser().user,
});

export const userState = atom<IUserState>({
  key: "user-state",
  default: {
    userId: "",
  },
});

export const companyDetailsState = atom<ICompanyDetails>({
  key: "company-state",
  default: {
    name: "",
    address: {
      address1: "",
      address2: "",
      zip: "",
      city: "",
      state: "",
    },
    logo: "",
    linkedInUrl: "",
    purposeOfUsing: "",
    billingAddress: {
      address1: "",
      address2: "",
      zip: "",
      city: "",
      state: "",
    },
  },
});

export const paymentState = atom<IPayment>({
  key: "payment-state",
  default: getUser().payment,
});

export const InviteDetailsState = atom<IInvite>({
  key: "invite-user-role-state",
  default: {
    firstName: "",
    lastName: "",
    email: "",
    role: "ADMIN",
    funds: [],
    developer: false,
    isAgentPermitted: false,
  },
});



export const OtpEmailState = atom<string>({
  key: "otp-email-state",
  default: "",
});

export const ForgotPageState = atom<string>({
  key: "forgot-page-state",
  default: "forgot",
});

export const isLoggedInState = atom({
  key: "is-loggedin-state",
  default: false,
});

export const userToken = atom<string>({
  key: "user-token",
  default: "",
});

export const FilterSessionTypeTableState = atom<any>({
  key: "Filter-Session-Type-Table-State",
  default: {
    label: "Active",
    value: "true",
  },
});
