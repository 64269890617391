import { CreateNewRoleCard } from '../create-new-role-card';

export const CreateNewRole = () => {
	return (
		<div className="CreateNewRole--container__body">
			<div className="CreateNewRole--container__body--text">
				<div className="CreateNewRole--container__body--text__header">
					Create new role from scratch or use predefined role template
				</div>
				<div className="CreateNewRole--container__body--text__desc">
					You can either create a new role or select a predefined role that you
					can customize as needed.
				</div>
			</div>
			<CreateNewRoleCard />
		</div>
	);
};
