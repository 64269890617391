import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Button, Image, Input, Loader } from '@storybook';
import { validateEmail } from 'constant';
import {
	LoginCurrentStepState,
	LoginEmailCredState,
	useLogin,
} from 'views';
import { useNotification } from 'hooks';

const isEnterEmailVisible = localStorage.getItem('show_email') ?? false;

export const EnterEmail = () => {
	const params = new URLSearchParams(window.location.search);
	const googleMail = params.get('email');
	const error = params.get('error');

	const loginCurrentStep = useRecoilValue(LoginCurrentStepState);
	const [loginEmail, setLoginEmail] = useRecoilState(LoginEmailCredState);

	const { verifyEmail, loading, loginWithSocial, isSocialLoading } = useLogin();
	const { errorNotification } = useNotification();

	useEffect(() => {
		if (error) {
			errorNotification(decodeURIComponent(error));
		}
		if (googleMail) {
			setLoginEmail(googleMail);
		}
	}, [googleMail]);

	useEffect(() => {
		if ( googleMail && loginEmail) {
			verifyEmail();
		}
	}, [ googleMail, loginEmail]);

	const handleOnChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setLoginEmail(value);
	}, []);

	const renderButtonLabel = useMemo(() => {
		if (loading) {
			return <Loader className="loader-white" dimension={20} />;
		}
		if (loginCurrentStep === 'CREDS') return 'Next';
	}, [loginCurrentStep, loading]);

	const isDisabled = useMemo(() => {
		const isValidEmail = !!validateEmail(loginEmail);
		if (loading) {
			return true;
		}
		if (loginCurrentStep === 'CREDS') {
			if (isValidEmail) return false;
			return true;
		}
	}, [loginEmail, loginCurrentStep, loading]);

	const renderLogin = useMemo(() => {
		return (
			<div className="signin-wrapper">
				<div className="signin-wrapper__inner">
					<div className="signin-wrapper__inner__form">
						<div>
							<div className="signin-wrapper__inner__form__title">Log in</div>
							<div className="signin-wrapper__inner__form__subtitle">
								Please log in to your account.
							</div>
						</div>
						<div
							className="signin-wrapper__inner__form__google"
							onClick={() => loginWithSocial()}
						>
							{isSocialLoading ? (
								<Loader dimension={26} />
							) : (
								<>
									<Image
										fileName="google.svg"
										className="signin-wrapper__inner__form__google_image"
									/>
									Sign in with Google
								</>
							)}
						</div>
						{isEnterEmailVisible && (
							<>
								<div className="signin-wrapper__inner__form__OR">
									<hr />
									OR
									<hr />
								</div>
								<Input
									label="Email"
									handleChange={handleOnChange}
									placeholder=""
									inputName="email"
									inputType="text"
									autofocus={true}
									value={loginEmail}
								/>
								<Button
									label={renderButtonLabel}
									type="button button__filled--primary button__large button__block "
									disabled={isDisabled}
									handleClick={() => verifyEmail()}
								/>
							</>
						)}
					</div>
				</div>
			</div>
		);
	}, [isDisabled, renderButtonLabel, loginEmail, isSocialLoading]);

	return <>{renderLogin}</>;
};
