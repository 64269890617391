import { ChangeEvent, FC } from "react";

import "./text-area.scss";

interface ITextArea {
	label: string;
	handleChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
	value: string;
	placeholder: string;
	isError?: boolean;
	row?: number;
	style?: any;
	isRequired?: boolean;
	errorMessage?: string;
}

export const TextArea: FC<ITextArea> = ({
	label,
	handleChange,
	value,
	placeholder,
	isError = false,
	row = 6,
	style,
	isRequired = false,
	errorMessage
}) => {
	return (
		<div className="input">
			<div className="input__label">
				{label}
				{isRequired && <span className="input__isRequired"> *</span>}
			</div>
			<div
				className={`input__text-field input__text-field${
          isError ? "--error" : ""
				}`}
			>
				<textarea
					name="some area"
					className="input__field text-area-field"
					rows={row}
					value={value}
					placeholder={placeholder}
					onChange={handleChange}
					style={style}
				/>
			</div>
			{isError && errorMessage && (
				<span className="input__error">{errorMessage}</span>
			)}
		</div>
	);
};
