import { useCallback } from 'react';
import './audit-card-loader.scss';

export const AuditSkeleton = ({ count }) => {
	const renderSkeleton = useCallback(() => {
		return Array(count)
			.fill(1)
			.map((index) => {
				return (
					<div key={count++ + '_' + index} className="skeleton">
						<div className="skeleton-img"></div>
						<div className="skeleton-text">
							<div className="skeleton-description"></div>
							<div className="skeleton-time"></div>
						</div>
					</div>
				);
			});
	}, [count]);
	return <div className="audit-skelton--container">{renderSkeleton()}</div>;
};
