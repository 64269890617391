import { ChangeEvent, FC, useCallback, useState } from 'react';
import { Button, Input, Loader, MobileInput } from '@storybook';
import Modal from '@storybook/new-modal/modal';

import { onlyLetter } from 'utils';
import { isValidateEmail } from 'constant';
import { InviteFormInput } from 'views';
import { Header } from './header';

import './invite-user-form.scss';

interface IInviteUserForm {
	handleInviteUser: (val: InviteFormInput) => void;
	handleCloseModal: () => void;
	isApiCalling: boolean;
	isInviteModalOpen: boolean;
}
export const InviteUserForm: FC<IInviteUserForm> = ({
	handleInviteUser,
	handleCloseModal,
	isApiCalling,
	isInviteModalOpen,
}) => {
	const [inviteUserForm, setInviteUserForm] = useState({
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		countryCode: '+1',
	});
	const [inviteErrorMsg, setInviteErrorMsg] = useState({
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
	});

	const handleChangeInput = useCallback(
		(
			e: ChangeEvent<HTMLInputElement & { countryCode?: string }>,
			name: string
		) => {
			const { value, countryCode } = e.target;

			if (name === 'phone') {
				setInviteUserForm((prev) => ({ ...prev, countryCode }));
			}
			setInviteUserForm((prev) => ({ ...prev, [name]: value ?? countryCode }));
			setInviteErrorMsg((pre) => ({
				...pre,
				[name]: '',
			}));
			if (name === 'firstName') {
				if (!onlyLetter(value)) {
					setInviteErrorMsg((pre) => ({
						...pre,
						['firstName']: 'Only letters are allowed in the field',
					}));
				}
			}
			if (name === 'lastName') {
				if (!onlyLetter(value)) {
					setInviteErrorMsg((pre) => ({
						...pre,
						['lastName']: 'Only letters are allowed in the field',
					}));
				}
			}
		},
		[]
	);

	const handleSubmit = useCallback(() => {
		if (
			inviteUserForm.phone.length < 7 ||
			inviteUserForm.firstName.length < 3 ||
			inviteUserForm.lastName.length < 3 ||
			!isValidateEmail(inviteUserForm.email) ||
			!onlyLetter(inviteUserForm.firstName) ||
			!onlyLetter(inviteUserForm.lastName)
		) {
			if (inviteUserForm.phone.length < 7) {
				setInviteErrorMsg((pre) => ({
					...pre,
					['phone']: 'Please enter a valid phone number',
				}));
			}

			if (!onlyLetter(inviteUserForm.firstName)) {
				setInviteErrorMsg((pre) => ({
					...pre,
					['firstName']: 'Only letters are allowed in the field',
				}));
			}

			if (!onlyLetter(inviteUserForm.lastName)) {
				setInviteErrorMsg((pre) => ({
					...pre,
					['lastName']: 'Only letters are allowed in the field',
				}));
			}

			if (inviteUserForm.firstName.length < 3) {
				setInviteErrorMsg((pre) => ({
					...pre,
					['firstName']: 'Please enter a more than 3 characters',
				}));
			}
			if (inviteUserForm.lastName.length < 3) {
				setInviteErrorMsg((pre) => ({
					...pre,
					['lastName']: 'Please enter a more than 3 characters',
				}));
			}

			if (!isValidateEmail(inviteUserForm.email)) {
				setInviteErrorMsg((pre) => ({
					...pre,
					['email']: `Please enter satschel domain only`,
				}));
			}
			return;
		}
		handleInviteUser(inviteUserForm);
	}, [inviteUserForm]);

	return (
		<Modal
			isOpen={isInviteModalOpen}
			closeModal={handleCloseModal}
			modalName="Invite user form"
			optionalClassName="InviteUserFormModal"
		>
			<Header
				head="Invite User"
				desc="Assign roles to users for streamlined access"
			/>
			<div className="InviteUserFormModal--body">
				<div className="InviteUserFormModal--row-2">
					<Input
						placeholder="Enter first name"
						label="First Name"
						inputType="text"
						handleChange={(e) => handleChangeInput(e, 'firstName')}
						value={inviteUserForm.firstName}
						isError={!!inviteErrorMsg.firstName}
						errorMessage={inviteErrorMsg.firstName}
					/>
					<Input
						placeholder="Enter last name"
						label="Last Name"
						inputType="text"
						handleChange={(e) => handleChangeInput(e, 'lastName')}
						value={inviteUserForm.lastName}
						isError={!!inviteErrorMsg.lastName}
						errorMessage={inviteErrorMsg.lastName}
					/>
				</div>

				<MobileInput
					handleChange={(e) => handleChangeInput(e, 'phone')}
					label="Phone Number"
					placeholder="+1 (xxx) xxx-xxxx"
					value={inviteUserForm.countryCode + inviteUserForm.phone}
					isError={!!inviteErrorMsg.phone}
					errorMessage={inviteErrorMsg.phone}
					enableSearch
				/>

				<Input
					label="Email"
					placeholder="Enter email address"
					inputType="text"
					handleChange={(e) => handleChangeInput(e, 'email')}
					value={inviteUserForm.email}
					isError={!!inviteErrorMsg.email}
					errorMessage={inviteErrorMsg.email}
				/>
			</div>

			<div className="InviteUserFormModal--buttons">
				<Button
					label="Cancel"
					type="button__filled--secondary"
					handleClick={handleCloseModal}
				/>
				<Button
					label="Invite"
					type="button__filled--primary"
					handleClick={handleSubmit}
					disabled={isApiCalling}
					loader={isApiCalling ? <Loader dimension={20} /> : <></>}
				/>
			</div>
		</Modal>
	);
};
