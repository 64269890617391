import { atom } from 'recoil';

export const OpenApprovalModalState = atom<boolean>({
	key: 'open-approval-modal-state-key',
	default: false,
});
export const BusinessComplianceDataState = atom<{
	sessionId: string;
	token: string;
	businessId:string
}>({
	key: 'business-compliance-data-state-key',
	default: {
		token: '',
		sessionId: '',
		businessId:''
	},
});

export const BusinessComplianceDataLoadedState = atom({
	key: 'business-compliance-data-loaded-state-key',
	default: false,
});
