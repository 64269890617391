import { FC, useMemo } from 'react';
import Tippy from '@tippyjs/react';

import { IPermission, IRolePermissions, useRolepermission } from 'views';

import './permission-tag.scss';

interface PermissionTagProps {
	role: IRolePermissions[] | IPermission[];
}

export const PermissionTag: FC<PermissionTagProps> = ({ role }) => {
	const { getAllPermissionTag } = useRolepermission();

	const isFullAccess = useMemo(() => {
		return getAllPermissionTag(role, 'fullAccess');
	}, [role]);

	const permissionList = useMemo(() => {
		return getAllPermissionTag(role, 'list');
	}, [role]);

	const getAllPermissionsList = useMemo(() => {
		const havePermission = Array.isArray(permissionList)
			? permissionList?.length
			: 0;

		if (havePermission === 0) return <></>;
		if (havePermission < 3)
			return (
				<>
					{(Array.isArray(permissionList) ? permissionList : [])?.map(
						(action, index) => (
							<div
								className="PermisssionTag--container__colorPurple"
								key={index?.toString()}
							>
								{action}
							</div>
						)
					)}
				</>
			);
		if (havePermission >= 3) {
			return (
				<>
					{(Array.isArray(permissionList) ? permissionList : [])
						?.filter((_, index) => index < 2)
						?.map((action, index) => (
							<div
								className="PermisssionTag--container__color"
								key={index?.toString()}
							>
								{action}
							</div>
						))}
					<Tippy
						content={(Array.isArray(permissionList) ? permissionList : []).map(
							(item, index) => {
								return (
									index > 1 && (
										<div key={item} className="tooltip-userRole-list">
											• {item}
										</div>
									)
								);
							}
						)}
					>
						<span className="PermisssionTag--container__number">
							+{havePermission - 2}
						</span>
					</Tippy>
				</>
			);
		}
		return <></>;
	}, [permissionList]);

	return (
		<div className="PermisssionTag--container">
			{isFullAccess ? (
				<div className="PermisssionTag--container__label">All access</div>
			) : (
				getAllPermissionsList
			)}
		</div>
	);
};
