import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Image, Loader, ReactResponsiveTable } from '@storybook';

import { PERMISSION_SCREEN, useDashboard } from 'views';
import { useNetwork, useNotification } from 'hooks';
import { API_URL } from 'constant';
import { useFetchUserList } from 'views/businesses/hooks';
import { IOptionsRow, IUserList, IUserRows } from './types';
import { ICompanyList } from 'views/types';
import { BUSINESS_USER_LIST_TABLE_HEADER } from '../../constants';

import './business-user-list.scss';

export const BusinessUserList: FC<IUserList> = ({ data }) => {
	const selectedId = data?.[0]?._id;
	const { isLock } = data?.[0] ?? {};
	const [usersList, setUsersList] = useState([]);
	const [userListRows, setUserListRows] = useState<IOptionsRow[]>([]);
	const { errorNotification, successNotification } = useNotification();
	const [modal, setModal] = useState<boolean>(false);
	const [userName, setUserName] = useState(null);
	const [isLoaded, setIsLoaded] = useState<boolean>(false);
	const [lockSelectedId, setLockSelectedId] = useState<string>('');
	const [deleteIndex, setDeleteIndex] = useState<number>();
	const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
	const {
		UserListData,
		//userListStatus,
		userListLoaded,
		userListLoading,
		userListError,
		userlistStatusCode,
	} = useFetchUserList(selectedId);

	const { remove: deleteUser } = useNetwork();
	const { patch: actionUserList, loading: isLockLoading } = useNetwork();
	const { checkUserWritePermission } = useDashboard();

	useEffect(() => {
		if (UserListData?.data.length) {
			if (userlistStatusCode) {
				setUsersList(UserListData.data);
			} else {
				errorNotification('Failed to load user details.');
			}
		} else if (userListLoaded && userListError) {
			errorNotification('Failed to load user details.');
		}
	}, [UserListData, userListError, userListLoaded]);

	const isUserPermissionWrite = useMemo(
		() =>
			checkUserWritePermission(
				true,
				PERMISSION_SCREEN.Businesses,
				PERMISSION_SCREEN.User_List
			),
		[checkUserWritePermission]
	);

	const handleOnClickLockUnlock = useCallback(async (item, index) => {
		if (isLock) return;
		setLockSelectedId(item._id);
		const payload = {
			isLock: !item.isLock,
		};

		if (item && item?._id) {
			await actionUserList(`${API_URL.SIMPLICI_USERS}/${item?._id}`, payload)
				.then((res) => {
					if (res.success) {
						setUsersList((prev: ICompanyList[]) => {
							const newObj = JSON.parse(JSON.stringify(prev));
							newObj[index].isLock = !newObj[index].isLock;
							return [...newObj];
						});
						successNotification(
							`${item.firstName} ${item.lastName}	is					
							${!item.isLock ? 'locked' : 'unlocked'} successfully!`
						);
					} else errorNotification(res.message);
				})
				.catch((error) => {
					errorNotification(error);
				});
		}
	}, []);

	const handleonClickDelete = useCallback(
		(index, item) => {
			setUserName(item);
			setDeleteIndex(index);
			setModal(true);
		},
		[userName, deleteIndex, modal]
	);

	useEffect(() => {
		const rows: IOptionsRow[] = [];
		usersList?.forEach((item, index) => {
			if (item) {
				let row: IUserRows = {
					action: function (): void {
						throw new Error('Function not implemented.');
					},
					firstName: '',
					lastName: '',
					role: '',
					logo: function (): void {
						throw new Error('Function not implemented.');
					},
					status: function (): void {
						throw new Error('Function not implemented.');
					},
					address: function (): void {
						throw new Error('Function not implemented.');
					},
					plan: function (): void {
						throw new Error('Function not implemented.');
					},
					companyName: '',
					createdAt: function (): void {
						throw new Error('Function not implemented.');
					},
				};
				BUSINESS_USER_LIST_TABLE_HEADER.forEach(({ key }) => {
					row.firstName = item?.firstName;
					row.lastName = item?.lastName;
					row.role = item?.role?.name;
					if (key === 'status') {
						const value = () => (
							<div className="status-wrapper">
								<span className={item[key]?.toLowerCase?.()} />
								<span>{item[key]?.toLowerCase() ?? '--'}</span>
							</div>
						);
						return (row[key] = value);
					}

					if (key === 'action' && isUserPermissionWrite) {
						const value = () => (
							<div className="lock-unlock--container">
								<div
									onClick={() => handleOnClickLockUnlock(item, index)}
									className={`action-icon-wrapper ${
										isLock ? 'disabled_btn' : ''
									}`}
								>
									{lockSelectedId === item._id && isLockLoading ? (
										<Loader className="loader-white" dimension={20} />
									) : (
										<i
											className={
												item.isLock ? 'ri-lock-line' : 'ri-lock-unlock-line'
											}
										/>
									)}
								</div>
								<div
									onClick={() => handleonClickDelete(index, item)}
									className="action-icon-wrapper"
								>
									<i className="ri-delete-bin-line"></i>
								</div>
							</div>
						);
						return (row[key] = value);
					}

					row = { ...row, [key]: item[key] };
				});
				rows.push(row);
			}
		});
		return setUserListRows(rows);
	}, [
		data,
		handleOnClickLockUnlock,
		handleonClickDelete,
		setUserListRows,
		usersList,
		isLockLoading,
	]);

	const renderSuperAdminTable = useMemo(() => {
		return (
			<ReactResponsiveTable
				isLoaded={userListLoaded}
				isLoading={userListLoading}
				numberOfList={6}
				column={BUSINESS_USER_LIST_TABLE_HEADER}
				rows={userListRows}
				height="calc(100vh - 285px)"
				emptyHeight={'calc(100vh - 255px)'}
			/>
		);
	}, [userListRows, userListLoaded, userListLoading]);

	const handleModal = useCallback(() => {
		setModal(false);
	}, []);

	const handleDeleteUser = useCallback(() => {
		if (userName?._id) {
			setDeleteLoading(true);
			deleteUser(
				`${API_URL.SIMPLICI_USERS}/${userName?._id}?type=SUPER_ADMIN&isDeleted=true`
			)
				.then((res) => {
					if (res?._id) {
						setUsersList((prev: ICompanyList[]) => {
							const newObj = JSON.parse(JSON.stringify(prev));
							newObj.splice(deleteIndex, 1);
							return [...newObj];
						});
						successNotification(
							`${userName.firstName} ${userName.lastName}	is					
							deleted successfully!`
						);
					} else errorNotification(res.message);
				})
				.catch((error) => {
					errorNotification(error);
				})
				.finally(() => {
					setDeleteLoading(false);
					setModal(false);
				});
		}
	}, [deleteIndex]);

	return (
		<>
			<div className="business-user-list--container">
				{renderSuperAdminTable}
			</div>
			{modal && (
				<div className="user-list-modal" onClick={() => handleModal()}>
					<div className="modal-content" onClick={(e) => e.stopPropagation()}>
						<div style={{ display: isLoaded ? 'none' : '' }}>
							<div className="confirmation-header">
								<Image
									fileName="confirmation_page.svg"
									className="confirmation-image"
									isLoad={() => setIsLoaded(!isLoaded)}
								/>
								<div className="modal-title">Are you sure?</div>
							</div>
							<div>
								<div className="modal-des">
									<span>
										You want to delete{' '}
										<span className="modal-delete-msg">
											{`${userName.firstName}'s`}
										</span>{' '}
										account?
									</span>
								</div>
								<div className="modal-footer">
									<Button
										label="Back"
										handleClick={() => handleModal()}
										type="button__filled button__filled--secondary button__large button-cancel"
									/>
									<Button
										label={
											deleteLoading ? (
												<Loader className="loader-white" dimension={20} />
											) : (
												'Delete'
											)
										}
										handleClick={() => handleDeleteUser()}
										type="button__filled button__filled--danger button__large button-cancel"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
