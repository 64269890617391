import { atom } from 'recoil';

export type ILogData = {
	data: { message: string; createdAt: string; action: string; physicalVerificationDoc?: string; };
};

interface IActivityLog {
	action: string;
	createdAt: string;
	message: string;
	_id: string;
	physicalVerificationDoc?: string;
}

export const ActivityLogState = atom<IActivityLog[]>({
	key: 'activity-log-key',
	default: [],
});
