import { FC, useEffect } from 'react';
import { Loader } from '@storybook';
import './accept-invite.scss';
import { useAcceptInvite } from './hooks/useAcceptInvite';

export const AcceptInvite: FC = () => {
    const { verifyInvite, message } = useAcceptInvite();
    useEffect(() => {
        verifyInvite()
    }, [])
    return (
		<div className='invite-screen--wrapper'>
            <div className='invite-screen--loader-message'>
                <div className='invite-screen--loader'>
                    {
                        message === 'Loading' &&
                        <Loader dimension={50} />
                    }
                </div>
                <p className='invite-screen--message'>{message}</p>
            </div>
		</div>
	);
};
