import { REACT_APP_API_HOST as API_HOST } from 'envs';

const isStage = /stage/gi.test(API_HOST);
const isPP = /pp/gi.test(API_HOST);
const isProd = /api.simplici.io/gi.test(API_HOST);

export const getCurrentEvrionment = () => {
	if (isStage) return 'stage';
	if (isPP) return 'pp';
	if (isProd) return 'prod';
	return 'stage';
};
