import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Image } from '@storybook';

import { COMPANY_LOGO } from 'constant';
import { ChooseMethod, EnterEmail, OtpVerify,BiometricApprovalSent } from './components';
import { LoginCurrentStepState } from './states';
import { MobileLinkSent } from './components/mobile-link-sent';

import './signin.scss';


export const SignIn = () => {
	const step = useRecoilValue(LoginCurrentStepState);

	const renderBody = useMemo(() => {
		switch (step) {
			case 'CREDS':
				return <EnterEmail />;
			case 'MOBILE_APPROVAL':
				return <MobileLinkSent />;
			case 'OTP_VERIFY':
				return <OtpVerify />;
			case 'CHOOSE_METHOD':
				return <ChooseMethod />;
			case 'BIOMETRIC_APPROVAL':
					return <BiometricApprovalSent />;
			default:
				return <EnterEmail />;
		}
	}, [step]);

	return (
		<>
			<div className="company-logo">
				<Image fileName="" url={COMPANY_LOGO} className="company-logo_image" />
			</div>
			<div className="signin-wrapper">
				<div className="signin-wrapper__inner">
					<div className="signin-wrapper__inner__form">{renderBody}</div>
				</div>
			</div>
		</>
	);
};
