import { FC } from 'react';
interface IHeader {
	head: string;
	desc: string;
}
export const Header: FC<IHeader> = ({ head, desc }) => {
	return (
		<div className="InviteUserFormModal--header">
			<h2>{head} </h2>
			<p>{desc} </p>
		</div>
	);
};
