import type { ICountries, ICountryCode } from '.';

import { FC } from 'react';
import Select from 'react-select';

import { DEFAULT_COUNTRY } from 'constant';
import countries from 'json/country-codes.json';

import './country-code.scss';

export const CountryCode: FC<ICountryCode> = ({ handleChangeCountry }) => {
	return (
		<Select
			defaultValue={DEFAULT_COUNTRY}
			formatOptionLabel={(country: ICountries) => (
				<div className="option">
					<img
						className="option__flag"
						src={country.image}
						alt="country-code"
					/>
					<span className="option__label">{country.label}</span>
				</div>
			)}
			className="country-label"
			options={countries}
			onChange={handleChangeCountry}
			isSearchable
		/>
	);
};
