import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { PermissionTableIcon } from '../../constants';
import {
	AddNewRoleFormState,
	PermissionTableDataState,
	SelectedUserRoleState,
} from '../../store';

interface IPermissionTable {
	isAllowedAccess?: boolean;
	isEditable?: boolean;
}

export const PermissionTable: FC<IPermissionTable> = ({
	isAllowedAccess,
	isEditable,
}) => {
	const [headerDropdown, setHeaderDropdown] = useState<string[]>([]);
	const [permissionHeaderCheckbox] = useState<
		Record<string, Record<string, boolean>>
	>({});

	const selectedUserRole = useRecoilValue(SelectedUserRoleState);
	const [addRoleForm, setAddRoleForm] = useRecoilState(AddNewRoleFormState);

	const permissionListTableData = useRecoilValue(PermissionTableDataState);

	useEffect(() => {
		setAddRoleForm((prev: any) => ({
			...prev,
			formErrors: {
				...prev.formErrors,
				permissions: null,
			},
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (selectedUserRole) {
			const { permissions } = selectedUserRole ?? {};
			if ((permissions ?? []).length) {
				setAddRoleForm((prev: any) => ({
					...prev,
					permissions,
				}));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedUserRole]);

	// to open and close dropdown
	const handleHeaderDropdown = useCallback(
		(id: string) => () => {
			if (headerDropdown.includes(id)) {
				const newIds = headerDropdown.filter((item) => item !== id);
				setHeaderDropdown(newIds);
			} else setHeaderDropdown((prev: string[]) => [...prev, id]);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[headerDropdown]
	);

	const handleClickCheckbox = useCallback(
		(type: string, moduleId: string, subModuleId?: string) => (e: any) => {
			e.stopPropagation();
			const { checked } = e.target;
			const opposite = type === 'read';

			const allSubModule = permissionListTableData.find(
				(item) => item._id === moduleId
			);
			const allSubModulePermission = allSubModule.subModules.map((item) => {
				return {
					subModuleId: item._id,
					view: checked,
					edit: opposite ? false : checked,
					create: opposite ? false : checked,
				};
			});

			const modulePermission: any = {
				module: moduleId,
				view: opposite ? checked : true,
				edit: opposite ? false : checked,
				create: opposite ? false : checked,
				subModules: allSubModulePermission,
			};

			if (subModuleId) {
				modulePermission.subModules = [
					{
						subModuleId,
						view: opposite ? checked : true,
						edit: opposite ? false : checked,
						create: opposite ? false : checked,
					},
				];
			}

			setAddRoleForm((prev: any) => {
				const newObjectRole = structuredClone(prev);

				const newObject = structuredClone(newObjectRole.permissions);

				const index = newObject.findIndex(
					(item: { module: string }) => item.module === moduleId
				);

				if (index !== -1) {
					if (subModuleId) {
						const subModuleIndex = newObject[index].subModules?.findIndex(
							(el: any) => el.subModuleId === subModuleId
						);
						if (subModuleIndex !== -1) {
							newObject[index].subModules[subModuleIndex] = {
								...newObject[index].subModules[subModuleIndex],
								subModuleId,
								...(type === 'write'
									? { view: checked, edit: checked, create: checked }
									: {
											view: checked,
											edit: false,
											create: false,
										}),
							};

							newObject[index].view = true;
							const { view, edit, create } =
								newObject[index].subModules[subModuleIndex];
							if (!view && !edit && !create) {
								newObject[index].subModules.splice(subModuleIndex, 1);
							}
						} else {
							newObject[index].view = true;
							newObject[index].subModules.push({
								subModuleId,
								...(type === 'read'
									? { view: checked, edit: false, create: false }
									: { view: checked, edit: checked, create: checked }),
							});
						}
					} else {
						newObject[index] = {
							...newObject[index],
							...(type === 'read'
								? { view: checked, edit: false, create: false }
								: { view: checked, edit: checked, create: checked }),
							subModules: allSubModulePermission,
						};

						const { view, edit, create, subModules = [] } = newObject[index];

						if (!view && !edit && !create && subModules.length == 0) {
							newObject.splice(index, 1);
						}
					}
				} else {
					newObject.push(modulePermission);
				}
				return { ...newObjectRole, ['permissions']: newObject };
			});
		},
		[addRoleForm, setAddRoleForm]
	);

	const checkPermission = useCallback(
		(
			formPermision: any,
			isSubModule: boolean,
			screenName: string | undefined = '',
			subModulesName: string | undefined = ''
		) => {
			const modulePermission = formPermision.find((item) => {
				return item?.module === screenName;
			});
			if (isSubModule) {
				const subModulePermission = modulePermission?.subModules.find(
					(item) => item.subModuleId === subModulesName
				);
				return subModulePermission;
			} else {
				return modulePermission;
			}
		},
		[addRoleForm]
	);

	const renderTableHeader = useMemo(() => {
		return (
			<div className="AddNewRoleModal--container__permission--table__header">
				<div className="AddNewRoleModal--container__permission--table__header--action">
					<div className="AddNewRoleModal--container__permission--table__header--action__title">
						Action
					</div>
				</div>
				<div className="AddNewRoleModal--container__permission--table__header--allowaccess">
					<div className="AddNewRoleModal--container__permission--table__header--allowaccess__viewOnly">
						View Only
					</div>
					<div className="AddNewRoleModal--container__permission--table__header--allowaccess__CreateandView">
						Create, Edit and View
					</div>
				</div>
			</div>
		);
	}, []);

	const renderTableBody = useMemo(() => {
		return (
			<div className="AddNewRoleModal--container__permission--table__body">
				{permissionListTableData?.map((list) => {
					return (
						<Fragment key={`${list._id.toString()}`}>
							<div
								className="permission-table-row"
								onClick={handleHeaderDropdown(list._id)}
							>
								<div className="permission-table-row__left">
									<div className="permission-table-row__left--dropdown">
										{list.subModules.length !== 0 && (
											<i
												className={
													headerDropdown.includes(list._id)
														? 'ri-arrow-down-s-fill'
														: 'ri-arrow-up-s-fill'
												}
											/>
										)}
									</div>
									<div className="permission-table-row__left--data">
										<div className="permission-table-row__left--data__icon">
											<i className={`ri-${PermissionTableIcon[list.name]}`} />
										</div>
										<div className="permission-table-row__left--data__label">
											{list.name}
										</div>
									</div>
								</div>
								<div className="permission-table-row__right">
									{isEditable ? (
										<div className="permission-table-row__right--checkboxView">
											{checkPermission(
												selectedUserRole.permissions,
												false,
												list._id
											)?.['view'] ?? false ? (
												<i className="ri-check-line permission-checked" />
											) : (
												<></>
											)}
										</div>
									) : (
										<div className="permission-table-row__right--checkboxView">
											<input
												type="checkbox"
												onClick={handleClickCheckbox('read', list._id)}
												checked={
													checkPermission(
														addRoleForm.permissions,
														false,
														list._id
													)?.['view'] ?? false
												}
												className={`permission-table-checkbox `}
												disabled={isAllowedAccess}
											/>
										</div>
									)}
									{isEditable ? (
										<div className="permission-table-row__right--createView">
											{checkPermission(
												selectedUserRole.permissions,
												false,
												list._id
											)?.['edit'] ?? false ? (
												<i className="ri-check-line permission-checked" />
											) : (
												<></>
											)}
										</div>
									) : (
										<div className="permission-table-row__right--createView">
											<input
												type="checkbox"
												onClick={handleClickCheckbox('write', list._id)}
												checked={
													checkPermission(
														addRoleForm.permissions,
														false,
														list._id
													)?.['edit'] ?? false
												}
												className={`permission-table-checkbox `}
												disabled={isAllowedAccess}
											/>
										</div>
									)}
								</div>
							</div>
							{!headerDropdown.includes(list._id) &&
								list.subModules.map((subList: any) => {
									return (
										<div
											className="permission-table-row__dropdownBody"
											key={subList._id}
										>
											<div className="permission-table-row__dropdownBody--row">
												<div className="permission-table-row__dropdownBody--row__left">
													<div className="permission-table-row__dropdownBody--row__left--dropdown">
														<i className="ri-arrow-down-s-fill" />
													</div>
													<div className="permission-table-row__dropdownBody--row__left--data">
														<div className='lassName="permission-table-row__dropdownBody--row__left--data__label'>
															{subList.name}
														</div>
													</div>
												</div>
												<div className="permission-table-row__dropdownBody--row__right">
													{isEditable ? (
														<div className="permission-table-row__dropdownBody--row__right--checkboxView">
															{checkPermission(
																selectedUserRole.permissions,
																true,
																list._id,
																subList._id
															)?.['view'] ?? false ? (
																<i className="ri-check-line permission-checked" />
															) : (
																<></>
															)}
														</div>
													) : (
														<div className="permission-table-row__dropdownBody--row__right--checkboxView">
															<input
																type="checkbox"
																onClick={handleClickCheckbox(
																	'read',
																	list._id,
																	subList._id
																)}
																checked={
																	checkPermission(
																		addRoleForm.permissions,
																		true,
																		list._id,
																		subList._id
																	)?.['view'] ?? false
																}
																className="permission-table-checkbox"
																disabled={isAllowedAccess || subList?.isRead}
															/>
														</div>
													)}
													{isEditable ? (
														<div className="permission-table-row__dropdownBody--row__right--createView">
															{checkPermission(
																selectedUserRole.permissions,
																true,
																list._id,
																subList._id
															)?.['edit'] ?? false ? (
																<i className="ri-check-line permission-checked" />
															) : (
																<></>
															)}
														</div>
													) : (
														<div className="permission-table-row__dropdownBody--row__right--createView">
															<input
																type="checkbox"
																onClick={handleClickCheckbox(
																	'write',
																	list._id,
																	subList._id
																)}
																checked={
																	checkPermission(
																		addRoleForm.permissions,
																		true,
																		list._id,
																		subList._id
																	)?.['edit'] ?? false
																}
																className="permission-table-checkbox"
																disabled={isAllowedAccess || subList?.isWrite}
															/>
														</div>
													)}
												</div>
											</div>
										</div>
									);
								})}
						</Fragment>
					);
				})}
			</div>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		headerDropdown,
		addRoleForm,
		permissionHeaderCheckbox,
		isAllowedAccess,
		permissionListTableData,
		selectedUserRole,
	]);

	return (
		<div className="AddNewRoleModal--container__permission--table">
			{renderTableHeader}
			{renderTableBody}
		</div>
	);
};
