import { FC, useMemo, useState } from 'react';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';
import { Button, Loader } from '@storybook';

import { API_URL, message } from 'constant';
import { useNetwork, useNotification } from 'hooks';
import {
	AddNewRoleModal,
	RoleAndPermissionNavigation,
	UserRoleNavigation,
	useNewRoles,
	AddNewRoleFormState,
	IPermissionType,
	IReadWrite,
	PermissionNavigation,
	SelectedUserRoleState,
	UserRolePermissionNavigation,
	useRolepermission,
	IAddNewRoleFormError,
} from 'views';
import { ConfirmationModal } from 'components';
import { AllowPermission } from '../allow-permission';
import { AssinedUser } from '../assined-user';
import { DropInfoOptions } from '../info-drop-options';

import './complaince-agent.scss';

interface IComplainceAgent {
	isUserPermissionWrite?: boolean;
}

export const ComplainceAgent: FC<IComplainceAgent> = ({
	isUserPermissionWrite = true,
}) => {
	const setUserRoleNavigate = useSetRecoilState(UserRolePermissionNavigation);
	const setNavigate = useSetRecoilState(PermissionNavigation);
	const resetAddRoleForm = useResetRecoilState(AddNewRoleFormState);
	
	const addRoleForm = useRecoilValue(AddNewRoleFormState);
	const setAddRoleForm = useSetRecoilState(AddNewRoleFormState);
	const [selectedUserRole, setSelectedUserRole] = useRecoilState(
		SelectedUserRoleState
	);
	const [isAddRoleModalOpen, setIsAddRoleModalOpen] = useState<boolean>(false);
	const { name, description, permissions, formErrors } = useMemo(
		() => addRoleForm,
		[addRoleForm]
	);

	const { patch: updateAddNewRole, loading: updateRoleLoader } = useNetwork();
	const { errorNotification } = useNotification();
	const handleCloseModel = () => null;
	const {
		handleDeleteUserRole,
		isOpenDeleteModal,
		handleOpenDelete,
		createRoleLoading,
	} = useNewRoles({ handleCloseModel });
	const { fetchUserRoles } = useRolepermission();

	const handleBackToPermission = async () => {
		setUserRoleNavigate(UserRoleNavigation.AddedRoleCard);
		setSelectedUserRole(null);
		resetAddRoleForm();
	};

	const handleCloseModal = () => {
		setIsAddRoleModalOpen((prev) => !prev);
	};
	const onEditInviteRole = () => {
		handleCloseModal();
		setNavigate(RoleAndPermissionNavigation.EditRole);
	};

	const handleUpdateRole = async () => {
		const formErr: IAddNewRoleFormError = {...formErrors};
		if (!name?.trim()) {
			formErr.name = 'Name is required.';
		} else if (name?.trim().length < 3) {
			formErr.name = 'Name should be at least 3 characters long.';
		} else if (!/^[a-zA-Z\s]+$/.test(name?.trim() || "")) {
			formErr.name = 'Only alphabets are allowed.';
		} else {
			formErr.name = null;
		}
		
		const hasAnyReadOrWritePermission = Object.keys(permissions ?? []).some(
			(category) => {
				return ['view', 'edit'].some(
					(permission) =>
						permissions[category as IPermissionType][permission as IReadWrite]
				);
			}
		);
		
		if (!hasAnyReadOrWritePermission) {
			formErr.permissions = 'Please select at least one permission for this role.';
		}

		const hasErrors = Object.values(formErr).some((error) => typeof error === 'string');
		
		if(hasErrors){
			setAddRoleForm((pre) => ({ ...pre, formErrors: formErr }));
			return
		}
		const payload = {
			name: name.trim(),
			description: description?.trim(),
			permissions,
		};

		const resp = await updateAddNewRole(
			`${API_URL.ROLES}/${selectedUserRole?._id}`,
			payload
		);

		if (resp) {
			setSelectedUserRole((pre: any) => ({ ...pre, ...payload }));
			handleCloseModal();
			resetAddRoleForm();
			await fetchUserRoles();
		} else {
			errorNotification(resp?.message ?? message.SomethingWentWrongMessage);
		}
	};
	

	return (
		<div className="ComplainceAgent--container">
			<div
				className="ComplainceAgent--container__back"
				onClick={handleBackToPermission}
			>
				<div className="ComplainceAgent--container__back--icon">
					<i className="ri-arrow-left-s-line" />
				</div>
				<div className="ComplainceAgent--container__back--label">
					Back to Roles and permission
				</div>
			</div>

			<div className="ComplainceAgent--container__userAccess">
				<div className="agent--container">
					<div className="agent--container__header">
						<div className="agent--container__header--title">
							<div className="agent--container__header--title__heading">
								<div className="agent--container__header--title__heading--text">
									<div className="agent--container__header--title__heading--text__label">
										{selectedUserRole?.name ?? 'NA'}
									</div>
									{isUserPermissionWrite && (
										<div className="agent--container__header--title__heading--text__btn">
											<div
												className="agent--container__header--title__heading--text__btn--label"
												onClick={onEditInviteRole}
											>
												Edit
											</div>
											<DropInfoOptions
												handleDelete={() => handleOpenDelete(selectedUserRole)}
												showEdit={false}
											/>
										</div>
									)}
								</div>
							</div>
							<div className="agent--container__header--title__subHeader">
								{selectedUserRole?.description ?? 'NA'}
							</div>
						</div>
					</div>

					<div className="agent--container__permission">
						<div className="agent--container__permission--table">
							<AllowPermission isDisable />
						</div>
					</div>
				</div>
				<div className="assinedUser--container">
					<AssinedUser isUserPermissionWrite={isUserPermissionWrite} />
				</div>
			</div>

			{isOpenDeleteModal && (
				<ConfirmationModal
					description={`Deleting ${selectedUserRole.name} role will remove user’s permissions from the system.`}
					boldDescription={` Do you still want to delete ${selectedUserRole.name} role?`}
					label="Delete"
					title="Are you sure?"
					visible={isOpenDeleteModal}
					showLoader={createRoleLoading}
					handleModal={handleDeleteUserRole}
				/>
			)}

			<AddNewRoleModal
				isAddRoleModalOpen={isAddRoleModalOpen}
				handleCloseModel={handleCloseModal}
			>
				<div className="AddNewRoleModal--container__footer">
					<Button
						label="Cancel"
						type="button__filled--secondary"
						handleClick={handleCloseModal}
					/>
					<Button
						label={
							updateRoleLoader ? (
								<Loader dimension={22} className="loader-white" />
							) : (
								'Save'
							)
						}
						type="button__filled--primary"
						handleClick={handleUpdateRole}
						disabled={updateRoleLoader}
					/>
				</div>
			</AddNewRoleModal>
		</div>
	);
};
