import { FC, useMemo } from 'react';
import { getDate, getTime } from 'utils';
import { activityAction, activityActionBackground, ShowViewReport } from '../constant';
import { ILogData } from '../states';

export const Logs: FC<ILogData> = (props) => {
	const {
		message,
		createdAt,
		action,
		physicalVerificationDoc='',
	} = useMemo(() => props.data, [props.data])

	return (
		<div className="audit-logs__lists__item">
			<div
				className="audit-logs__lists__item__icon"
				style={{ background: activityActionBackground[action] }}
			>
				<i className={`${activityAction[action]} action_${action}`} />
			</div>

			<div className="audit-logs__lists__item__text-container">
				<div className="audit-logs__lists__item__text-container__text">
					{message}{' '}
					{(ShowViewReport.includes(action) && physicalVerificationDoc) ? <span className={`audit-logs__lists__item__${action}`}>
						<a rel="noreferrer" href={physicalVerificationDoc}>
							View report
						</a>
					</span>:null}
				</div>
				<div className="audit-logs__lists__item__text-container__date">
					{getDate(createdAt)} at {getTime(createdAt)}
				</div>
			</div>
		</div>
	);
};
