import App from 'app';
import { createRoot } from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import { RecoilRoot } from 'recoil';
import 'react-toastify/dist/ReactToastify.css';
import 'remixicon/fonts/remixicon.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
	<RecoilRoot>
		<BrowserRouter>
			<App />
			<ToastContainer
				position="top-center"
				autoClose={2000}
				hideProgressBar
				closeOnClick
				pauseOnHover
				transition={Slide}
				limit={1}
				theme="dark"
			/>
		</BrowserRouter>
	</RecoilRoot>
);
