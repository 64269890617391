export const COUNTRIES = [
  { value: "usa", label: "USA" },
  { value: "deu", label: "GERMANY" },
  { value: "ita", label: "ITALY" },
  { value: "ind", label: "INDIA" },
  { value: "uk", label: "UNITED KINGDOM" },
];

export const LANGUAGES = [
  { value: "en", label: "English (EN)" },
  { value: "es", label: "Spanish (ES)" },
  { value: "ka", label: "Georgian (KA)" },
];

export const ALLOWED_DOCS = [
  { value: "id", label: "ID CARD" },
  { value: "Passport", label: "PASSPORT" },
  { value: "non-bio-passport", label: "NON BIO PASSPORT" },
  { value: "driver_license", label: "DRIVER LICENSE" },
  { value: "residence_license", label: "RESIDENCE LICENSE" },
];

export const DEFAULT_DOCS = [
  { value: "id", label: "ID CARD" },
  { value: "Passport", label: "PASSPORT" },
  { value: "driver_license", label: "DRIVER LICENSE" },
];

export const ACTIVE_TABS = {
  PIPELINE: "pipeline",
  KYC: "kyc",
  CREDENTIALS: "credentials",
  SETTINGS: "settings",
  INVITES: "invites",
  MINTCARTA: "mint-carta",
};

export const SIDEBAR_TABS = [
  {
    ROUTE: ACTIVE_TABS.KYC,
    TITLE: "KYC",
    ICON: "ri-user-search-line",
    TAB_INDEX: 1,
  },
  {
    ROUTE: ACTIVE_TABS.CREDENTIALS,
    TITLE: "Credentials",
    ICON: "-2-fill",
    TAB_INDEX: 2,
  },
  {
    ROUTE: ACTIVE_TABS.INVITES,
    TITLE: "Invites",
    ICON: "ri-user-add-line",
    TAB_INDEX: 3,
  },
  {
    ROUTE: ACTIVE_TABS.SETTINGS,
    TITLE: "Settings",
    ICON: "ri-settings-5-line",
    TAB_INDEX: 4,
  },
];

export const KYC_LABELS = {
  LIVENESS_LABEL: "Liveness Check",
  LANGUAGES_LABEL: "Languages to support",
  DOCUMENT_TYPES_LABEL: "List of allowed documents",
  DOCUMENT_COUNTRIES_LABEL: "Limit document scanning by country codes",
  SKIP_FACE_LABEL: "Skip face scanning step completely",
  ALLOW_GENERAL_DOCUMENT_CAPTURE_LABEL: "Allowing capture of general document",
  ALLOW_GENERAL_DOCUMENT_UPLOAD_LABEL: "Allowing upload of general document",
  SKIP_AGREEMENT_LABEL: "Skip agreement step completely",
  SKIP_DOCUMENT_LABEL: "Skip document scanning step completely",
  ALLOW_DOCUMENT_UPLOAD_LABEL: "Allow upload of an image as a document",
  ALLOW_FACE_UPLOAD_LABEL: "Allow person to upload a selfie",
  ALLOW_FACE_DOC_UPLOAD_LABEL:
    "Allow a person to upload an image of them holding a document in hand",
  REQUIRE_FACE_DOCUMENT_LABEL: "Require a person to hold a document in a hand",
  DISABLE_DOCUMENT_CAPTURE_LABEL: "Disable document capture with camera",
  SKIP_DESKTOP_LABEL:
    "Skip personal computer, mac browser session and display QR code to continue on mobile device",
  CAPTURE_DOUBLE_PAGE_PASSPORT_LABEL:
    "Instead of capturing passport's single page, capture double",
};

export const RESP_LANGUAGE = {
  en: { value: "en", label: "English (EN)" },
  es: { value: "es", label: "Spanish (ES)" },
  ka: { value: "ka", label: "Georgian (KA)" },
  ru: { value: "ru", label: "Russian (RU)" },
  uk: { value: "uk", label: "Ukrainian (UK)" },
};

export const RESP_COUNTRIES = {
  usa: { value: "usa", label: "USA" },
  deu: { value: "deu", label: "GERMANY" },
  ita: { value: "ita", label: "ITALY" },
  ind: { value: "ind", label: "INDIA" },
  uk: { value: "uk", label: "UNITED KINGDOM" },
};

export const RESP_ALLOWED_DOCS = {
  id: { value: "id", label: "ID CARD" },
  Passport: { value: "Passport", label: "PASSPORT" },
  "non-bio-passport": { value: "non-bio-passport", label: "NON BIO PASSPORT" },
  driver_license: { value: "driver_license", label: "DRIVER LICENSE" },
  residence_license: { value: "residence_license", label: "RESIDENCE LICENSE" },
};

export const WARNINGS = {
  LANGUAGE_MISSING: "Languages to support is not Selected!",
  DOCUMENTS_TYPES_MISSING: "list of allowed documents not Selected!",
  COUNTRY_CODES_MISSING:
    "Limit document scanning by country codes not Selected!",
  UPDATE_SUCCESS: "Updated Successfully",
  ERROR: "Something went wrong !",
  NAME_MISSING: "Company Name is Missing...!",
  LOGO_MISSING: "Company Logo is Missing...!",
  FAILED: "Faild to Save. Try Again.",
  SAVED: "Saved Successfully",
};

export const INVITES_TABLE = {
  HEADER: [
    {
      TITLE: "Contact Number",
      INDEX: 1,
    },
    {
      TITLE: "Provider",
      INDEX: 2,
    },
    {
      TITLE: "Invitation Date",
      INDEX: 3,
    },
    {
      TITLE: "Status",
      INDEX: 4,
    },
  ],
  BODY: [
    {
      CONTACT_NUMBER: 625612,
      DATE: "17-May-2022",
      STATUS: "Not yet opened",
      ACTION: "Withdraw",
      INDEX: 1,
    },
    {
      CONTACT_NUMBER: 625612,
      DATE: "17-May-2022",
      STATUS: "Not yet opened",
      ACTION: "Withdraw",
      INDEX: 2,
    },
    {
      CONTACT_NUMBER: 625612,
      DATE: "17-May-2022",
      STATUS: "Not yet opened",
      ACTION: "Withdraw",
      INDEX: 3,
    },
  ],
};

export const DEFAULT_COUNTRY = {
  value: "United States",
  label: "+1",
  code: "US",
  image:
    "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg",
};

export const PROVIDER_OPTIONS = [
  { label: "COMPLIANCE", value: "COMPLIANCE" },
  { label: "IDENTOMAT", value: "IDENTOMAT" },
];


