import { atom } from 'recoil';

interface ITotalRevenueChart {
	[key: string]: {
		amount: number;
		total: number;
	};
}

interface IValue {
	processing: number;
	pending: number;
	completed: number;
	rejected: number;
	failed: number;
	accept: number;
	reject: number;
	accepted: number;
}

interface IAnalyticsRevenueChart {
	[key: string]: IValue;
}

interface IBusinessList {
	businessId: string;
	amount: number;
	createdAt: string;
	totalTransaction: number;
	name: string;
}

interface ITotal {
	totalRevenue: number;
	payAsYouGo: number;
	totalBusiness: number;
	onboardingFlows: number;
	revenue: number;
	sessionCount: number;
	transactionsCount: number;
	monthlyBilling: number;
	businesses: number;
	pages: number;
	failedPayment: number;
	duePayment: number;
	pendingPayment: number;
}

interface IRevenueTransactionDetail {
	businesses: IBusinessList[];
	total: ITotal;
}

interface ITransactionData {
	businessName: string;
	paymentId: string;
	transactionId: string;
	amount: number;
	type: string;
	time: string;
	card: string;
	status: string;
}

export const TotalRevenueChartState = atom<ITotalRevenueChart>({
	key: 'total-revenue-chart-key',
	default: {},
});

export const AnalyticsRevenueChartState = atom<IAnalyticsRevenueChart>({
	key: 'analytics-revenue-chart-key',
	default: {},
});

export const RevenueTransactionDetailState = atom<IRevenueTransactionDetail>({
	key: 'revenue-transaction-detail-key',
	default: null,
});

export const TransactionsRevenueChartState = atom<ITotalRevenueChart>({
	key: 'transactions-total-revenue-chart-key',
	default: {},
});

export const TransactionsAnalyticsChartState = atom<IAnalyticsRevenueChart>({
	key: 'transactions-analytics-revenue-chart-key',
	default: {},
});

export const TransactionsTotalSummaryState = atom<
	ITotal | Record<string, never>
>({
	key: 'transactions-total-summary-key',
	default: {},
});

export const TransactionDetailListState = atom<ITransactionData[]>({
	key: 'transaction-detail-list-key',
	default: [],
});
