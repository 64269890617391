import { useCallback } from 'react';
import { PublicKeyCredentialDescriptorFuture } from '@simplewebauthn/typescript-types';
import { useSetRecoilState } from 'recoil';

import {
	base64URLStringToBuffer,
	bufferToBase64URLString,
	getRegistrations,
	saveNewCredentials,
	toAuthenticatorAttachment,
} from './utils';
import { isConfirmationModalOpen } from 'views/signin/states';

export const useWebAuthn = () => {
	const setConfirmationOpen = useSetRecoilState(isConfirmationModalOpen);

	const registerNewCredential = useCallback(
		async (callback: any, registrationOptions: any) => {
			const {
				rp,
				user,
				attestation,
				pubKeyCredParams,
				challenge,
				authenticatorSelection,
				timeout,
				extensions,
			} = registrationOptions ?? {};

			const { name, id, displayName } = user;
			const publicKey: PublicKeyCredentialCreationOptions = {
				rp: rp ?? {},
				user: {
					name: name ?? '',
					id: base64URLStringToBuffer(id ?? ''),
					displayName: displayName ?? '',
				},
				attestation: attestation ?? 'direct',
				pubKeyCredParams: pubKeyCredParams ?? [],
				challenge: base64URLStringToBuffer(challenge ?? ''),
				authenticatorSelection: authenticatorSelection ?? {},
				timeout,
				extensions,
				excludeCredentials: [],
			};
			const res = (await navigator?.credentials?.create({
				publicKey: publicKey,
			})) as any;

			const payload = {
				rawId: bufferToBase64URLString(res?.rawId),
				id: res.id,
				name: name ?? '',
				userId: id,
				displayName: displayName ?? '',
			};

			const successPayload = {
				authenticatorAttachment: toAuthenticatorAttachment(
					res.authenticatorAttachment
				),
				id: res.id,
				rawId: bufferToBase64URLString(res?.rawId),
				response: {
					attestationObject: bufferToBase64URLString(
						res.response.attestationObject
					),
					clientDataJSON: bufferToBase64URLString(res.response.clientDataJSON),
					transports: ['internal'],
				},
				type: 'public-key',
				clientExtensionResults: res.getClientExtensionResults() ?? {},
			};

			saveNewCredentials(payload);
			await callback(successPayload);
		},
		[]
	);

	const authenticate = useCallback(
		async (onSuccess: any, resAuthenticateCredentials: any) => {
			const { id, authenticateOptions } = resAuthenticateCredentials;
			const { challenge, allowCredentials, rpId, timeout, userVerification } =
				authenticateOptions ?? {};

			const registeredCredentials = () => {
				return allowCredentials?.map(
					(registeredDevice: PublicKeyCredentialDescriptorFuture) => ({
						id: base64URLStringToBuffer(registeredDevice.id),
						type: registeredDevice.type,
						transports: registeredDevice.transports,
					})
				);
			};
			const publicKey = {
				challenge: base64URLStringToBuffer(challenge ?? ''),
				allowCredentials: registeredCredentials(),
				rpId: rpId ?? '',
				timeout: timeout ?? 60000,
				userVerification: userVerification ?? 'required',
			};

			try {
				// browser receives the publicKey object and passes it to WebAuthn "get" API
				const res = (await navigator?.credentials?.get({
					publicKey: publicKey,
				})) as any; //as PublicKeyCredential;

				const payload = {
					id,
					authenticateOptions: {
						id: res.id,
						rawId: bufferToBase64URLString(res.rawId),
						response: {
							authenticatorData: bufferToBase64URLString(
								res.response?.authenticatorData
							),
							clientDataJSON: bufferToBase64URLString(
								res.response?.clientDataJSON
							),
							signature: bufferToBase64URLString(res.response?.signature),
							userHandle: bufferToBase64URLString(res.response?.userHandle),
						},
						type: 'public-key',
						clientExtensionResults: res.getClientExtensionResults(),
						authenticatorAttachment: toAuthenticatorAttachment(
							res.authenticatorAttachment
						),
					},
				};
				onSuccess(payload);
			} catch (error: any) {
				// eslint-disable-next-line no-console
				console.log({ error });
				if (error.name === 'NotAllowedError') {
					setConfirmationOpen(true);
				}
			}
		},
		[setConfirmationOpen]
	);

	return {
		authenticate,
		registerNewCredential,
		getRegistrations,
	};
};
