export const AddUserRoleLabel = {
	PermissionHeader: 'Permissions',
	PermissionDescription: 'Select permissions you want to give it to this role',
	CreateNewRoleHeader: 'No role available',
	CreateNewRoleDescription: 'You haven’t added any role yet',
};

export const AllowPermissionLabel = {
	Header: 'Permissions',
	Description: 'Select permissions you want to give to this role',
	Card: {
		Header: 'All access',
		Description:
			'Enable this option to grant this role unrestricted access to all available permissions. When turned on, individual permissions below will be disabled.',
	},
};

export const PermissionTableIcon = {
	Businesses: 'profile-line',
	Activities: 'list-check-2',
	Revenue: 'money-dollar-circle-line',
	'Roles & permissions': 'settings-5-line',
	'API Dashboard': 'database-line',
};

export const UserRoleHeaderData: Record<
	string,
	{ head: string; desc: string }
> = {
	AddNewRole: {
		head: 'Add new role',
		desc: 'Define roles, set permissions and assign to users for streamlined access',
	},
	CreateNewRole: {
		head: 'Create New Role',
		desc: 'Create role and add permissions',
	},
	NoRoleAvailable: {
		head: 'No role available',
		desc: 'You haven’t added any role yet',
	},
	EditRole: {
		head: 'Edit role',
		desc: 'Define roles, edit permissions and assign to users for streamlined access',
	},
};

export const RolesAndPermissionCardData = {
	Invite_User: 'Invite user',
	Edit_Role: 'Edit role',
	Delete_Role: 'Delete role',
};

export const RoleAndPermissionNavigation = {
	CreateNewRole: 'CreateNewRole',
	AddNewRole: 'AddNewRole',
	NoRoleAvailable: 'NoRoleAvailable',
	EditRole: 'EditRole',
};

export const UserRoleNavigation = {
	ComplainceAgent: 'ComplainceAgent',
	AddedRoleCard: 'AddedRoleCard',
	userListTable: 'userlist',
};

export const InviteNavigation = {
	InviteForm: 'InviteForm',
	InvitePreview: 'InvitePreview',
};
