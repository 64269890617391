import { useEffect } from 'react';
import { useNetwork } from 'hooks';

import { API_URL } from 'constant';
export const useFetchUserList = (selectedId: string) => {
	const {
		get: getUserList,
		data: UserListData,
		error: userListError,
		status: userListStatus,
		isLoaded: userListLoaded,
		loading: userListLoading,
		statusCode: userlistStatusCode,
	} = useNetwork();

	useEffect(() => {
		if (selectedId && !userListLoaded)
			getUserList(`${API_URL.BUSINESSES}?businessId=${selectedId}`);
	}, [selectedId]);

	return {
		UserListData,
		userListError,
		userListStatus,
		userListLoaded,
		userListLoading,
		userlistStatusCode,
	};
};
