import { Skeleton } from '@storybook';
import { SessionInfoChild } from 'views/businesses';

export const UserRoleSkeletonLoader = () => {
	return (
		<>
			<div className="UserRoleSkeletonLoader">
				{Array(6)
					.fill(1)
					.map((data, index: number) => (
						<div
							className="UserRoleSkeletonLoader--container"
							key={data + index.toString()}
						>
							<div className="UserRoleSkeletonLoader--container__header hover">
								<div className="UserRoleSkeletonLoader--container__header--label">
									<div className="UserRoleSkeletonLoader--container__text">
										<div className="UserRoleSkeletonLoader--container__labelContainer">
											<div>
												<div className="UserRoleSkeletonLoader--container__header--label__title">
													<Skeleton height={15} width={200} />
												</div>
												<div className="UserRoleSkeletonLoader--container__header--label__subTitle">
													<Skeleton height={12} width={200} />
												</div>
											</div>

											<div className="">
												<SessionInfoChild
													height={30}
													width={10}
													borderRadius={8}
												/>
											</div>
										</div>

										<div className="UserRoleSkeletonLoader--container__permissionTag">
											<SessionInfoChild
												height={25}
												width={100}
												borderRadius={8}
											/>
											<SessionInfoChild
												height={25}
												width={100}
												borderRadius={8}
											/>
											<SessionInfoChild
												height={25}
												width={30}
												borderRadius={8}
											/>
										</div>
									</div>
								</div>

								<div className="UserRoleSkeletonLoader--container__inviteUser">
									<div className="UserRoleSkeletonLoader--container__profile">
										<div className="overlap-image-one">
											<SessionInfoChild
												height={40}
												width={40}
												borderRadius={25}
											/>
										</div>
									</div>

									<div className="UserRoleSkeletonLoader--container__inviteUser--label">
										<SessionInfoChild
											height={20}
											width={100}
											borderRadius={6}
										/>
									</div>
								</div>
							</div>
						</div>
					))}
			</div>
		</>
	);
};

export const AssignedUserSkeletonLoader = () => {
	return (
		<>
			<div className="AssinedUserSkeletonLoader--container__userList">
				{Array(5)
					.fill(1)
					?.map((data, index: number) => {
						return (
							<div
								className="AssinedUserSkeletonLoader--container__userList--data"
								key={data + index.toString()}
							>
								<div className="AssinedUserSkeletonLoader--container__userList--data__profile">
									<SessionInfoChild height={40} width={40} borderRadius={25} />
								</div>
								<div className="AssinedUserSkeletonLoader--container__userList--data__label">
									<div className="AssinedUserSkeletonLoader--container__userList--data__label--name">
										<Skeleton height={12} width={200} />
									</div>
									<div className="AssinedUserSkeletonLoader--container__userList--data__label--account">
										<Skeleton height={8} width={200} />
									</div>
								</div>
								<SessionInfoChild height={30} width={10} borderRadius={8} />
							</div>
						);
					})}
			</div>
		</>
	);
};
