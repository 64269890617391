import { FC, MouseEvent, createRef, useCallback, useState } from 'react';

import { useOutsideClick } from 'hooks';

import './drop-option.scss';

interface IDropOption {
	options: {
		title: string | JSX.Element;
		handleOption: () => void;
	}[];
}

export const DropOption: FC<IDropOption> = ({ options = [] }) => {
	//local state for show drop options
	const [showMenu, setShowMenu] = useState(false);

	// refs
	const ref = createRef<HTMLDivElement>();

	useOutsideClick(ref, () => {
		setShowMenu(false);
	});

	const handleClick = useCallback(
		(e: MouseEvent<HTMLDivElement>) => {
			e.stopPropagation();
			setShowMenu(!showMenu);
		},
		[showMenu]
	);

	return (
		<>
			<div ref={ref}>
				<div onClick={handleClick} className="DropOption">
					<i className="ri-more-2-fill" />
				</div>
				{showMenu && (
					<div
						onClick={(e) => e.stopPropagation()}
						className="DropOption__list"
					>
						{options.map(({ title, handleOption }) => (
							<div
								key={title.toString()}
								onClick={handleOption}
								className="DropOption__listItem"
							>
								{title}
							</div>
						))}
					</div>
				)}
			</div>
		</>
	);
};
