import { useCallback } from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import {
	ISelectedBusiness,
	ITimeIntervalVerificationData,
	IsVerificationModalEditableState,
	IsVerificationsModalOpenState,
	SelectedBusinessState,
	SuperAdminDetails,
	TimeIntervalVerificationDataState,
} from '../states';
import { useNetwork, useNotification } from 'hooks';
import { API_URL } from 'constant';
import { isNumber } from 'utils';

export const useVerificationTimeInterval = () => {
	// globle state
	const setIsVerificationsModalOpen = useSetRecoilState(
		IsVerificationsModalOpenState
	);
	const setSelectedBusiness = useSetRecoilState(SelectedBusinessState);
	const setVerificationsData = useSetRecoilState(
		TimeIntervalVerificationDataState
	);
	const setIsEditable = useSetRecoilState(IsVerificationModalEditableState);
	const setSuperAdminData = useSetRecoilState(SuperAdminDetails);
	const resetSelectedBusiness = useResetRecoilState(SelectedBusinessState);
	const resetIsVerificationModalEditable = useResetRecoilState(
		IsVerificationModalEditableState
	);
	const resetVerificationsData = useResetRecoilState(
		TimeIntervalVerificationDataState
	);

	// hooks
	const { patch, loading } = useNetwork();
	const { errorNotification, successNotification } = useNotification();

	// function for calculating days within the time interval
	const getDays = useCallback(
		(time: number, interval: 'days' | 'months' | 'years') => {
			if (!time) return 0;
			if (interval === 'days') {
				return Number(time);
			}
			if (interval === 'years') {
				return Number(time) * 365;
			}
			if (interval === 'months') {
				// Get the current date
				const currentDate = new Date();
				const nextDate = new Date(currentDate);

				nextDate.setMonth(currentDate.getMonth() + Number(time));

				// Convert both dates to UTC to avoid issues with daylight saving time
				const utcDate1 = Date.UTC(
					currentDate.getFullYear(),
					currentDate.getMonth(),
					currentDate.getDate()
				);
				const utcDate2 = Date.UTC(
					nextDate.getFullYear(),
					nextDate.getMonth(),
					nextDate.getDate()
				);

				// Calculate the difference in milliseconds
				const timeDifference = utcDate2 - utcDate1;

				// Convert the difference to days
				const daysDifference = Math.floor(
					timeDifference / (1000 * 60 * 60 * 24)
				);

				return daysDifference - 1 ?? 1;
			}
		},
		[]
	);

	const handleOpenTimeIntervalModal = useCallback((item: ISelectedBusiness) => {
		const { _id, users, verificationTimeInterval } = item ?? {};
		const { kyc, kyb, authentication } = verificationTimeInterval ?? {};
		const { firstName, lastName, email } = users ?? {};
		if (!kyc || !kyb || !authentication) setIsEditable(true);
		setSelectedBusiness({
			_id,
			users: {
				firstName,
				lastName,
				email,
			},
			verificationTimeInterval: {
				kyc: kyc ?? 0,
				kyb: kyb ?? 0,
				authentication: authentication ?? 0,
			},
		});
		setVerificationsData({
			kyb: {
				time: kyb ?? 0,
				interval: { label: 'Days', value: 'days' },
			},
			kyc: {
				time: kyc ?? 0,
				interval: { label: 'Days', value: 'days' },
			},
			authentication: {
				time: authentication ?? 0,
				interval: { label: 'Days', value: 'days' },
			},
		});
		setIsVerificationsModalOpen(true);
	}, []);

	const handleCloseTimeIntervalModal = useCallback(() => {
		setIsVerificationsModalOpen(false);
		setTimeout(() => {
			resetSelectedBusiness();
			resetVerificationsData();
			resetIsVerificationModalEditable();
		}, 1000);
	}, []);

	const maxMinTimeInterval = useCallback((value: number) => {
		return value < 1 || value > 365;
	}, []);

	const handleSaveTimeInterval = useCallback(
		async (
			selectedBusiness: ISelectedBusiness,
			VerificationsData: ITimeIntervalVerificationData
		) => {
			const { _id } = selectedBusiness ?? {};
			const { kyc, kyb, authentication } = VerificationsData ?? {};
			const verificationTimeInterval = {
				kyc: getDays(kyc.time, kyc.interval.value as 'days'),
				kyb: getDays(kyb.time, kyb.interval.value as 'days'),
				authentication: getDays(
					authentication.time,
					authentication.interval.value as 'days'
				),
			};

			if (
				maxMinTimeInterval(verificationTimeInterval.kyc) ||
				maxMinTimeInterval(verificationTimeInterval.kyb) ||
				maxMinTimeInterval(verificationTimeInterval.authentication)
			) {
				errorNotification(
					'The time interval should range from a minimum of 1 day to a maximum of 365 days.'
				);
				return;
			}

			const payload = {
				type: 'timeInterval',
				verificationTimeInterval,
			};
			const response = await patch(`${API_URL.BUSINESSES}/${_id}`, payload);
			const {
				verificationTimeInterval: intervalData,
				_id: responseId,
				message,
			} = response ?? {};
			if (intervalData && responseId) {
				setSuperAdminData((pre) => {
					const prevState = JSON.parse(JSON.stringify(pre ?? []));
					const findIndex =
						prevState?.findIndex(({ _id }) => _id === responseId) ?? 0;
					prevState[findIndex].verificationTimeInterval = intervalData;
					return prevState;
				});
				successNotification(
					message ?? 'Verifications time intervals updated successfully.'
				);
				handleCloseTimeIntervalModal();
			} else {
				errorNotification(message ?? 'Something went wrong.');
			}
			return !!intervalData;
		},
		[getDays]
	);

	const handleTimeChange = useCallback((e, key) => {
		const { value } = e.target ?? {};
		if (!value) {
			setVerificationsData((pre) => ({
				...pre,
				[key]: {
					...pre[key],
					time: '',
				},
			}));
			return;
		}
		if (!isNumber(value)) return;
		setVerificationsData((pre) => ({
			...pre,
			[key]: {
				...pre[key],
				time: value,
			},
		}));
	}, []);

	const handleIntervalChange = useCallback((e, key) => {
		const { value, label } = e ?? {};
		setVerificationsData((pre) => ({
			...pre,
			[key]: {
				...pre[key],
				interval: { label, value },
			},
		}));
	}, []);

	return {
		handleOpenTimeIntervalModal,
		handleCloseTimeIntervalModal,
		handleSaveTimeInterval,
		handleTimeChange,
		handleIntervalChange,
		loading,
	};
};
