import type { ISuperAdmin, ISuperAdminRow } from "./type";

import { atom } from "recoil";

export const SuperAdminState = atom<ISuperAdmin[] | any>({
  key: "super-admin-fund-detail",
  default: [],
});

export const SuperAdminRowState = atom<ISuperAdminRow[]>({
  key: "super-admin-fund-detail-tables",
  default: [],
});
