export const HEADER_TITLE = {
	TOTAL_REVENUE: 'total consumption',
	TOTAL_TRANSACTION: 'total transactions',
	ANALYTICS: 'analytics',
	TOTAL_BUSINESSES: 'Businesses',
};

export const overheadIcons = {
	onboardingFlows: 'onboarding',
	payAsYouGo: 'pay',
	revenue: 'dollar',
	sessionCount: 'session',
	totalBusiness: 'business',
	totalRevenue: 'dollar',
};

export const OverheadConstant = {
	revenue: 'Total revenue',
	sessionCount: 'Total sessions',
	onboardingFlows: 'Total Onboarding Flows',
	payAsYouGo: 'Pay as you go',
	totalBusiness: 'Number of businesses',
	totalRevenue: 'Total revenue',
};

export const searchDropdownOptions = [
	{
		value: 'company',
		label: 'Search by company',
	},
	{
		value: 'name',
		label: 'Search by name',
	},
	{
		value: 'email',
		label: 'Search by email',
	},
	{
		value: 'address',
		label: 'Search by address',
	},
];

export const SessionFilterOptions = [
	{
		value: false,
		label: 'Active',
	},
	{
		value: true,
		label: 'Archived',
	},
];

export const IMAGE_GCP_URL =
	'https://storage.googleapis.com/satschel-assets-public/assets/illustrations/simplici-media';

export const COMPANY_LOGO =
	'https://storage.googleapis.com/satschel-public-assets/images/logo/simplici-wordmark.png';
