import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { REACT_APP_API_HOST as API_HOST } from 'envs';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { getCookie, setCookie } from 'utils/cookies';


/**
 * 
 * TODO: THis is a basic setup to let the PowerBI embded report works in super admin, later this needs to be refactor to current project standard
 * @ Shabaz, @Avinash, @Gaurav
 */

export function Revenue() {
	const [embdedToken, setEmbdedToken] = useState({ token: '', report: '' });
	const config = {
		method: 'get',
		maxBodyLength: Infinity,
		url: `${API_HOST}/power-bi`,
		headers: {},
	};

	useEffect(() => {
		const getEmbededToken = async () => {
			const cookieValue = getCookie('powerBIAccessToken'); // Retrieving the value of the cookie named "myCookie"

			if (!cookieValue) {
				const resp = await axios.request(config);

				const token = resp.data?.data?.embededToken?.data?.token;
				const reportId = resp.data?.data?.reports?.[0]?.id;

				const data = {
					token: token,
					report: reportId,
				};

				setCookie('powerBIAccessToken', data, 60);

				setEmbdedToken(data);
			} else {
				setEmbdedToken(cookieValue);
			}
		};
		getEmbededToken();
	}, []);

	return (
		<main className="flex min-h-screen flex-col items-center justify-between p-24">
			<PowerBIEmbed
				embedConfig={{
					type: 'report',
					id: embdedToken?.report,
					embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${embdedToken?.report}&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtVVMtRS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZX19`,
					accessToken: embdedToken?.token,
					tokenType: models.TokenType.Embed,
					settings: {
						panes: {
							filters: {
								expanded: false,
								visible: false,
							},
						},
					},
				}}
				eventHandlers={
					new Map([
						[
							'loaded',
							function () {},
						],
						[
							'rendered',
							function () {},
						],
						[
							'error',
							function () {},
						],
						['visualClicked', () => {}],
						['pageChanged', () => {}],
					])
				}
				cssClassName={'Embed-container'}
				getEmbeddedComponent={(embeddedReport) => {
					(window as any).report = embeddedReport;
				}}
			/>
		</main>
	);
}
