import { FC, useEffect } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Button } from '@storybook';
import { IActionProps, UploadedEnvelopeDocsState } from 'views';

export const ActionComp: FC<IActionProps> = () => {
	const setEnvelopeData = useSetRecoilState(UploadedEnvelopeDocsState);

	const envelopeData = useRecoilValue(UploadedEnvelopeDocsState);

	useEffect(() => {
		if (envelopeData) {
			setEnvelopeData(envelopeData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [envelopeData]);

	function handleDeleteFile(): void {
		setEnvelopeData((prevEnvelopeData) => ({
			...prevEnvelopeData,
			data: {
				...prevEnvelopeData.data,
				originalname: '',
				base64File: '',
			},
		}));
	}

	return (
		<div className={`upload-doc-table__${'action-wrapper'}`}>
			<Button
				handleClick={handleDeleteFile}
				disabled={false}
				label={
					<>
						<i className="ri-delete-bin-5-fill" />
					</>
				}
				type="button__ghost--danger upload-doc-table__delete-button"
			/>
		</div>
	);
};
