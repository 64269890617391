// import { getCuurentUrl } from 'utils';
import { IMAGE_GCP_URL } from 'constant';
interface IImage {
	fileName: string;
	url?: string;
	className?: string;
	height?: string;
	width?: string;
	props?: any;
	isLoad?: any;
}

export const Image = ({
	fileName,
	className,
	url: imageUrl,
	height,
	width,
	props,
}: IImage) => {
	// const url = imageUrl ?? `${getCuurentUrl()}/media/${fileName}`;
	const url = imageUrl ?? `${IMAGE_GCP_URL}/${fileName}`;
	return (
		<img
			src={url}
			alt={fileName}
			className={className ?? ''}
			height={height}
			width={width}
			{...props}
		/>
	);
};
