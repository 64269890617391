export const API_URL = {
	BUSINESSES: '/businesses',
	CLIENT_LOGIN: 'client-login',
	USER_LOGIN: '/user-login',
	SESSION_DETAILS: '/super-admin-session-details',
	SESSION_DOWNLOAD: '/super-admin-session-reports',
	AUDIT_LOG: '/audit-logs',
	REVENUE: '/revenue',
	REVENUE_NEW: '/revenue-new',
	ANALYTICS: '/superadmin-analytics',
	KYB: '/business-kyb',
	BUSINESS_DETAILS: '/business-prices?businessId=',
	SESSION_LIST: '/session-lists?businessId=',
	COMPLEX_SESSION_LIST: '/complex-sessions?business=',
	CLIENT_SOCIAL_LOGIN: '/social-login',
	CLIENT_WEBAUTHN: '/web-authentication',
	CLIENT_VERIFY_PHONE: '/verify-phone',
	USER_DETAILS: '/user-details',
	USERS: '/users',
	ROLES: '/roles',
	SIMPLICI_USERS: '/simplici-users',
	SIMPLICI_TOKEN: '/simplici-token',
	PERMISSION_LIST: '/access-modules',
	BUSSINESS_COMPLIIANCE: '/business-compliance',
	EMAIL_INVITE: '/email-invite',
}
