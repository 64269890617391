import { FC } from 'react';
import './header.scss';

type Title = {
	title: string;
};

export const Header: FC<Title> = (props) => {
	const { title } = props;
	return (
		<div className="header--container">
			<p>{title}</p>
		</div>
	);
};
