import { FC } from 'react';

import { Loader } from '@storybook';

import './screen-loader.scss';

interface IScreenLoader {
	visible: boolean;
}

export const ScreenLoader: FC<IScreenLoader> = ({ visible }) => {
	if (!visible) return <></>;
	return (
		<div className="screen-loader--wrapper">
			<Loader dimension={50} />
		</div>
	);
};
