import { atom } from 'recoil';
import { ICompanyList } from 'views/types';
import {
	IEnvelopeState,
	ISelectedBusiness,
	ITimeIntervalVerificationData,
} from './types';

interface IGrid {
	readOnly: boolean;
	value?: any;
	className?: string;
	keys?: string;
	stepId?: string;
	price?: string;
	overflow?: string;
	service?: string;
}

interface IBillingTableData {
	grid: IGrid[][];
}

export const BillingTableDataState = atom<
	IBillingTableData | Record<string, never>
>({
	key: 'billing-details-state',
	default: {},
});

export const BillingTableErrorState = atom<boolean>({
	key: 'billing-table-error-state-key',
	default: false,
});

export const SuperAdminDetails = atom<ICompanyList[]>({
	key: 'super-admin-details-key',
	default: [],
});

export const IsVerificationsModalOpenState = atom<boolean>({
	key: 'is-verification=modal-open-state-key',
	default: false,
});

export const SelectedBusinessState = atom<ISelectedBusiness>({
	key: 'selected-business-state-key',
	default: {
		_id: '',
		users: {
			firstName: '',
			lastName: '',
			email: '',
		},
		verificationTimeInterval: {
			kyc: 0,
			kyb: 0,
			authentication: 0,
		},
	},
});
export const IsVerificationModalEditableState = atom<boolean>({
	key: 'is-verification-modal-editable-state-key',
	default: false,
});
export const IsFileUploadState = atom<boolean>({
	key: 'is-file-upload-state',
	default: false,
});
export const TimeIntervalVerificationDataState =
	atom<ITimeIntervalVerificationData>({
		key: 'time-interval-verification-data-state-key',
		default: {
			kyc: {
				time: 0,
				interval: {
					label: 'days',
					value: 'days',
				},
			},
			kyb: {
				time: 0,
				interval: {
					label: 'days',
					value: 'days',
				},
			},
			authentication: {
				time: 0,
				interval: {
					label: 'days',
					value: 'days',
				},
			},
		},
	});

export const UploadedEnvelopeDocsState = atom<IEnvelopeState>({
	key: 'uploaded-envelope-docs-state-key',
	default: {
		isLoading: false,
		data: {
			base64File: '',
			originalname: '',
			size: '',
			extension: '',
		},
	},
});

export const newUploadFile = atom<string[]>({
	key: 'new-upload-file',
	default: [],
});

export const IsStatusCollapseState = atom<boolean>({
	key: 'is-status-collapse-state-key',
	default: false,
});


export const InboxfilteringTagsState = atom<any>({
	key: 'inbox-fitlering-tags-state-key',
	default: {},
});

export const InboxfiltersIndicatorState = atom<number>({
	key: 'inbox-filter-indicators-state-key',
	default: 0,
});
