import { useCallback, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

import { API_URL, message } from 'constant';
import { useCookie, useNetwork, useNotification } from 'hooks';
import { isLoggedInState, userToken } from 'states';

import {
	ICredentialPayload,
	IsLoginEmailVerifiedState,
	LoginCurrentStepState,
	LoginEmailCredState,
	LoginMaskPhoneCredState,
	MobileLoginLinkResponseState,
	OptionsResponseState,
	isPhoneVerifiedWebAuthRegister,
	phoneVerifiedWebAuthRegisterToken,
} from '../states';

export const useLogin = () => {
	const loginEmail = useRecoilValue(LoginEmailCredState);
	const setIsLoggedIn = useSetRecoilState(isLoggedInState);
	const setUserToken = useSetRecoilState(userToken);
	const setLoginCurrentStep = useSetRecoilState(LoginCurrentStepState);
	const setOptionsResponse = useSetRecoilState(OptionsResponseState);
	const setPhoneWebAuthRegisterToken = useSetRecoilState(
		phoneVerifiedWebAuthRegisterToken
	);

	const [isPhoneVerifiedWebAuth, setIsPhoneVerifiedWebAuth] = useRecoilState(
		isPhoneVerifiedWebAuthRegister
	);
	const [isLoginEmailVerified, setIsLoginEmailVerified] = useRecoilState(
		IsLoginEmailVerifiedState
	);
	const [maskPhoneCred, setMaskPhoneCred] = useRecoilState(
		LoginMaskPhoneCredState
	);

	const [{ verificationId }, setSendLinkResponse] = useRecoilState(
		MobileLoginLinkResponseState
	);
	const [isResending, setIsResending] = useState(false);
	const [isApprovalLoading, setIsApprovalLoading] = useState(false);
	const [isSocialLoading, setIsSocialLoading] = useState(false);

	const { successNotification, errorNotification } = useNotification();
	const { post: postEmailLogin, loading } = useNetwork();
	const { get: getSocial } = useNetwork();
	const { post } = useNetwork();
	const navigate = useNavigate();
	const { deleteCookie } = useCookie();

	const registrationOptionsWithToken = useCallback(
		async (accessToken?: any) => {
			const configHeader = { Authorization: `Bearer ${accessToken}` };
			const optionPayload = {
				type: 'registrationOptsUID',
			};
			const response = await post(
				API_URL.CLIENT_WEBAUTHN,
				optionPayload,
				configHeader
			);

			const { id, registrationOptions } = response?.data ?? {};
			if (id && registrationOptions) {
				setPhoneWebAuthRegisterToken(accessToken);
				setOptionsResponse(response.data);
			} else {
				errorNotification(
					response?.message ?? message.SomethingWentWrongMessage
				);
			}
		},
		[]
	);

	const handleOnSuccess = useCallback(
		(token: string) => {
			setIsLoggedIn(true);
			localStorage.setItem('token', JSON.stringify(token));
			setUserToken(token);
			successNotification('Login successfully!');
			navigate('../pending-businesses');
		},
		[navigate, setIsLoggedIn, successNotification]
	);

	const verifyEmail = useCallback(async () => {
		const payload: ICredentialPayload = {
			type: 'email',
			email: loginEmail.trim().toLowerCase(),
		};
		const response = await postEmailLogin(API_URL.USER_LOGIN, payload);
		if (response?.data) {
			setIsLoginEmailVerified(response?.data?.isVerifiedEmail);
			setIsPhoneVerifiedWebAuth(response?.data?.isVerifiedPhone);

			if (!response?.data?.isVerifiedEmail) {
				setLoginCurrentStep('OTP_VERIFY');
				successNotification(response?.data?.message);
			} else {
				setLoginCurrentStep('CHOOSE_METHOD');
				setMaskPhoneCred({
					countryCode: response?.data?.countryCode,
					phone: response?.data?.phone,
					userId: response?.data?.userId,
				});
			}
		} else {
			errorNotification(response?.message ?? message.SomethingWentWrongMessage);
		}
	}, [loginEmail]);

	const verifyEmailOtp = useCallback(
		async (payload: ICredentialPayload) => {
			const response = await postEmailLogin(API_URL.USER_LOGIN, payload);

			if (response?.data) {
				if (isLoginEmailVerified) {
					if (
						response?.data?.success &&
						response?.data?.status === 'approved' &&
						response?.data?.token
					) {
						if (!isPhoneVerifiedWebAuth) {
							setIsPhoneVerifiedWebAuth(true);
							setLoginCurrentStep('CHOOSE_METHOD');

							await registrationOptionsWithToken(response.data.token);
						} else {
							handleOnSuccess(response.data.token);
						}
					}
				} else {
					if (response?.data?.success) {
						successNotification(response?.data?.message);
						setLoginCurrentStep('CHOOSE_METHOD');
						setMaskPhoneCred({
							countryCode: response?.data?.countryCode,
							phone: response?.data?.phone,
							userId: response?.data?.userId,
						});
					}
				}
			} else {
				errorNotification(response?.message);
			}
		},
		[isLoginEmailVerified, isPhoneVerifiedWebAuth]
	);

	const getStatus = useCallback(async () => {
		const payload = {
			type: 'getTokenWithCode',
			verificationId,
		};
		const response = await postEmailLogin(API_URL.USER_LOGIN, payload);

		if (response?.data) {
			const { status, token } = response?.data ?? {};

			if (token && status === 'approved') {
				handleOnSuccess(token);
				return;
			}
			if (status === 'rejected') {
				setLoginCurrentStep('CHOOSE_METHOD');
				errorNotification(response?.data?.message);
				return;
			}
		} else {
			errorNotification(response?.message);
		}
	}, [setLoginCurrentStep, verificationId]);

	const handleResendLink = useCallback(async () => {
		if (isResending) {
			return;
		}
		setIsResending(true);
		const payload = {
			type: 'verifyPhone',
			countryCode: maskPhoneCred.countryCode,
			phone: maskPhoneCred.phone,
			userId: maskPhoneCred.userId,
		};

		const response = await postEmailLogin(API_URL.USER_LOGIN, payload);

		if (response?.data) {
			successNotification(response?.data?.message);

			setSendLinkResponse({ verificationId: response?.data?.verificationId });
		} else {
			errorNotification(response?.message ?? message.SomethingWentWrongMessage);
		}
		setIsResending(false);
	}, [isResending, maskPhoneCred]);

	const handleMobileApproval = useCallback(
		async (isWebAuth?: boolean) => {
			if (isApprovalLoading) {
				return;
			}
			if (!isWebAuth) {
				setIsPhoneVerifiedWebAuth(true);
			}
			setIsApprovalLoading(true);

			const payload = {
				type: 'verifyPhone',
				countryCode: maskPhoneCred.countryCode,
				phone: maskPhoneCred.phone,
				userId: maskPhoneCred.userId,
			};

			const response = await postEmailLogin(API_URL.USER_LOGIN, payload);

			if (response?.data) {
				successNotification(response?.data?.message);
				if (!response?.data?.isVerifiedPhone) {
					setIsLoginEmailVerified(true);
					setLoginCurrentStep('OTP_VERIFY');
				} else {
					setLoginCurrentStep('MOBILE_APPROVAL');
					setSendLinkResponse({
						verificationId: response?.data?.verificationId,
					});
				}
			} else {
				errorNotification(
					response?.message ?? message.SomethingWentWrongMessage
				);
			}
			setIsApprovalLoading(false);
		},
		[maskPhoneCred, isApprovalLoading]
	);

	const loginWithSocial = useCallback(
		async () => {
			setIsSocialLoading(true);
			deleteCookie('user');
			localStorage.clear();
			await getSocial(
				`${API_URL.CLIENT_SOCIAL_LOGIN}?type=google&app=super`
			).then((res) => {
				setIsSocialLoading(false);
				const { data } = res ?? {};
				const { url } = data?.[0] ?? {};
				// eslint-disable-next-line @typescript-eslint/no-unused-expressions
				url ? (window.location.href = url) : errorNotification(res?.message);
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[getSocial]
	);

	return {
		verifyEmail,
		verifyEmailOtp,
		loading,
		handleOnSuccess,
		getStatus,
		handleResendLink,
		isResending,
		handleMobileApproval,
		isApprovalLoading,
		loginWithSocial,
		isSocialLoading,
	};
};
