import { useRecoilValue } from 'recoil';

import { Loader } from '@storybook';
import { useInterval } from 'hooks';
import {
	LoginMaskPhoneCredState,
	MobileLoginLinkResponseState,
	useLogin,
} from 'views';

import './mobile-link-sent.scss';

export const MobileLinkSent = () => {
	const { verificationId } = useRecoilValue(MobileLoginLinkResponseState);
	const maskPhoneCred = useRecoilValue(LoginMaskPhoneCredState);

	const { getStatus, handleResendLink, isResending } = useLogin();

	if (verificationId !== '') {
		useInterval(getStatus, 5000);
	}

	return (
		<div className="mobile-link">
			<div className="mobile-link__wrapper">
				<div className="mobile-link__row">
					<div className="mobile-link__row mobile-link__row-1 mobile-link__vertical-space">
						<Loader className="loader-blue" dimension={60} />
					</div>
					<div className="mobile-link__title">Waiting for approval</div>
					<div className="mobile-link__description">
						We have sent you a link for approval on{' '}
						<span>
							{maskPhoneCred.countryCode} {maskPhoneCred.phone}
						</span>
					</div>
					<div className="mobile-link__description">
						Kindly click on the link provided in the text message and complete
						the verification process from there.
					</div>
				</div>
				<div className="mobile-link__description">
					Didn’t received the text message?{' '}
					<span
						className={`mobile-link__resend ${
							isResending ? 'mobile-link__resend_stop' : ''
						}`}
						onClick={handleResendLink}
					>
						Resend
					</span>
				</div>
			</div>
		</div>
	);
};
