import { AllRoutes } from './routes';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'tippy.js/dist/tippy.css';
import './app.scss';

const App = () => {
	return (
		<div>
			<AllRoutes />
		</div>
	);
};

export default App;
