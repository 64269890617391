import { atom } from 'recoil';

interface ILoginMaskPhoneCredState {
	phone: string;
	countryCode: string;
	userId: string;
}

export type ICredentialPayload = {
	email?: string;
	type: 'email' | 'emailCode' | 'phoneCode' | 'verifyPhone';
	code?: string;
	choice?: 'accept';
	phone?: string;
	countryCode?: string;
	userId?: string;
};

type ILoginCurrentStep =
	| 'CREDS'
	| 'CHOOSE_METHOD'
	| 'MOBILE_APPROVAL'
	| 'OTP_VERIFY'
	| 'BIOMETRIC_APPROVAL';

export const LoginEmailCredState = atom<string>({
	key: 'login-email-cred-key',
	default: '',
});

export const LoginMaskPhoneCredState = atom<ILoginMaskPhoneCredState>({
	key: 'login-mask-phone-cred-key',
	default: { phone: '', countryCode: '', userId: '' },
});

export const LoginCurrentStepState = atom<ILoginCurrentStep>({
	key: 'login-current-step-key',
	default: 'CREDS',
});

export const MobileLoginLinkResponseState = atom<{
	verificationId: string;
}>({
	key: 'mobile-login-link-response-key',
	default: {
		verificationId: '',
	},
});

export const IsLoginEmailVerifiedState = atom<boolean>({
	key: 'is-login-email-verified-key',
	default: false,
});

export const ApprovalStatusState = atom<string>({
	key: 'approval-status-state-key',
	default: '',
});

export const VerificationIDState = atom<string>({
	key: 'verification-id-state-key',
	default: '',
});

export const OptionsResponseState = atom<any>({
	key: 'options-response-state-key',
	default: null,
});

export const isConfirmationModalOpen = atom({
	key: 'confirmation-modal-open',
	default: false,
});

export const ConfigHeaderForWebAuthnState = atom<any>({
	key: 'config-header-for-webauthn-state-key',
	default: null,
});

export const isWebAuthnSupported = atom<boolean>({
	key: 'is-web-authn-supported-key',
	default: false,
});

export const isWebAuthnRegisterLoader = atom<boolean>({
	key: 'is-web-authn-register-loader-state-key',
	default: false,
});

export const isPhoneVerifiedWebAuthRegister = atom<boolean>({
	key: 'is-phone-web-authn-register-state-key',
	default: false,
});

export const phoneVerifiedWebAuthRegisterToken = atom<string>({
	key: 'phone-web-authn-register-token-state-key',
	default: '',
});


