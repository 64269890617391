import { FC, useCallback, useMemo } from 'react';
import classNames from 'classnames';

import { TreeGraphChart } from './tree-graph-chart';
import {
	fundInvestmentType,
	sessionActionLine,
	sessionActionStatus,
} from '../../hooks';

import './tree-graph-chart.scss';

interface ISessionTreeGraph {
	details: any;
	showOnBoardingName?: boolean;
	viewComplexModal?: any;
	isInvite?: boolean;
	funds?: any[];
	docs?: any[];
	isSessionModal?: boolean;
	isSessionList?: boolean;
}

const countNodeLength = 1;

export const SessionTreeGraph: FC<ISessionTreeGraph> = ({
	details,
	showOnBoardingName = false,
	isInvite = false,
	funds = [],
	docs = [],
	isSessionModal = false,
	isSessionList = false,
	viewComplexModal,
}) => {
	const labelName = useCallback(
		(id: string) => {
			const nodeFiltered = (details?.design?.nodes || []).filter(
				(item: any) => item?.id === id
			);
			const filtered = (details?.nodes || []).filter(
				(item: any) => item?._id === id || item?.pipelineNodeId === id
			);

			if (
				filtered[0]?.label === 'Fund Investment' &&
				fundInvestmentType?.[filtered?.[0]?.type]
			) {
				return `Fund (${fundInvestmentType[filtered[0]?.type]})`;
			}
			if (
				filtered[0]?.label === 'Accreditation Verification' &&
				filtered?.[0]?.type
			) {
				return `Accreditation (${filtered[0]?.type})`;
			}
			//Deepak : Change Sign Agreement label to Sign Doc. in case of complex
			if (filtered[0]?.label === 'Sign Agreement') {
				return 'Sign Doc.';
			}

			return filtered[0]?.label || nodeFiltered[0]?.label || 'Loading...';
		},
		[details?.design?.nodes, details?.nodes]
	);

	const actionLinkStatus = useCallback(
		(to: string, from: string) => {
			const toFiltered = (details?.nodes || []).filter(
				(item: any) => item.pipelineNodeId === to
			);
			const fromFiltered = (details?.nodes || []).filter(
				(item: any) => item.pipelineNodeId === from
			);

			return (fromFiltered[0]?.action?.status || 'pending') !== 'pending' &&
				sessionActionLine[toFiltered[0]?.action?.status] ===
					sessionActionLine[fromFiltered[0]?.action?.status]
				? '#0000FF'
				: '#B3B3B3';
		},
		[details?.nodes]
	);

	const chartData = useMemo(() => {
		const { design = {} } = details ?? {};
		const { edges = [] } = design;
		const data = (edges.length ? edges : [])?.map((item: any) => [
			item.source,
			item.target,
			actionLinkStatus(item.source, item.target),
		]);
		if (edges.length === 0) {
			return [[details?.design?.nodes?.[0]?.id]];
		}
		return data;
	}, [actionLinkStatus, details]);

	const nodeColor = useMemo(() => {
		const data = (details?.nodes || []).map((item: any) => {
			let color = sessionActionStatus[item?.action?.status] || '#8c97b8';
			const pipelineNodeId = item?._id;
			if (isInvite) {
				const isFundConfigured = !!funds.find(
					(fund: any) => fund.node === pipelineNodeId
				);

				const isDocConfigured = !!docs.find(
					(doc: any) => doc.node === pipelineNodeId
				);

				const isConfigured = isFundConfigured || isDocConfigured;
				if (isConfigured) {
					color = sessionActionStatus.completed ?? color;
				}
			}
			return {
				id: item?.pipelineNodeId ?? pipelineNodeId,
				color,
			};
		});

		return data;
	}, [details?.nodes, docs, funds, isInvite]);

	const onClick = useCallback(
		(id: string) => {
			if (!isInvite) {
				return;
			}

			const selectNode = details?.nodes?.find((node: any) => node._id === id);
			if (!selectNode) {
				return;
			}
		},
		[details?.nodes, isInvite]
	);

	const renderChart = useMemo(() => {
		const id = isInvite
			? `invite_${details._id}`
			: isSessionModal
			? `session${details._id}`
			: details._id;

		return (
			<TreeGraphChart
				isLoading={false}
				title={id}
				isInverted={false}
				seriesData={chartData}
				seriesLabelName={labelName}
				nodesColor={nodeColor}
				isInvite={isInvite}
				onClick={onClick}
				isSessionModal={isSessionModal}
			/>
		);
	}, [
		chartData,
		details._id,
		isInvite,
		isSessionModal,
		labelName,
		nodeColor,
		onClick,
	]);

	const chartClasses = useMemo(() => {
		return classNames('onboarding-session-tree-graph', {
			'session-tree-graph': isSessionList,
			'onboarding-invite-tree-graph': isInvite || isSessionModal,
		});
	}, [isSessionList, isInvite, isSessionModal]);

	return (
		<div className={chartClasses}>
			{renderChart}
			{!isInvite && !isSessionModal && chartData.length > countNodeLength && (
				<div className="tree-graph-show-more" onClick={viewComplexModal}>
					View <div>complete</div> flow
				</div>
			)}
			{showOnBoardingName && (
				<div className="tree-graph-onboarding-name">
					{details?.onBoardingName}
				</div>
			)}
		</div>
	);
};
