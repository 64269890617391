import { FC, useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { ConfirmationModal } from 'components';
import { useNotification } from 'hooks';
import { useRolepermission } from 'views/role-permission/hooks';
import {
	RolesAndPermissionCardData,
	UserRoleNavigation,
} from '../../constants';
import {
	IUserRoleState,
	InviteFormInput,
	SelectedUserRoleState,
	UserRolePermissionNavigation,
	UserRoleState,
} from '../../store';
import { DropInfoOptions } from '../info-drop-options';
import { PermissionTag } from '../permission-tag';
import { UserAvatar } from './user-avatar';
import { InviteUserForm } from '../invite-user-form';
import { message } from 'constant';
import { IUserDetailsState, UserDetailsState } from 'views';
import { Image } from '@storybook';

interface IRolesAndPermission {
	isUserPermissionWrite?: boolean;
}

export const RolesAndPermission: FC<IRolesAndPermission> = ({
	isUserPermissionWrite,
}) => {
	// global state
	const setUserRoleNavigate = useSetRecoilState(UserRolePermissionNavigation);
	const setSelectedUserRole = useSetRecoilState(SelectedUserRoleState);
	const [userRoleState, setUserRoleState] = useRecoilState(UserRoleState);
	const userDetails = useRecoilValue(UserDetailsState);

	const [roleItem, setRoleItem] = useState<IUserRoleState | null>(null);
	const [isDeleted, setDelete] = useState(false);
	const [isInviteModalOpen, setInviteModalOpen] =
		useState<IUserRoleState | null>(null);

	const { deleteUserRole, inviteUser, inviteUserLoader } = useRolepermission();
	const { successNotification, errorNotification } = useNotification();

	const { roles, firstName, lastName } = useMemo(
		() => userDetails as IUserDetailsState,
		[userDetails]
	);
	const { name: superName, permissions } = useMemo(
		() => roles?.[0]?.role,
		[roles]
	);

	const getPermission = useCallback((permission) => {
		return permission.map((item) => {
			const { _id, view = false, edit = false, create = false } = item;
			const subModules = item.subModules.map((subModule) => ({
				subModuleId: subModule._id,
				view: subModule.view ?? false,
				edit: subModule.edit ?? false,
				create: subModule.create ?? false,
			}));

			return {
				module: _id,
				subModules,
				view,
				edit,
				create,
			};
		});
	}, []);

	const handleEditRole = (item) => {
		setUserRoleNavigate(UserRoleNavigation.ComplainceAgent);

		setSelectedUserRole((pre) => ({
			...pre,
			...item,
			permissions: getPermission(item.permissions),
		}));
	};

	const handleInviteRole = (e: React.MouseEvent<HTMLInputElement>, item) => {
		e.stopPropagation();
		setInviteModalOpen(item);
	};

	const onConfirmed = useCallback(
		async (isOpen1: boolean, isOpen2: boolean) => {
			if (!isOpen2 && !isOpen1) {
				setRoleItem(null);
				return;
			}
			setDelete(true);
			const resp = await deleteUserRole(roleItem._id);
			if (resp) {
				successNotification(message.DELETE_MESSAGE);
				setUserRoleState((prev) => {
					const updatedList = prev?.filter((el) => el._id !== roleItem._id);
					return updatedList;
				});
				setRoleItem(null);
			} else {
				errorNotification(resp.message);
			}
			setDelete(false);
		},
		[roleItem]
	);

	const handleOpenDelete = (role: any, e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
		setRoleItem(role);
	};

	const handleCloseModal = useCallback(() => {
		setInviteModalOpen(null);
	}, []);

	const handleInviteUser = useCallback(
		async (payload: InviteFormInput) => {
			payload['roles'] = [{ role: isInviteModalOpen?._id ?? '' }];
			const resp = await inviteUser(payload);

			if (resp?._id) {
				const invitedIndex: any =
					(userRoleState ?? []).findIndex(
						(el) => el._id === isInviteModalOpen._id
					) ?? {};

				setUserRoleState((pre: any) =>
					Object.values({
						...pre,
						[invitedIndex]: {
							...pre[invitedIndex],
							users: [{ ...resp }, ...pre[invitedIndex]['users']],
							count: pre[invitedIndex].count + 1,
						},
					})
				);
				setInviteModalOpen(null);
			}
		},
		[isInviteModalOpen]
	);

	return (
		<div className="RolesAndPermission">
			<div className="RolesAndPermission--container owner-permission">
				<div className="RolesAndPermission--container__header hover">
					<div className="RolesAndPermission--container__header--label">
						<div className="RolesAndPermission--container__text">
							<div className="RolesAndPermission--container__labelContainer">
								<div className="RolesAndPermission--container__header--label__title">
									{superName || 'NA'} (YOU)
								</div>
								<div className="RolesAndPermission--container__header--label__subTitle">
									{`${firstName} ${lastName}` || 'NA'}
								</div>
							</div>
							<PermissionTag role={permissions} />
						</div>
					</div>
					<div className="RolesAndPermission--container__userProfile">
						<Image
							fileName="AvatarDefault-Light.svg"
							className="overlap-image"
						/>
					</div>
				</div>
			</div>
			{!!userRoleState?.length &&
				(userRoleState ?? [])?.map((role) => (
					<div
						className="RolesAndPermission--container"
						key={role._id}
						onClick={() => handleEditRole(role)}
					>
						<div className="RolesAndPermission--container__header hover">
							<div className="RolesAndPermission--container__header--label">
								<div className="RolesAndPermission--container__text">
									<div className="RolesAndPermission--container__labelContainer">
										<div className="RolesAndPermission--container__header--label__title">
											{role?.name ?? 'NA'}
										</div>
										<div className="RolesAndPermission--container__header--label__subTitle">
											{role?.description ?? 'NA'}
										</div>
									</div>
									<PermissionTag role={role.permissions} />
								</div>
								<div className="RolesAndPermission--container__dropOptions">
									{isUserPermissionWrite ? (
										<DropInfoOptions
											showEdit={false}
											handleDelete={(e) => handleOpenDelete(role, e)}
										/>
									) : (
										<></>
									)}
								</div>
							</div>
							<div className="RolesAndPermission--container__inviteUser">
								<div className="RolesAndPermission--container__inviteUser--icon">
									{Array(role?.count ?? 0)
										.fill(4)
										.map((user, index) => {
											if (index < user) {
												return <UserAvatar key={index?.toString()} />;
											}
										})}

									{role?.count > 3 ? (
										<div className="overlap-count">+{role?.count - 3}</div>
									) : (
										<div className="RolesAndPermission--overlap-opacity"></div>
									)}
								</div>
								{isUserPermissionWrite ? (
									<div
										className="RolesAndPermission--container__inviteUser--label"
										onClick={(e: React.MouseEvent<HTMLInputElement>) =>
											handleInviteRole(e, role)
										}
									>
										{RolesAndPermissionCardData.Invite_User}
									</div>
								) : null}
							</div>
						</div>
					</div>
				))}
			{!!isInviteModalOpen && (
				<InviteUserForm
					handleInviteUser={handleInviteUser}
					handleCloseModal={handleCloseModal}
					isApiCalling={inviteUserLoader}
					isInviteModalOpen={!!isInviteModalOpen}
				/>
			)}

			{!!roleItem && (
				<ConfirmationModal
					description={`Deleting ${roleItem.name} role will remove user’s permissions from the system.`}
					boldDescription={` Do you still want to delete ${roleItem.name} role?`}
					label="Delete"
					title="Are you sure?"
					visible={!!roleItem}
					showLoader={isDeleted}
					handleModal={onConfirmed}
				/>
			)}
		</div>
	);
};
