import { useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { ChartTitleActivity, TransactionsRevenueChartState } from 'views';
import { useNetwork } from 'hooks';
import { API_URL, HEADER_TITLE } from 'constant';
import { Header } from 'components';
import { BarChart } from '../analyticsChart';

import './analytics-overview.scss';

export const AnalyticsOverview: React.FC<{ id: string }> = ({ id }) => {
	const [transactionsTotal, setTransactionsTotalRevenue] = useRecoilState(
		TransactionsRevenueChartState
	);
	const {
		get: getAnalyticsChartData,
		data: getAnalyticsData,
		loading,
	} = useNetwork();

	useEffect(() => {
		if (!Object.keys(transactionsTotal).length && id)
			getAnalyticsChartData(`${API_URL.ANALYTICS}?business=${id}&graph=true`);
	}, [transactionsTotal, id]);

	useEffect(() => {
		if (getAnalyticsData?.data?.length) {
			setTransactionsTotalRevenue(getAnalyticsData?.data?.[0]);
		}
	}, [getAnalyticsData?.data]);

	const XAxisValue = useMemo(() => {
		return Object.keys(transactionsTotal ?? {}).map(
			(item) => ChartTitleActivity?.[item] ?? item
		);
	}, [transactionsTotal]);

	const yAxisValue = useMemo(() => {
		return Object.keys(transactionsTotal ?? {}).map(
			(item) => transactionsTotal?.[item]?.total ?? 0
		);
	}, [transactionsTotal]);

	return (
		<div className="analytics-overview">
			<Header title={HEADER_TITLE.TOTAL_REVENUE} />
			<BarChart
				isLoading={loading}
				title="Analytics-transactions"
				yAxis={yAxisValue}
				xAxis={XAxisValue}
				totalAmount={transactionsTotal}
			/>
		</div>
	);
};
