// manish, awadhesh, vaishali,
export const SUPER_ADMINS = [
  "63469ec1bb97b1d8eee92800",
  "62e3ded007181417d85ebfe6",
  "62e22fa947f503190143a3ef",
  "632e13054236dd2f46e81553", // Austin
  "634e8141f5eae387f8fce238",
  "63522909f5eae387f8fe14a2",
];

export const createdAt = new Date('2021-01-01');
