import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { REACT_APP_API_HOST as API_HOST } from 'envs';
import { userToken } from 'states';

interface IConfig {
	headers?: {
		Authorization?: string;
		'Content-Type'?: string;
	};
}

export const useNetwork = () => {
	const accessToken = useRecoilValue(userToken);
	// const { accessToken: token } = useRecoilValue(loginState);
	// const accessToken = token;
	const [data, setData] = useState<any>(null);
	const [error, setError] = useState<any>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [status, setStatus] = useState<any>(null);
	const [statusCode, setStatusCode] = useState<any>(null);
	const [isLoaded, setIsLoaded] = useState<boolean>(false);

	const config: IConfig = useMemo(() => ({}), []);
	const postConfig: IConfig = useMemo(() => ({}), []);

	useEffect(() => {
		if (statusCode === 401) {
			localStorage.removeItem('token');
			window.location.href = '/signin';
		}
	}, [status]);

	if (accessToken) {
		config.headers = {
			Authorization: `Bearer ${accessToken}`,
		};
	}
	postConfig.headers = {
		'Content-Type': 'application/json',
		...(config.headers ?? {}),
	};

	const get = useCallback(
		async (url: string, abortController?: any): Promise<any> => {
			setLoading(true);
			let controller: any;
			if (abortController) {
				controller = new AbortController();
				abortController.push(controller);
			}
			try {
				const response = await fetch(API_HOST + url, {
					...config,
					...(abortController && { signal: controller.signal }),
				});
				const apiPayload = await response.json();
				setStatus(response?.ok);
				setIsLoaded(true);
				setData(apiPayload);
				setStatusCode(response.status);
				return apiPayload;
			} catch (err: any) {
				setError(err);
				return null;
			} finally {
				setLoading(false);
			}
		},

		[config, accessToken]
	);

	const post = useCallback(
		async (url: string, requestJSON: any, configHeader?: any) => {
			setLoading(true);
			if (configHeader) {
				postConfig.headers = {
					...postConfig.headers,
					...configHeader,
				};
			}
			try {
				const response = await fetch(API_HOST + url, {
					method: 'POST',
					...postConfig,
					body: JSON.stringify(requestJSON),
				});
				if (response.status === 500) {
					setError(response.type);
				}
				setStatus(response?.ok);
				const apiData = await response.json();
				if (url.includes('charts?businessId')) {
					setIsLoaded(true);
					setData(apiData);
					return apiData;
				}
				const apiResponse = apiData;
				setIsLoaded(true);
				setData(apiResponse);
				setStatusCode(response.status);
				return apiResponse;
			} catch (err: any) {
				setError(err);
				return null;
			} finally {
				setLoading(false);
			}
		},

		[postConfig, accessToken]
	);

	const formData = useCallback(
		async (url: string, requestJSON: any) => {
			setLoading(true);
			try {
				const response = await fetch(API_HOST + url, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
					body: requestJSON,
				});
				const apiData = await response.json();
				setStatus(response?.ok);
				setIsLoaded(true);
				setData(apiData);
				setLoading(false);
				setStatusCode(response.status);
				return apiData;
			} catch (err) {
				setError(err);
				return null;
			} finally {
				setLoading(false);
			}
		},
		[accessToken]
	);

	const put = useCallback(
		async (url: string, requestJSON?: any) => {
			try {
				const response = await fetch(API_HOST + url, {
					method: 'PUT',
					...postConfig,
					body: JSON.stringify(requestJSON),
				});
				setStatus(response?.ok);
				const apiData = await response.json();
				setStatus(response.status);
				setIsLoaded(true);
				setData(apiData.data);
				setStatusCode(response.status);
				return apiData.data;
			} catch (err: any) {
				setError(err);
				return null;
			} finally {
				setLoading(false);
			}
		},

		[postConfig, accessToken]
	);

	const remove = useCallback(
		async (url: string, requestJSON?: any) => {
			try {
				const response = await fetch(API_HOST + url, {
					method: 'DELETE',
					...postConfig,
					body: JSON.stringify(requestJSON),
				});

				setStatus(response?.ok);
				const apiData = await response.json();
				setStatus(response.status);
				setIsLoaded(true);
				setData(apiData.data ?? apiData);
				setStatusCode(response.status);
				return apiData.data ?? apiData;
			} catch (err: any) {
				setError(err);
				return null;
			} finally {
				setLoading(false);
			}
		},

		[postConfig, accessToken]
	);

	const patch = useCallback(
		async (url: string, requestJSON?: any) => {
			setLoading(true);
			try {
				const response = await fetch(API_HOST + url, {
					method: 'PATCH',
					...postConfig,
					body: JSON.stringify(requestJSON),
				});
				setStatus(response?.ok);
				const apiData = await response.json();
				setIsLoaded(true);
				const apiResponse = apiData.data ?? apiData;
				setData(apiResponse);
				setStatusCode(response.status);
				return apiData.data ?? apiData;
			} catch (err: any) {
				setError(err);
				return null;
			} finally {
				setLoading(false);
			}
		},

		[postConfig, accessToken]
	);

	return {
		get,
		post,
		formData,
		put,
		data,
		status,
		statusCode,
		error,
		loading,
		setLoading,
		remove,
		patch,
		isLoaded,
		setIsLoaded,
	};
};
