import { FC, useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { EditableTable } from '@storybook';

import { useNetwork, useNotification } from 'hooks';
import { convertStringToNumber, multiplyNumber } from 'utils';
import {
	BillingTableErrorState,
	BillingTableDataState,
} from 'views';
import { API_URL } from 'constant';

import 'react-datasheet/lib/react-datasheet.css';
import './billing-details.scss';

interface FCBillingDetails {
	item: any;
}
export const BillingDetails: FC<FCBillingDetails> = ({ item }) => {
	const [tableData, setTableData] = useRecoilState(BillingTableDataState);
	const setIsBillingTableError = useSetRecoilState(BillingTableErrorState);
	const { errorNotification } = useNotification();

	const { get: fetchBusinessDetails, loading } = useNetwork();

	const indexingServices = {
		onboardingService: { index: 0 },
		idvDocument: { index: 1 },
		aml: { index: 2 },
		geoLocation: { index: 3 },
		kyb: { index: 4 },
		payIn: { index: 5 },
		payOut: { index: 6 },
		address: { index: 7 },
		ssn: { index: 8 },
		deviceBiometric: { index: 9 },
		ocr: { index: 10 },
		liveness: { index: 11 },
		webComponent: { index: 12 },
		'506b': { index: 13 },
		'506c': { index: 14 },
		simpliciSignAgreement: { index: 15 },
		docusignSignAgreement: { index: 16 },
		simpliciSignService: { index: 17 },
		simpliciMultiSign: { index: 18 },
		simpliciTextService: { index: 19 },
		simpliciText: { index: 20 },
	};
	const transformData = (input: any) => {
		// Define the headers
		const output: any = [
			[
				{ readOnly: true, value: 'Service', className: 'title' },
				{ value: 'Monthly per user', readOnly: true, className: 'title' },
				{ value: 'Per use domestic', readOnly: true, className: 'title' },
				{ value: 'Per use International', readOnly: true, className: 'title' },
				{ value: 'Number Used', readOnly: true, className: 'title' },
				{ value: 'Total Billing', readOnly: true, className: 'title' },
			],
		];

		const newArr = new Array(20);

		Object.keys(input.data[0]).map((el) => {
			const foundIndex = indexingServices[el]?.index;
			if (foundIndex != undefined) {
				newArr[foundIndex] = {
					key: el,
					value: input.data[0][el],
				};
			}
		});
		// Transform each entry in the data
		for (const obj of newArr) {
			const { key, value } = obj ?? {};
			const { price, stepId, label, service, used = 0 } = value ?? {};
			const { monthly, domestic, international } = price ?? {};
			const totalValue = multiplyNumber(domestic, used);

			output.push([
				{
					readOnly: true,
					value: label,
					keys: key,
					stepId: stepId,
					price: 'label',
					className: monthly ? 'title' : '',
					overflow: 'wrap',
					service,
				},
				{
					readOnly: !!service,
					value: !service ? monthly?.toString() : '',
					keys: key,
					stepId: stepId,
					price: 'monthly',
					overflow: 'wrap',
					service,
				},
				{
					readOnly: !!monthly,
					value: service ? domestic?.toString() : '',
					keys: key,
					stepId: stepId,
					price: 'domestic',
					overflow: 'wrap',
					service,
				},
				{
					readOnly: !!monthly,
					value: service ? international?.toString() : '',
					keys: key,
					stepId: stepId,
					price: 'international',
					overflow: 'wrap',
					service,
				},
				{
					readOnly: true,
					value: service ? used?.toString() : '',
					keys: key,
					stepId: stepId,
					price: 'number_used',
					overflow: 'wrap',
					service,
				},
				{
					readOnly: true,
					value: service ? totalValue.toFixed(2) : '',
					keys: key,
					stepId: stepId,
					price: 'total_billing',
					overflow: 'wrap',
					service,
				},
			]);
		}
		return output;
	};

	useEffect(() => {
		if (!tableData.grid) {
			fetchBusinessDetails(`${API_URL.BUSINESS_DETAILS}${item.id}`)
				.then((res) => {
					if (res.data?.length) {
						const data = transformData(res);
						setTableData({ grid: data });
					}
				})
				.catch((error) => {
					errorNotification(
						error.message ?? 'Failed to fetch business details'
					);
				});
		}
	}, []);

	const onCellsChanged = (changes) => {
		setIsBillingTableError(false);
		const grid = structuredClone(tableData.grid);
		changes.forEach(({ cell, row, col, value }) => {
			if (!/^(0|[1-9]\d*)(\.\d+)?$/g.test(value)) {
				return setIsBillingTableError(true);
			}

			if (/number_used|domestic/.test(cell.price)) {
				const key = cell.price === 'number_used' ? 'domestic' : 'number_used';
				const totalBillingIndex = grid[row].findIndex(
					(el: any) => el.price === 'total_billing'
				);
				const numberUsedIndex = grid[row].findIndex(
					(el: any) => el.price === key
				);
				const totalValue = multiplyNumber(
					value,
					grid[row][numberUsedIndex]?.value ?? '0'
				);
				grid[row][totalBillingIndex] = {
					...grid[row][totalBillingIndex],
					value: convertStringToNumber(totalValue?.toString() ?? '0'),
				};
			}
			grid[row][col] = { ...grid[row][col], value };
		});
		setTableData({ grid });
	};

	return (
		<div className="db-wrapper">
			<EditableTable
				loading={loading}
				grid={tableData?.grid ?? [[]]}
				handleChange={onCellsChanged}
			/>
		</div>
	);
};
