import { IUploadDocHeader } from './states';

export const SUPER_ADMIN_TABLE = {
	HEADER: [
		{
			label: 'Company ',
			key: 'companyName',
			key2: 'address',
			format: 'jsx',
			className: '',
			width: '15%',
			sortableKey: 'company_name'
		},
		{
			label: 'Name & Email',
			key: 'users',
			format: 'jsx',
			className: '',
			width: '15%',
			sortableKey: 'user_name'
		},
		{
			label: 'Type',
			key: 'accountType',
			format: '',
			className: 'business-type',
			width: '12%',
		},
		{
			label: 'Date & Time',
			key: 'info',
			key2: 'createdAt',
			format: 'jsx',
			width: '10%',
		},
		{
			label: 'Physical Verification',
			key: 'physicalVerification',
			format: 'jsx',
			width: '5%',
		},
		{
			label: 'Status',
			key: 'approveStatus',
			format: 'jsx',
			width: '14%',
		},
		{
			label: 'Action',
			key: 'action',
			format: 'jsx',
			width: '5%',
		},
	],
};

export const WARNING_MESSAGE =
	'Note: Super Admin has the capability to modify prices as per customer requirement in the New Price table.';

export const PRODUCT_PRICING_HEADER = ['Actions', 'Default Price', 'New Price'];

export const TOAST_MESSAGES = {
	SUCCESS: 'price has been updated successfully',
	ERROR: 'something went wrong',
};

export const CUSTOM_PRICE_KEYS = {
	aml: 'AML',
	accreditation: 'Accreditation',
	kyc: 'KYC',
	signAgreement: 'Sign Agreement',
	fundInvestment: 'Fund Investment',
};

export const BUSINESS_USER_LIST_TABLE_HEADER = [
	{
		label: 'First Name ',
		key: 'firstName',
		format: 'string',
		className: '',
		width: '20%',
	},
	{
		label: 'Last Name',
		key: 'lastName',
		format: 'string',
		className: '',
		width: '20%',
	},
	{
		label: 'Role',
		key: 'role',
		format: 'string',
		width: '15%',
	},
	// {
	// 	label: 'Status',
	// 	key: 'status',
	// 	format: 'jsx',
	// 	width: '15%',
	// },
	{
		label: 'Action',
		key: 'action',
		format: 'jsx',
		width: '8%',
	},
];

// Session-list-constants
export const SessionTable = {
	HEADER: [
		{
			label: 'Fund/Company',
			key: 'fundName',
			format: 'string',
			width: '15%',
			className: 'fund_name',
		},
		{
			label: 'Name',
			key: 'name',
			format: 'string',
			width: '15%',
		},
		{
			label: 'Email & Contact',
			key: 'email',
			key2: 'contact',
			format: 'jsx',
			width: '15%',
			className: 'email__contact',
		},
		{
			label: 'Date & Time',
			key: 'date',
			key2: 'date',
			format: 'jsx',
			width: '13%',
		},
		{
			label: 'Status & Onboarding Flow',
			key: 'fullStatus',
			format: 'jsx',
			key2: 'onBoardingName',
			width: '27%',
		},
		{
			label: 'Actions',
			key: 'action',
			format: 'jsx',
			className: 'action__btn-Review',
			width: '12%',
		},
	],
};

export const Actions = [
	{
		label: 'Approved',
		value: 'completed',
		color: 'var(--color-green)',
	},
	{
		label: 'Pending',
		value: 'pending',
		color: 'var(--color-text-50-light)',
	},
	{
		label: 'Under Review',
		value: 'processing',
		color: 'var(--color-orange)',
	},
	{
		label: 'Rejected',
		value: 'rejected',
		color: '#F55353',
	},
];

export const lableData = {
	kyc: 'KYC',
	payOut: 'Fund (Payout)',
	payIn: 'Fund (Pay In)',
	fundInvestment: 'Fund (Pay In)',
	accreditation: 'Accreditation',
	'506c': 'Accred.(506c)',
	'506b': 'Accred.(506b)',
	aml: 'AML',
	signAgreement: 'Sign Doc.',
	authentication: 'Auth',
	form: 'Form',
	kyb: 'KYB',
	'kyc(with liveness)': 'KYC',
	signAgreement_esign: 'Sign Doc.',
	signAgreement_docusign: 'Sign Doc.',
	simpliciSignAgreement: 'Sign Doc.',
	docusignSignAgreement: 'Sign Doc.',
};

export const LabelDataActivity: { [key: string]: string } = {
	kyc: 'KYC',
	payOut: 'Fund (Payout)',
	payIn: 'Fund (Pay In)',
	fundInvestment: 'Fund (Pay In)',
	accreditation: 'Accreditation',
	'506c': 'Accred.(506c)',
	'506b': 'Accred.(506b)',
	aml: 'AML',
	signAgreement: 'Sign Doc.',
	authentication: 'Auth',
	form: 'Form',
	kyb: 'KYB',
	'kyc(with liveness)': 'KYC',
	signAgreement_esign: 'Sign Doc.',
	signAgreement_docusign: 'Sign Doc.',
};

export const sessionMessage = {
	AddCcMessage: 'Please add the email in CC',
};

export const FileTypes = {
	JPG: true,
	JPEG: true,
	PNG: true,
	DOC: true,
	DOCX: true,
	PDF: true,
	GIF: true,
	CSV: true,
	TXT: true,
	XSL: true,
};

export const SessionTypesQuery = {
	Archived_Sessions: 'archived_session',
};

export const TOTAL_TRANSACTION_LIST_TABLE = {
	HEADER: [
		{
			label: 'Transaction ID ',
			key: 'transactionId',
			format: 'string',
			className: 'total-transaction-table',
			width: '18%',
		},
		{
			label: 'Date & Time',
			key: 'time',
			format: 'jsx',
			className: '',
			width: '15%',
		},
		{
			label: 'Amount (USD)',
			key: 'amount',
			format: 'jsx',
			width: '10%',
		},
		{
			label: 'Type',
			key: 'type',
			format: 'jsx',
			width: '10%',
		},
		{
			label: 'Payment Method',
			key: 'card',
			format: 'jsx',
			width: '15%',
		},
		{
			label: 'Status',
			key: 'status',
			format: 'jsx',
			width: '10%',
		},
	],
};

export const HEADERS = {
	USERS: 'User List',
	SESSIONS: 'Session List',
	TRANSACTIONS: 'Analytics & Transactions',
	BILLINGS: 'Billing Details',
};

export const HEADER_ACTIVE = {
	USERS: 'usersList',
	SESSIONS: 'sessionList',
	TRANSACTIONS: 'transactions',
	BILLINGS: 'billing_details',
};

export const CUSTOM_HEADER_ACTIVE = {
	'User List': 'usersList',
	'Session List': 'sessionList',
	'Analytics & Transactions': 'transactions',
	'Billing Details': 'billing_details',
};

export const SUPER_ADMIN_VIEWLIST_MODAL_HEADER = [
	{
		KEY: 1,
		TITLE: HEADERS.USERS,
		ACTIVE: HEADER_ACTIVE.USERS,
		TAB: 1,
	},
	{
		KEY: 2,
		TITLE: HEADERS.SESSIONS,
		ACTIVE: HEADER_ACTIVE.SESSIONS,
		TAB: 2,
	},
	{
		KEY: 3,
		TITLE: HEADERS.TRANSACTIONS,
		ACTIVE: HEADER_ACTIVE.TRANSACTIONS,
		TAB: 3,
	},
	{
		KEY: 4,
		TITLE: HEADERS.BILLINGS,
		ACTIVE: HEADER_ACTIVE.BILLINGS,
		TAB: 4,
	},
];

export const ActivityActionName = [
	'kyc',
	'kyb',
	'aml',
	'506b',
	'506c',
	'payIn',
	'payOut',
	'signAgreement',
	'proofReading',
];

export const ChartTitleActivity: { [key: string]: string } = {
	kyc: 'KYC',
	kyb: 'KYB',
	aml: 'AML',
	'506b': 'Acc. 506b',
	'506c': 'Acc. 506c',
	payIn: 'Funds Pay In',
	payOut: 'Funds Pay Out',
	signAgreement: 'Sign Doc.',
	form: 'Form',
	proofReading: 'Proof Reading',
};

export const ChartXAxisActivity: { [key: string]: string } = {
	KYC: 'kyc',
	KYB: 'kyb',
	AML: 'aml',
	'Acc. 506b': '506b',
	'Acc. 506c': '506c',
	'Funds Pay In': 'payIn',
	'Funds Pay Out': 'payOut',
	'Sign Doc.': 'signAgreement',
	Form: 'form',
	'Proof Reading': 'proofReading',
};

export const TimeIntervalVerifications = [
	{
		key: 'kyc',
		label: 'KYC',
		time: '0',
		interval: {
			value: 'days',
			label: 'Days',
		},
	},
	{
		key: 'kyb',
		label: 'KYB',
		time: '0',
		interval: {
			value: 'days',
			label: 'Days',
		},
	},
	{
		key: 'authentication',
		label: 'Facial enrolment',
		time: '0',
		interval: {
			value: 'days',
			label: 'Days',
		},
	},
];

export const TimeIntervalOptions = [
	{
		value: 'days',
		label: 'Days',
	},
	{
		value: 'months',
		label: 'Months',
	},
	{
		value: 'years',
		label: 'Years',
	},
];

type IMessageType =
	| 'FILE_LIMIT_EXCEEDS_MESSAGE'
	| 'FIELD_ERROR_MESSAGE'
	| 'RECIPIENT_SIGN_MESSAGE'
	| 'EMAIL_UNIQUE_MESSAGE'
	| 'MAX_RECIPIENT_LIMIT_MESSAGE'
	| 'FILES_SUPPORTED_MESSAGE'
	| 'DOCUMENT_UPLOAD_FAIL_MESSAGE'
	| 'DOCUMENT_UPLOAD_MESSAGE'
	| 'OPTIONAL_FIELD_MESSAGE'
	| 'URL_LINK_MESSAGE'
	| 'SIGNATURE_UPLOAD_MESSAGE'
	| 'API_ERROR_MESSAGE'
	| 'SIGNATURE_NOT_UPLOAD_MESSAGE'
	| 'TEMPLATE_SAVED_MESSAGE'
	| 'ONE_FILE_REQUIRED'
	| 'UPLOAD_FAILED'
	| 'FILE_UPLOADED'
	| 'DOCUMENT_UPLOAD_SUCESS_MESSAGE'
	| 'ONE_NEED_TO_SIGN_REQUIRED';
export const MESSAGE: { [key in Uppercase<IMessageType>]: string } = {
	FILE_LIMIT_EXCEEDS_MESSAGE: 'File size exceeds the maximum limit.',
	FILES_SUPPORTED_MESSAGE: 'Only pdf, doc and docx files are supported.',
	SIGNATURE_UPLOAD_MESSAGE: 'Signature or initial can not be more than 1 MB.',
	API_ERROR_MESSAGE: 'Something went wrong, please try again later!',
	SIGNATURE_NOT_UPLOAD_MESSAGE: 'Failed to remove Signature/Initial',
	URL_LINK_MESSAGE: 'Provide the valid link in the url',
	OPTIONAL_FIELD_MESSAGE: 'Please add at least one non-optional field',
	DOCUMENT_UPLOAD_MESSAGE: 'Please upload atleast one document',
	DOCUMENT_UPLOAD_FAIL_MESSAGE: 'Failed to upload the document',
	MAX_RECIPIENT_LIMIT_MESSAGE: 'Maximum 25 recipients is allowed',
	EMAIL_UNIQUE_MESSAGE: 'Emails should be unique',
	RECIPIENT_SIGN_MESSAGE:
		'There should be at least one recipient as type "Needs to sign"',
	FIELD_ERROR_MESSAGE: 'Fix all the error(s) in the fields',

	// SUCESS-MESSAGE
	TEMPLATE_SAVED_MESSAGE: 'Template saved successfully.',
	DOCUMENT_UPLOAD_SUCESS_MESSAGE: 'Document uploaded successfully',
	ONE_FILE_REQUIRED: 'Please upload at least one document',
	FILE_UPLOADED: 'Document uploaded successfully',
	UPLOAD_FAILED: 'Failed to upload the document',
	ONE_NEED_TO_SIGN_REQUIRED:
		'There should be at least one active recipient has type "Needs to sign"',
};

type ICommon =
	| 'FILE_TYPE'
	| 'FILE_SIZE_LIMIT'
	| 'FILES_SUPPORTED'
	| 'NO_RECIPIENTS_ADDED';
export const COMMON_CONST: { [key in Uppercase<ICommon>]: string } = {
	FILE_TYPE: 'application/pdf',
	FILE_SIZE_LIMIT: 'Maximum upload file size: 10 MB.',
	FILES_SUPPORTED: 'Supported file format: Pdf, Doc, Docx',
	NO_RECIPIENTS_ADDED: 'No recipients are added',
};

export const UploadDocHeader: IUploadDocHeader[] = [
	{
		label: 'Report Name',
		key: 'documentName',
		key2: 'name',
		format: 'string',
		width: '10%',
	},
	{
		label: 'File Size',
		key: 'size',
		key2: 'size',
		format: 'number',
		width: '10%',
	},

	{
		label: 'Action',
		key: 'actions',
		key2: 'delete',
		format: 'jsx',
		width: '10%',
	},
];

export const ApproveStatus = (value: string = '') => {
	const key = value?.toLowerCase() ?? '';
	const label = key.replace(/_/g, ' ');
	return { classes: key, label };
};
