import type { IInputChange } from 'types';

import { DragEvent, useCallback, useMemo } from 'react';

import { useSetRecoilState } from 'recoil';
import { useNotification } from 'hooks';

import { convertFileToBase64, documentTypeRegex, formatBase64 } from 'utils';

import {
	IsFileUploadState,
	IUploadDoc,
	UploadedEnvelopeDocsState,
} from 'views/businesses/states';
import { COMMON_CONST, MESSAGE } from 'views/businesses/constants';

const { FILES_SUPPORTED_MESSAGE, FILE_LIMIT_EXCEEDS_MESSAGE } = MESSAGE;

export const BrowseFile = () => {
	const setEnvelopeDocs = useSetRecoilState(UploadedEnvelopeDocsState);

	const { errorNotification } = useNotification();

	const setIsLoading = useSetRecoilState(IsFileUploadState);

	const { FILES_SUPPORTED, FILE_SIZE_LIMIT } = COMMON_CONST;

	const handleImportPdf = useCallback(
		async (e: IInputChange | File, type?: 'drag' | 'browse') => {
			const file: File =
				(type === 'drag'
					? (e as File)
					: (e as IInputChange).target.files?.[0]) ?? ({} as File);
			if (file && file.size > 10000000) {
				errorNotification(FILE_LIMIT_EXCEEDS_MESSAGE);
			} else if (file && !documentTypeRegex.test(file.type)) {
				errorNotification(FILES_SUPPORTED_MESSAGE);
			} else if (file) {
				const base64pdf = await convertFileToBase64(file);
				if (base64pdf && typeof base64pdf === 'string') {
					setIsLoading(true);
					setTimeout(() => {
						setIsLoading(false);
						const filePayload: IUploadDoc = {
							data: {
								originalname: file?.name || 'Document',
								size: file?.size ?? 0,
								base64File: formatBase64(base64pdf),
								extension: file.type,
							},
						};
						return setEnvelopeDocs(filePayload as any);
					}, 5000);
				}
			}
			if (type !== 'drag') {
				(e as IInputChange).target.value = '';
			}
		},
		[]
	);

	const handleDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);

	const handleDrop = useCallback(
		(e: DragEvent<HTMLDivElement>) => {
			e.preventDefault();
			e.stopPropagation();
			const files = Array.from(e.dataTransfer.files);
			handleImportPdf(files?.[0] as File, 'drag');
		},
		[handleImportPdf]
	);

	const renderLoadingScreen = useMemo((): JSX.Element => {
		return (
			<>
				<i className="ri-file-upload-line browse-file__logo" />
				<div className="browse-file__label-text">
					<span className="browse-file__light-text">
						Drag and drop files or{' '}
						<span className="browse-file__click-label">Browse file</span>
					</span>
				</div>
				<div className="browse-file__description">{FILES_SUPPORTED}</div>
				<div className="browse-file__description">{FILE_SIZE_LIMIT}</div>
			</>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div
			className="browse-file-sign-doc"
			onDragOver={handleDragOver}
			onDrop={handleDrop}
		>
			<div className="upload-documents__header">Upload Report</div>
			<label
				htmlFor="browse-file"
				className="browse-file__label-box browse-file__label-box-height"
			>
				{renderLoadingScreen}
				<input
					multiple={false}
					accept=".pdf, .doc, .docx"
					id="browse-file"
					type="file"
					title=""
					onChange={handleImportPdf}
					className={`browse-file__input`}
				/>
			</label>
		</div>
	);
};
