import { FC } from 'react';

// import { DatePicker } from '@storybook';
import { AnalyticsOverview } from '../analyticsOverview';
import { BusinessTransactionList } from '../businessTransactionList';
import { OnboardingFlowAnalytics } from '../onboardingFlowAnalytics';

import './analytics-transactions.scss';
import { OverheadTransaction } from '../overheadTransaction';

type currentBusinessId = {
	currentBusinessId: string;
};

export const AnalyticsTransactions: FC<currentBusinessId> = ({
	currentBusinessId,
}) => {
	return (
		<div className="analytics-transactions-container">
			{/* <div className="analytics-transactions-filter">
				<DatePicker onSubmit={''} />
			</div> */}
			<div className="revenue-details-overview-container">
				<OverheadTransaction id={currentBusinessId} />
			</div>
			<AnalyticsOverview id={currentBusinessId} />
			<BusinessTransactionList selectedId={currentBusinessId} />
			<OnboardingFlowAnalytics id={currentBusinessId} />
		</div>
	);
};
