export const message = {
	InvalidEmailMessage: 'Invalid Email.',
	SomethingWentWrongMessage: 'Something went wrong.',
	CreteNewAccountMessage: `Don't have an account? Create your account, it takes less than a minute.`,
	AlreadyAccountMessage: 'Already have an account?',
	ValidPasswordMessage:
		'Password need at least one uppercase, one lowercase, one number, one special character and minimum length is 8.',
	AlreadyHaveAccount: 'Already have an account?',
	DoNotHaveAccount: `Don't have an account?`,
	LoginHeaderMessage:
		'Enter your email address and password to access admin panel.',
	WelcomeBack: 'Welcome back!',
	CRED_SUCCESS: 'Credential added successfully!',
	DELETE_MESSAGE: 'Deleted successfully !',
	SATSCHEL: 'Satschel',
	DESCRIPTION_MESSAGE:
		'Deleting will permanently remove this file from the system.',
	BOLD_DESCRIPTION: 'This can’t be undone',
	TITLE_MESSAGE: 'Are you sure?',
	COPY_TOASTER_MESSAGE: 'copied !',
	UPDATE_SUCCESS_MESSAGE: 'Updated Successfully!',
	VALID_NAME_MESSAGE: 'Name should be at least 3 character',
	VALID_NAME_INPUT_MESSAGE:
		'Name should be contain at least 3 english character',
	ADD_MOBILE_NUMBER: 'Add at least one mobile number',
	VALID_ADDRESS_MESSAGE: 'Address should be greater than 3 character',
	INVALID_COMPANY_NAME: 'No company exists with this name',
	ROLE_SUCCESS: 'Role added successfully!',
	ROLE_INVITE_SUCCESS: 'User invited successfully!',
	ROLE_INVITE_DELETE: 'User deleted successfully!',
	ROLE_INVITE_BLOCKED: 'User Blocked successfully!',
	ROLE_INVITE_UNBLOCKED: 'User Unblocked successfully!',
};

export const signupFormMessage = {
	firstNameMessage: 'Please enter first name',
	lastNameMessage: 'Please enter last name',
	passwordMessage: 'Please enter password',
	conFirmPasswordMessage: 'Confirm your password',
	passwordNotMatchMessage: 'Password did not match',
	emailMessage: 'Please enter email',
	validEmailMessage: 'Please enter valid email',
	phoneMessage: 'Please enter phone number',
	errorMessageOnlyNumber: 'Must be only digits',
	errorMessageStringGreaterThan8: 'Must be greater than 8 digit',
	errorMessageStringLessThan14: 'Must be less than 14 digits',
};

export const MintCartaMessage = {
	ValidPhoneNumberMessage:
		'Mobile number length should be between 8 to 12 digits',
	SubmitResponseMessage: 'Thank you for the details. Its under process',
	EmptyValidCompanyNameMessage: 'Enter valid company name',
	EmptyValidFirstNameMessage: 'Enter valid first name',
	EmptyValidLastNameMessage: 'Enter valid last name',
	EmptyValidAddressMessage: 'Enter valid address',
	EmptyValidEmailMessage: 'Enter valid email',
	EmptyValidPhoneMessage: 'Enter valid phone number',
	EmptyValidLinkedinMessage: 'Enter valid linkedin profile',
	EmptyValidTickerMessage: 'Enter valid ticker',
	EmptyValidOptionVestedMessage: 'Enter valid Option vested',
};
export const MintCartaPlaceHolderMessage = {
	CompanyNameMessage: 'Enter company name',
	FirstNameMessage: 'Enter first name',
	LastNameMessage: 'Enter last name',
	AddressMessage: 'Enter address',
	EmailMessage: 'Enter email',
	PhoneMessage: 'Enter phone number',
	LinkedinMessage: 'Enter linkedin profile',
	TickerMessage: 'Enter ticker',
	OptionVestedMessage: 'Enter option vested',
};

export const InviteEmailMessage = {
    InviteVerified: "Your verification was successful. You will be redirected to the dashboard shortly.",
    InviteAlreadyVerified: "Your invite has already been verified. Redirecting you to the dashboard now.",
    InviteError: "There was an issue processing your invite. Please try again.",
    InviteCodeMissing: "Verification code is missing. Please check the link and try again."
};
