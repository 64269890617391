import type { IDateRange } from "./type";

import { atom } from "recoil";
import { defineds } from "@storybook/date-range-picker/defineds";
import { createdAt } from "constant";
const { tillDate } = defineds;


export const DatePickerVisibleState = atom<boolean>({
  key: "date-picker-visible-state",
  default: false,
});

export const SelectedDateRangeState = atom<IDateRange[]>({
  key: "selected-date-range",
  default: [
		{
			
      startDate: new Date(createdAt),
				endDate: tillDate,
			key: 'selection',
		},
	], 
});
