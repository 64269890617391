import {
	ChangeEvent,
	FC,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useRecoilValue } from 'recoil';
import classNames from 'classnames';
import { ReactDropdown, ReactResponsiveTable } from '@storybook';

import { userToken } from 'states';
import { getDate, getTime } from 'utils';
import { useNetwork, useNotification } from 'hooks';
import { FilterSessionTypeTableState } from 'states';
import { API_URL, SessionFilterOptions } from 'constant';
import {
	ComplexStepsView,
	PERMISSION_SCREEN,
	SessionTable,
	SessionTreeGraph,
	SessionTypesQuery,
	lableData,
	useDashboard,
} from 'views';
import {
	IOptionsRow,
	ISessionList,
	ISessionType,
	IUserList,
	IUserRows,
} from './types';
import { SessionDetailsModal } from '../sessionDeatilsModal';

import './business-session-list.scss';

export const BusinessSessionList: FC<IUserList> = ({ data }) => {
	const {
		get: getSessionList,
		data: sessionData,
		isLoaded: userListLoaded,
		loading: userListLoading,
	} = useNetwork();

	const {
		get: getComplexSessionList,
		data: complexSessionData,
		loading: complexListLoading,
	} = useNetwork();

	const {
		post: postSessionDownloading,
		data: sessionDownloadingData,
		loading: isDownloadPending,
		error: sessionDownloadingError,
	} = useNetwork();

	const accessToken = useRecoilValue(userToken);
	const [sessionList, setSessionList] = useState([]);
	const [complexSessionList, setComplexSessionList] = useState([]);
	const [sessionListRows, setSessionListRows] = useState([]);
	const sessionTypeState = useRecoilValue(FilterSessionTypeTableState);
	const [modalIsopen, setModalIsOpen] = useState(false);
	const [isComplexFlowModal, setIsComplexFlowModal] = useState(false);
	const [sessionFilter, setSessionFilter] = useState(
		SessionFilterOptions[0].value
	);
	const [sessionId, setSessionId] = useState(null);
	const [complexStepView, setComplexStepView] = useState({});
	const { errorNotification } = useNotification();
	const { checkUserWritePermission } = useDashboard();

	const selectedId = useMemo(() => data[0]?._id, [data]);

	useEffect(() => {
		setSessionList([]);
		setSessionListRows([]);
		getSessionList(
			`${API_URL.SESSION_LIST}${selectedId}&isDeleted=${sessionFilter}`
		);
		getComplexSessionList(
			`${API_URL.COMPLEX_SESSION_LIST}${selectedId}&isDeleted=${sessionFilter}`
		);
	}, [sessionFilter]);

	useEffect(() => {
		if (sessionData?.data?.length) {
			setSessionList(sessionData?.data);
		}
		if (complexSessionData?.data?.length) {
			setComplexSessionList(complexSessionData?.data);
		}
	}, [sessionData, complexSessionData]);

	const isUserPermissionWrite = useMemo(
		() =>
			checkUserWritePermission(
				true,
				PERMISSION_SCREEN.Businesses,
				PERMISSION_SCREEN.Session_List
			),
		[checkUserWritePermission]
	);

	const combinedSession = useMemo(() => {
		const linearSession = sessionList.map((item) => ({
			...item,
			type: 'linear',
		}));

		const complexSession = complexSessionList.map((item) => ({
			...item,
			type: 'complex',
		}));

		const combined = linearSession.concat(complexSession);

		const finalSession = combined.sort((a, b) => {
			return new Date(b.date || 0).getTime() - new Date(a.date || 0).getTime();
		});

		if (!complexListLoading && !userListLoading) {
			return finalSession;
		} else {
			return [];
		}
	}, [sessionList, complexSessionList, userListLoading, complexListLoading]);

	const openSesionDetailsModal = (session: ISessionList) => {
		setSessionId(session?._id);
		setModalIsOpen(true);
		setComplexStepView(session);
	};

	const viewComplexStep = useCallback(
		(e: ChangeEvent<HTMLInputElement>, item: ISessionList) => {
			e.stopPropagation();
			setIsComplexFlowModal(true);
			setComplexStepView(item);
		},
		[]
	);

	const complexStepCloseModal = useCallback(() => {
		setComplexStepView(null);
		setIsComplexFlowModal(false);
	}, []);

	const StatusJsx = useCallback(
		(fullStatus: { [key: string]: string }) => (
			<>
				{fullStatus &&
					Object.keys(fullStatus).map((key, index) => {
						return (
							<div
								className={`status__clip statusbg__${fullStatus[
									key
								]?.toLocaleLowerCase()} statustct__${key}`}
								key={`session-list-${index}`} //eslint-disable-line
							>
								<svg
									className="leftImg"
									width="6"
									viewBox="0 0 6 20"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="M0 0H6V9.16667V20H0L6 9.16667L0 0Z" />
								</svg>

								<div className="inner__status">{lableData[key]}</div>
								<svg
									width="6"
									viewBox="0 0 6 20"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="M0 0L6 9.16667L0 20V0Z" />
								</svg>
							</div>
						);
					})}
			</>
		),
		[]
	);

	useEffect(() => {
		if (combinedSession && SessionTable.HEADER.length) {
			const rows: IOptionsRow[] = [];
			combinedSession.forEach((session, index) => {
				if (session) {
					let row: IUserRows = {
						address: undefined,
						approveStatus: '',
						companyName: '',
						createdAt: '',
						isLock: false,
						linkedInUrl: '',
						logo: '',
						status: '',
						users: undefined,
						_id: '',
						sendEmail: false,
						fundName: '',
						sessionEmail: '',
						sessionMobile: '',
						action: function (): void {
							throw new Error('Function not implemented.');
						},
						name: '',
						type: '',
					};
					SessionTable.HEADER.forEach(({ format, key, key2 }) => {
						row._id = session._id;
						row.createdAt = session?.date;
						row.sendEmail = session.email;
						row.fundName = session.fundName !== '' ? session.fundName : '--';

						if (key === 'date') {
							const value = () => (
								<div className="row_content_wrapper">
									<div className="row_content_wrapper__title">
										{getDate(session[key2])}
									</div>
									<div className="row_content_wrapper__sub_title">
										{getTime(session[key2])}
									</div>
								</div>
							);
							return (row[key] = value);
						}

						if (format === 'jsx' && key === 'fullStatus') {
							if (session.type === 'linear') {
								const value = () => (
									<>
										<div
											className={
												sessionTypeState.value ===
												SessionTypesQuery.Archived_Sessions
													? 'disable-in-archived session-status'
													: 'session-status'
											}
											key={index.toLocaleString()}
										>
											{StatusJsx(session[key])}
										</div>
										{key2 ? <div className="subTd">{session[key2]}</div> : ''}
									</>
								);
								return (row[key] = value);
							} else {
								const value = () => (
									<SessionTreeGraph
										details={session}
										showOnBoardingName
										isSessionList
										viewComplexModal={(e: ChangeEvent<HTMLInputElement>) =>
											viewComplexStep(e, session)
										}
									/>
								);
								return (row[key] = value);
							}
						}

						if (format === 'jsx' && key === 'action') {
							const value = () => (
								<div
									key={index} //eslint-disable-line
									className="view-session-btn--container"
								>
									<div className="review">
										<span
											onClick={() => openSesionDetailsModal(session)}
											className="view-session-btn"
										>
											View Sessions
										</span>
									</div>
								</div>
							);
							return (row[key] = value);
						}

						if (key === 'date') {
							const value = () => <>{session?.date}</>;
							return (row[key] = value);
						}

						if (format === 'jsx') {
							const value = () => (
								<>
									<div className="text-ellipsis text-lower-case">
										{session[key]}
									</div>
									{key2 ? <div className="subTd">{session[key2]}</div> : ''}
								</>
							);

							row = {
								...row,
								[key]: value,
								sessionEmail: session[key] ?? '--',
								sessionMobile: session[key2 as string] ?? '--',
							};
							return (row[key] = value);
						}

						row = { ...row, [key]: session[key] ?? '--' };
						return null;
					});

					row = {
						...row,
						...session?.fullStatus,
						onboardingFlowName: session?.onBoardingName,
					};
					rows.push(row);
				}
			});

			return setSessionListRows(rows);
		}
	}, [combinedSession]);

	const renderBusinessTableModal = useMemo(() => {
		return (
			<ReactResponsiveTable
				isLoading={userListLoading || complexListLoading}
				numberOfList={6}
				column={SessionTable.HEADER}
				rows={sessionListRows}
				height="calc(90vh - 252px)"
			/>
		);
	}, [sessionListRows, userListLoaded, userListLoading]);

	const handleCloseViewModal = () => {
		setModalIsOpen(false);
		setSessionId(null);
		setComplexStepView(null);
	};

	const handleSessionDownload = async () => {
		const payload = {
			sessionIds: [sessionId],
			fileType: 'pdf',
			businessId: selectedId,
		};

		await postSessionDownloading(API_URL.SESSION_DOWNLOAD, payload);
	};

	useEffect(() => {
		if (sessionDownloadingError) {
			errorNotification('There are some trouble while downloading the session');
		}

		if (sessionDownloadingData?.data) {
			const { url } = sessionDownloadingData.data;
			window.location.href = url[0];
		}
	}, [sessionDownloadingData, sessionDownloadingError]);

	const handleChange = (selectedOption: ISessionType) => {
		setSessionFilter(selectedOption.value);
	};

	const sessionClass = useMemo(() => {
		return classNames('session-user-modal web-component-xicon', {
			'session-user-modal__onlyRead': !isUserPermissionWrite,
		});
	}, [isUserPermissionWrite]);

	return (
		<>
			<div className="business-session-list--container">
				<ReactDropdown
					options={SessionFilterOptions}
					defaultValue={SessionFilterOptions[0]}
					handleChangeSelect={handleChange}
				/>
				{renderBusinessTableModal}
			</div>
			{modalIsopen && (
				<SessionDetailsModal
					isOpen={modalIsopen}
					handleCloseViewModal={handleCloseViewModal}
					className={sessionClass}
					modalName={'Business Modal'}
					handleDownloadClick={handleSessionDownload}
					sessionId={sessionId}
					accessToken={accessToken}
					isDownloadPending={isDownloadPending}
					sessionItemSelected={complexStepView}
				/>
			)}

			{isComplexFlowModal && (
				<ComplexStepsView
					isOpen={isComplexFlowModal}
					closeModal={complexStepCloseModal}
					details={complexStepView}
				/>
			)}
		</>
	);
};
