
const setCookie = (name, value, expirationInMinutes) => {
	const jsonValue = JSON.stringify(value); // Stringify the JSON object
	const date = new Date();
	date.setTime(date.getTime() + expirationInMinutes * 60 * 1000);
	const expires = 'expires=' + date.toUTCString();
	document.cookie = name + '=' + jsonValue + ';' + expires + ';path=/'; // Set the JSON string as the cookie value
};

const getCookie = (name) => {
	const cookieName = name + '=';
	const decodedCookie = decodeURIComponent(document.cookie);
	const cookieArray = decodedCookie.split(';');
	for (let i = 0; i < cookieArray.length; i++) {
		let cookie = cookieArray[i];
		while (cookie.charAt(0) === ' ') {
			cookie = cookie.substring(1);
		}
		if (cookie.indexOf(cookieName) === 0) {
			const cookieValue = cookie.substring(cookieName.length, cookie.length);
			return JSON.parse(cookieValue); // Parse the cookie value back to a JSON object
		}
	}
	return null; // Return null if cookie is not found or JSON parsing fails
};

export {
    setCookie,
    getCookie
}