import { FC, useEffect } from 'react';
import Modal from '@storybook/new-modal/modal';

import { useApprovalModal, useSessionApproval } from './store';
import { ICompanyList, ICompanySelected } from 'views/businesses/types';

import './approvalModal.scss';

interface IApprovalModal {
	SuperAdminData: ICompanyList[];
	setsuperAdminData: (value: ICompanyList[]) => void;
	selectedItem: null | ICompanySelected;
}

export const ApprovalModal: FC<IApprovalModal> = ({
	SuperAdminData,
	setsuperAdminData,
	selectedItem,
}) => {
	// globle states
	const { isOpenModal, modalTitle, modalBody, handleCloseModal } =
		useApprovalModal({
			SuperAdminData,
			setsuperAdminData,
			selectedItem,
		});

	const { fetchSession } = useSessionApproval();

	const { _id } = selectedItem ?? {};

	useEffect(() => {
		if (_id) {
			fetchSession(_id);
		}
	}, [_id]);

	return (
		<div className="ApprovalModal">
			<Modal
				isOpen={isOpenModal}
				closeModal={handleCloseModal}
				className="client-details-modal approve-modal"
				modalName="client details modal"
				title={modalTitle}
			>
				{modalBody}
			</Modal>
		</div>
	);
};
