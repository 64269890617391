import { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { useNetwork, useDebounce } from 'hooks';
import { API_URL } from 'constant';
import { SuperAdminDetails } from '../states';
import { IDateRange } from 'states';
import { getUtcMilliSecond } from 'utils';

export const useFetchBusinessList = (
	pageNumber?: number,
	searchTerm?: string,
	setPageNumber?: (arg: number) => void,
	searchType?: string,
	dateRange?: null | IDateRange[],
	filterStatus?: null | string[]
) => {
	const [searchBusiness, setSearchBusiness] = useState('');
	const [SuperAdminData, setsuperAdminData] = useRecoilState(SuperAdminDetails);
	const [totalPages, setTotalPages] = useState(null);

	const {
		get: getSuperAdmin,
		data: fetchData,
		error: SuperAdminError,
		status: SuperAdminStatus,
		isLoaded: SuperAdminLoaded,
		loading: SuperAdminLoading,
	} = useNetwork();

	const pageLimit = 20;

	const getBusiness = async () => {
		let date = ''
		if(dateRange && dateRange.length > 0){
			const startDate = getUtcMilliSecond(dateRange[0].startDate, 'start')
			const endDate = getUtcMilliSecond(dateRange[0].endDate, 'end')
			date += `&startDate=${new Date(startDate).toISOString()}&endDate=${new Date(endDate).toISOString()}`
		}
		if(filterStatus){
			filterStatus.map(i => {
				date += `&approveStatus=${i}`
			})
		}
		await getSuperAdmin(
			`${API_URL.BUSINESSES}?type=all&page=${pageNumber}&limit=${pageLimit}&sortingOrder=desc&isDeleted=false&searchTerm=${searchBusiness}&businessType=${searchType}${date}`
		);
	};

	const debounceValue = useDebounce(searchTerm.trim(), 300);

	useEffect(() => {
		setSearchBusiness(debounceValue);
		setPageNumber(1);
		setsuperAdminData([]);
	}, [debounceValue]);

	useEffect(() => {
		getBusiness();
	}, [pageNumber, searchBusiness, dateRange, filterStatus?.length]);

	useEffect(() => {
		if (fetchData) {
			setsuperAdminData(fetchData?.data?.[0]?.data);
			const totalPageCount =
				fetchData?.data?.[0]?.totalPages === 0
					? fetchData?.data?.[0]?.totalPages + 1
					: fetchData?.data?.[0]?.totalPages;
			setTotalPages(totalPageCount);
		}
	}, [fetchData]);

	return {
		SuperAdminData,
		setsuperAdminData,
		SuperAdminError,
		SuperAdminLoaded,
		SuperAdminStatus,
		SuperAdminLoading,
		totalPages,
	};
};
