import Modal from '@storybook/new-modal/modal';
import { SessionTreeGraph } from '../network-chart';

import "./complex-step-view.scss"

type Props = {
	isOpen: boolean;
	closeModal: () => void;
	details: any;
};

export const ComplexStepsView = ({
	closeModal,
	isOpen,
	details,
}: Props) => {
	return (
		<Modal
			isOpen={isOpen}
			modalName="Conditional Onboarding Flow Details"
			closeModal={closeModal}
			className="conditional-onboarding-details"
			showCloseBtn={true}
			isStopOutsideClick
			title={
				<div className="conditional-onboarding-details__title-wrapper">
					<div className="conditional-onboarding-details__title">
						Onboarding flow overview
					</div>
					<div className="conditional-onboarding-details__sub-title">
						Here’s a quick overview of all the steps and their current statuses
						in this onboarding flow.
					</div>
				</div>
			}
		>
			<div id="complex-onboarding-main" className="conditional-flow-steps">
				<SessionTreeGraph details={details} isSessionModal />
			</div>
		</Modal>
	);
};
