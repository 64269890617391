import { useCallback, useState } from 'react';
import { useNetwork, useNotification } from 'hooks';

import { API_URL } from 'constant';
import { useSetRecoilState } from 'recoil';
import { SuperAdminDetails } from '../states';
export const useLockUnlock = () => {
	const [lockSelectedItem, setLockSelectedItem] = useState(null);
	const { successNotification, errorNotification } = useNotification();
	const {
		patch: actionBusinessListHandleLock,
		data: lockunlockSuccessData,
		loading: isLockLoading,
		status: isLockUnlockStatus,
	} = useNetwork();
	const setsuperAdminData = useSetRecoilState(SuperAdminDetails);

	const handleOnClickLockUnlock = useCallback(async (event, item, value?) => {
		event.stopPropagation?.();
		setLockSelectedItem(null);
		setLockSelectedItem({ ...item });

		const payloadPhysicalVerification = {
			verificationStatus:
				item.physicalVerificationStatus === null ? 'null' : 'Request processed',
		};

		const payload = {
			isLock: item.isLock ? false : true,
		};

		if (item && item?._id) {
			const response = await actionBusinessListHandleLock(
				`${API_URL.BUSINESSES}/${item?._id}`,
				value === 'dropdown' ? payloadPhysicalVerification : payload
			);

			if (
				response?.physicalVerificationStatus === 'Request processed' &&
				value === 'dropdown'
			) {
				successNotification('Request processed');
				setsuperAdminData((prev) => {
					const clonedAdminData = structuredClone(prev);
					const index = clonedAdminData.findIndex(
						(el: any) => el?._id === item?._id
					);
					if (index !== -1) {
						clonedAdminData[index].physicalVerificationStatus =
							response?.physicalVerificationStatus;
					}
					return clonedAdminData;
				});
			} else if (response?.isLock !== undefined) {
				const successMessage = `${response?.name ?? 'Company'} is ${response?.isLock ? 'locked' : 'unlocked'} successfully!`;
				successNotification(successMessage);
			} else {
				errorNotification('We are getting some error');
			}
		}
	}, []);

	return {
		handleOnClickLockUnlock,
		lockunlockSuccessData,
		lockSelectedItem,
		isLockLoading,
		isLockUnlockStatus,
	};
};
