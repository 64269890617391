import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { API_URL, message } from 'constant';
import { useNetwork, useNotification } from 'hooks';
import {
	ApprovalStatusState,
	IsLoginEmailVerifiedState,
	LoginCurrentStepState,
	LoginMaskPhoneCredState,
	OptionsResponseState,
	VerificationIDState,
	isConfirmationModalOpen,
	isPhoneVerifiedWebAuthRegister,
	isWebAuthnRegisterLoader,
	isWebAuthnSupported,
	phoneVerifiedWebAuthRegisterToken,
} from '../states';
import { useLogin } from './use-login';
import { useWebAuthn } from './web-authn';

export const useWebLogin = () => {
	const setLoginCurrentStep = useSetRecoilState(LoginCurrentStepState);
	const setConfirmationOpen = useSetRecoilState(isConfirmationModalOpen);
	const setNotSupportedOpen = useSetRecoilState(isWebAuthnSupported);
	const setIsWebLoaded = useSetRecoilState(isWebAuthnRegisterLoader);
	const setIsLoginEmailVerified = useSetRecoilState(IsLoginEmailVerifiedState);
	const isPhoneVerifiedWebAuth = useRecoilValue(isPhoneVerifiedWebAuthRegister);
	const verficationStatus = useRecoilValue(ApprovalStatusState);
	const LoginMaskPhoneCred = useRecoilValue(LoginMaskPhoneCredState);
	const { phone, countryCode } = LoginMaskPhoneCred ?? {};
	const phoneWebAuthRegisterToken = useRecoilValue(
		phoneVerifiedWebAuthRegisterToken
	);

	const [optionsResponse, setOptionsResponse] =
		useRecoilState(OptionsResponseState);
	const [verificationId, setVerificationId] =
		useRecoilState(VerificationIDState);
	const [isBiometricLoading, setIsBiometricLoading] = useState(false);
	const [isWebAuthnCompleted, setIsWebAuthnCompleted] = useState(false);
	const [isLoaded, setIsLoaded] = useState(true);
	const [isOptionsLoaded, setIsOptionsLoaded] = useState(true);

	const { successNotification, errorNotification } = useNotification();
	const { handleOnSuccess, handleMobileApproval } = useLogin();
	const { authenticate: authentication } = useWebAuthn();
	const { post } = useNetwork();

	const verifyRegistrationWithToken = useCallback(
		async (payload: any, accessToken?: string) => {
			const configHeader = { Authorization: `Bearer ${accessToken}` };
			const response = await post(
				API_URL.CLIENT_WEBAUTHN,
				payload,
				configHeader
			);
			const { isRegistered, token } = response?.data ?? {};
			if (isRegistered && token) {
				successNotification('Web authentication is registered successfully');
				setIsWebAuthnCompleted(true);
				handleOnSuccess(token);
				return;
			}
			setIsWebAuthnCompleted(false);
			errorNotification('Web authentication registration failed.');
		},
		[post]
	);

	const registrationOptions = useCallback(
		async (verifiedEmailId?: string) => {
			setIsOptionsLoaded(false);
			let optionPayload: any = {
				type: 'registrationOpts',
				verificationId: verifiedEmailId ?? verificationId,
			};
			if (!verifiedEmailId && !verificationId) {
				optionPayload = {
					type: 'registrationOptsUID',
				};
			}
			const response = await post(API_URL.CLIENT_WEBAUTHN, optionPayload);

			const { id, registrationOptions } = response?.data ?? {};
			if (id && registrationOptions) {
				setOptionsResponse(response.data);
			} else {
				errorNotification(
					response?.message ?? message.SomethingWentWrongMessage
				);
			}
			setIsOptionsLoaded(true);
		},
		[errorNotification, post, setOptionsResponse, verificationId]
	);

	useEffect(() => {
		if (verficationStatus) {
			if (verficationStatus === 'approved') {
				registrationOptions();
			}
			if (verficationStatus === 'rejected') {
				errorNotification('Approval Rejected.');
			}
		}
	}, [verficationStatus]);

	const startRegisterDevice = useCallback(async () => {
		if (verficationStatus === 'approved') {
			registrationOptions();
		} else {
			const approvalPayload = {
				type: 'registrationApproval',
				phone,
				countryCode,
				deviceInfo: navigator.userAgent ?? {},
			};

			setIsLoaded(false);
			const response = await post(API_URL.CLIENT_WEBAUTHN, approvalPayload);

			if (response?.data) {
				const { message, success, verificationId } = response.data ?? {};
				setVerificationId(verificationId);
				if (success && verificationId) {
					setLoginCurrentStep('BIOMETRIC_APPROVAL');
					successNotification(message);
					return;
				}
			} else {
				errorNotification(response?.message);
			}
		}
	}, [
		countryCode,
		errorNotification,
		phone,
		registrationOptions,
		successNotification,
		verficationStatus,
	]);

	const handleAuthenticationSuccess = useCallback(
		async (res: any) => {
			if (res) {
				setIsWebAuthnCompleted(true);
				const payload = {
					type: 'verifyAuthentication',
					authenticateOptions: res.authenticateOptions ?? {},
					id: res.id ?? '',
				};

				const resp = await post(API_URL.CLIENT_WEBAUTHN, payload);
				const { isAuthenticated, token } = resp?.data ?? {};

				if (token && isAuthenticated) {
					setIsWebAuthnCompleted(true);
					handleOnSuccess(token);
				} else {
					setIsWebAuthnCompleted(false);
					setConfirmationOpen(true);
				}
			}
		},
		[setConfirmationOpen]
	);

	const handleRegistrastionSuccess = useCallback(
		async (res: any) => {
			if (res) {
				setIsWebAuthnCompleted(true);
				let payloadSaveCredential = {};
				if (phoneWebAuthRegisterToken) {
					payloadSaveCredential = {
						type: 'verifyRegistrationUID',
						registrationOptResponse: res,
						id: optionsResponse?.id,
					};
					await verifyRegistrationWithToken(
						payloadSaveCredential,
						phoneWebAuthRegisterToken
					);
				} else {
					payloadSaveCredential = {
						type: 'verifyRegistration',
						registrationOptResponse: res,
						id: optionsResponse?.id,
						verificationId: verificationId,
					};

					const response = await post(
						API_URL.CLIENT_WEBAUTHN,
						payloadSaveCredential
					);

					const { isRegistered, token } = response?.data ?? {};

					if (isRegistered) {
						if (token) {
							setIsWebAuthnCompleted(true);
							handleOnSuccess(token);
							return;
						}
						setIsWebAuthnCompleted(false);

						const payloadForAuthenticateCredentials = {
							type: 'authenticateOpts',
							countryCode,
							phone,
						};

						const resAuthenticateCredentials = await post(
							API_URL.CLIENT_WEBAUTHN,
							payloadForAuthenticateCredentials
						);

						const { id } = resAuthenticateCredentials?.data ?? {};
						if (id) {
							authentication(
								handleAuthenticationSuccess,
								resAuthenticateCredentials?.data ?? {}
							);
						} else {
							errorNotification(resAuthenticateCredentials?.message);
						}
						return;
					}
					setIsWebAuthnCompleted(false);
				}
			}
		},
		[
			optionsResponse?.id,
			verificationId,
			successNotification,
			phone,
			countryCode,
			authentication,
			handleAuthenticationSuccess,
			errorNotification,
			phoneWebAuthRegisterToken,
			verifyRegistrationWithToken,
		]
	);

	const getWebAuthnSupported = async () => {
		return (
			window?.PublicKeyCredential !== undefined &&
			typeof window.PublicKeyCredential === 'function'
		);
	};

	const handleWebAuth = useCallback(async () => {
		if (isBiometricLoading) return;
		if (!isPhoneVerifiedWebAuth) {
			setIsLoginEmailVerified(true);
			handleMobileApproval(true);
			return;
		}
		if (await getWebAuthnSupported()) {
			setIsBiometricLoading(true);
			setIsWebLoaded(false);

			const payloadForAuthenticateCredentials = {
				type: 'authenticateOpts',
				countryCode,
				phone,
			};

			const resAuthenticateCredentials = await post(
				API_URL.CLIENT_WEBAUTHN,
				payloadForAuthenticateCredentials
			);

			const { id } = resAuthenticateCredentials?.data ?? {};
			if (id) {
				authentication(
					handleAuthenticationSuccess,
					resAuthenticateCredentials?.data ?? {}
				);
			} else {
				setConfirmationOpen(true);
			}
			setIsBiometricLoading(false);
		} else {
			setNotSupportedOpen(true);
		}
	}, [
		isBiometricLoading,
		phone,
		countryCode,
		authentication,
		handleAuthenticationSuccess,
		setConfirmationOpen,
		setNotSupportedOpen,
		handleMobileApproval,
		isPhoneVerifiedWebAuth,
	]);

	return {
		registrationOptions,
		isOptionsLoaded,
		isLoaded,
		handleWebAuth,
		handleRegistrastionSuccess,
		startRegisterDevice,
		isWebAuthnCompleted,
		isBiometricLoading,
	};
};
