import { isSameDay } from "date-fns";

import { defineds } from "./defineds";
import { Preview, Range } from "react-date-range";

export const SelectionLabel: { [key: string]: string } = {
  TODAY: 'Today',
	LAST_7_DAYS: 'Last 7 days',
	LAST_30_DAYS: 'Last 30 days',
	THIS_MONTH: 'This month',
	LAST_MONTH: 'Last month',
	LAST_90_DAYS: 'Last 90 days',
	LAST_QUARTER: 'Last quarter',
	THIS_YEAR: 'This year',
	LAST_YEAR: 'Last year',
	ALL: 'ALL',
  CUSTOM: "Custom",
};

const staticRangeHandler = {
  range: {},
  isSelected: ({ startDate, endDate }: Range) => {
    if (startDate && endDate) {
      return isSameDay(startDate, endDate);
    }
    return false;
  },
};

export const createStaticRanges = (
  ranges: { label: string; range: () => Preview;
    isSelected: (e: Range) => boolean;
   }[]
) => {
  return ranges.map((range) => {
    return { ...staticRangeHandler, ...range };
  });
};

const { TODAY,
	LAST_7_DAYS,
	LAST_30_DAYS,
	THIS_MONTH,
	LAST_MONTH,
	LAST_90_DAYS,
	LAST_QUARTER,
	THIS_YEAR,
	LAST_YEAR,
	ALL, } =
  SelectionLabel;

const {
  endOfLastMonth,
	endOfLast90Days,
	endOfLastQuarter,
	endOfLastWeek,
	endOfLastYear,
	endOfToday,
	startOfLastMonth,
	startOfLast90Days,
	startOfLastQuarter,
	startOfLastWeek,
	startOfLastYear,
	startOfToday,
	startOfYear,
	tillDate,
	startOfThisMonth,
	endOfThisMonth,
	startOfLast30days,
	endOfLast30days
} = defineds;

export const defaultStaticRanges = (createAt: Date|string = startOfToday) =>
	createStaticRanges([
		{
			label: ALL,
			range: () => ({
				startDate: new Date(createAt),
				endDate: tillDate,
			}),
			isSelected: ({ startDate, endDate }: Range) => {
				if (startDate && endDate) {
					return startDate === startDate && endDate === tillDate;
				}
				return false;
			},
		},

		{
			label: TODAY,
			range: () => ({
				startDate: startOfToday,
				endDate: endOfToday,
			}),
			isSelected: ({ startDate, endDate }: Range) => {
				if (startDate && endDate) {
					return isSameDay(startDate, endDate);
				}
				return false;
			},
		},
		{
			label: LAST_7_DAYS,
			range: () => ({
				startDate: startOfLastWeek,
				endDate: endOfLastWeek,
			}),
			isSelected: ({ startDate, endDate }: Range) => {
				if (startDate && endDate) {
					return startDate === startOfLastWeek && endDate === endOfLastWeek;
				}
				return false;
			},
		},
		{
			label: LAST_30_DAYS,
			range: () => ({
				startDate: startOfLast30days,
				endDate: endOfLast30days,
			}),
			isSelected: ({ startDate, endDate }: Range) => {
				if (startDate && endDate) {
					return startDate === startOfLast30days && endDate === endOfLast30days;
				}
				return false;
			},
		},
		{
			label: THIS_MONTH,
			range: () => ({
				startDate: startOfThisMonth,
				endDate: endOfThisMonth,
			}),
			isSelected: ({ startDate, endDate }: Range) => {
				if (startDate && endDate) {
					return startDate === startOfThisMonth && endDate === endOfThisMonth;
				}
				return false;
			},
		},
		{
			label: LAST_MONTH,
			range: () => ({
				startDate: startOfLastMonth,
				endDate: endOfLastMonth,
			}),
			isSelected: ({ startDate, endDate }: Range) => {
				if (startDate && endDate) {
					return startDate === startOfLastMonth && endDate === endOfLastMonth;
				}
				return false;
			},
		},
		{
			label: LAST_90_DAYS,
			range: () => ({
				startDate: startOfLast90Days,
				endDate: endOfLast90Days,
			}),
			isSelected: ({ startDate, endDate }: Range) => {
				if (startDate && endDate) {
					return startDate === startOfLast90Days && endDate === endOfLast90Days;
				}
				return false;
			},
		},
		{
			label: LAST_QUARTER,
			range: () => ({
				startDate: startOfLastQuarter,
				endDate: endOfLastQuarter,
			}),
			isSelected: ({ startDate, endDate }: Range) => {
				if (startDate && endDate) {
					return startDate === startOfLastQuarter && endDate === endOfLastQuarter;
				}
				return false;
			},
		},
		{
			label: LAST_YEAR,
			range: () => ({
				startDate: startOfLastYear,
				endDate: endOfLastYear,
			}),
			isSelected: ({ startDate, endDate }: Range) => {
				if (startDate && endDate) {
					return startDate === startOfLastYear && endDate === endOfLastYear;
				}
				return false;
			},
		},
		{
			label: THIS_YEAR,
			range: () => ({
				startDate: startOfYear,
				endDate: tillDate,
			}),
			isSelected: ({ startDate, endDate }: Range) => {
				if (startDate && endDate) {
					return startDate === startOfYear && endDate === tillDate;
				}
				return false;
			},
		},
	]);
