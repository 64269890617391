// packages import
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { Image } from '@storybook';

// local components import
import { SIDEBAR_ACCOUNT_TABS, SIDEBAR_TABS } from 'routes';
import { useNetwork, useCookie } from 'hooks';
import { userToken } from 'states';
import { LoginCurrentStepState, useDashboard } from 'views';

// style sheets
import './sidebar.scss';

export const Sidebar = () => {
	// hooks
	const navigate = useNavigate();
	//const [isOpen, setIsOpen] = useState(true);
	const [isOpen] = useState(true);
	const { pathname } = useLocation();
	const { post: logoutApi } = useNetwork();
	const { deleteCookie } = useCookie();
	const setUserToken = useSetRecoilState(userToken);
	const resetLoginCurrentStep = useResetRecoilState(LoginCurrentStepState);

	const { customUserTab } = useDashboard();

	// this will manage the logout
	const onLogout = useCallback(() => {
		// logoutApi(API_URL.LOGOUT, {});
		deleteCookie('user');
		localStorage.clear();
		setUserToken('');
		window.location.href = '/signin';
		resetLoginCurrentStep();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deleteCookie, logoutApi]);

	// this will change the tabs
	const handleChangeTab = useCallback(
		(tabName: string) => {
			if (tabName === 'LOGOUT') {
				onLogout();
			}
			if (tabName !== 'LOGOUT') {
				navigate(`/${tabName}`);
			}
		},
		[navigate, onLogout]
	);

	const conditionalActiveTab = useCallback(
		(tabName: string) => {
			const isAllow = customUserTab.includes(tabName);

			return isAllow ?? false;
		},
		[customUserTab]
	);

	const mapProfileTabs = useMemo(
		() => (
			<>
				<div className="anything">
					{SIDEBAR_ACCOUNT_TABS.tabs.map(({ ICON, TAB_INDEX, TITLE }) => {
						return (
							<div
								className="sidebar__item"
								onClick={() => handleChangeTab('LOGOUT')}
								key={`${TAB_INDEX}-${TITLE}`}
							>
								<i className={`sidebar__icon ${ICON}`} />
								<div className="sidebar__text">{TITLE}</div>
							</div>
						);
					})}
				</div>
			</>
		),
		[handleChangeTab]
	);

	// this will manage the visibility of integrate tab ie: will visible only if the plan is paid
	// here tabs are rendered using map
	const mapRender = useMemo(
		() => (
			<div className="anything">
				{SIDEBAR_TABS.tabs.map(
					({ ICON, ROUTE, TAB_INDEX, TITLE }) =>
						conditionalActiveTab(TITLE) && (
							<div
								key={`${TAB_INDEX}-${TITLE}`}
								className={`sidebar__item ${
									(pathname === `/${ROUTE}` ||
										pathname.includes(`/${ROUTE}`)) &&
									'sidebar__item--active'
								}`}
								onClick={() => handleChangeTab(ROUTE)}
							>
								<i className={`sidebar__icon ${ICON}`} />
								<div className="sidebar__text">{TITLE}</div>
							</div>
						)
				)}
			</div>
		),
		[pathname, handleChangeTab, conditionalActiveTab]
	);

	const handleLogoClick = useCallback(
		(path: string) => {
			navigate(path);
		},
		[navigate]
	);

	// const handleOpen = useCallback(() => {
	// 	setIsOpen((pre) => !pre);
	// 	// eslint-disable-next-line
	// }, []);

	return (
		<div
			className={`sidebar-container ${
				isOpen ? 'open-sidebar' : 'sidebar-collapse'
			}`}
			style={{ width: isOpen ? '242px' : '72px' }}
		>
			<div
				onClick={() => handleLogoClick('/businesses')}
				className="sidebar-container__brandLogo"
			>
				<Image
					fileName="Onboarded.ai_Logo.svg"
					className="sidebar-container__brandLogo__img"
					url="https://storage.googleapis.com/satschel-public-assets/images/logo/simplici-wordmark.png"
				/>
			</div>
			<div className="sidebar-tabs-container">
				{/* <div className="sidebar-toggle-btn" onClick={handleOpen}>
					<i
						className={`${
							isOpen
								? 'ri-arrow-left-s-line left-accor'
								: 'ri-arrow-right-s-line right-accor'
						}`}
					/>
				</div> */}
				<div className="sidebar">{mapRender}</div>
				<div className="sidebar">{mapProfileTabs}</div>
			</div>
		</div>
	);
};
