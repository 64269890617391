import { useState } from 'react';
import { ISelectedBusiness, SuperAdminDetails } from '../states';
import { useVerificationTimeInterval } from './useVerificationTimeInterval';
import { useRecoilValue } from 'recoil';
import { useNotification } from 'hooks';

export const useDropOption = () => {
	// hooks
	const { handleOpenTimeIntervalModal } = useVerificationTimeInterval();
	const [isOpen, setIsOpen] = useState(false);
	const { errorNotification } = useNotification();

	const superAdminDetailsValue = useRecoilValue(SuperAdminDetails);

	function getPhysicalVerificationStatusById(id, superAdminDetailsValue) {
		for (let i = 0; i < superAdminDetailsValue.length; i++) {
			if (superAdminDetailsValue[i]._id === id) {
				return superAdminDetailsValue[i].physicalVerificationStatus;
			}
		}
		return null;
	}

	const handleUpload = (item) => {
		if (
			getPhysicalVerificationStatusById(item?._id, superAdminDetailsValue) ===
			'Request processed'
		) {
			setIsOpen(true);
		} else {
			errorNotification('Status not Approved! please approve first!!!');
		}
	};

	const OptionList = (item: ISelectedBusiness, cb) => {
		const { verificationTimeInterval } = item ?? {};
		const { kyb, kyc, authentication } = verificationTimeInterval ?? {};
		const title =
			!kyb || !kyc || !authentication
				? 'Set verification time intervals'
				: 'Verification time intervals';

		const physicalVerificationStatus = getPhysicalVerificationStatusById(
			item?._id,
			superAdminDetailsValue
		);

		const options = [
			{
				title,
				handleOption: () => handleOpenTimeIntervalModal(item),
			},
		];

		if (physicalVerificationStatus === 'Request processed') {
			options.push({
				title: 'Upload physical verification report',
				handleOption: () => {
					handleUpload(item);
				},
			});
		} else {
			if (physicalVerificationStatus === 'PENDING') {
				options.push({
					title: 'Accept physical verification request',
					handleOption: () => {
						cb({}, item, 'dropdown');
					},
				});
			}
		}

		return options;
	};

	return { OptionList, isOpen, setIsOpen };
};
