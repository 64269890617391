import { useMemo, useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { AuditSkeleton, TableEmptyMessage } from '@storybook';
import { useNetwork, useNotification } from 'hooks';
import { API_URL } from 'constant';
import { Header } from 'views/header';
import { Logs } from './components';
import { ActivityLogState } from './states';

import './audit-log.scss';

export const AuditLog = () => {
	const [activityLog, setActivityLog] = useRecoilState(ActivityLogState);
	const {
		get: getAuditList,
		data: auditData,
		loading: isAuditLoading,
		error: auditLogError,
	} = useNetwork();
	const { errorNotification } = useNotification();

	useEffect(() => {
		getAuditList(API_URL.AUDIT_LOG);
	}, []);

	useEffect(() => {
		if (auditData?.data?.length) {
			setActivityLog(auditData?.data);
		}
	}, [auditData]);

	const renderLists = useMemo(() => {
		if (activityLog.length) {
			return activityLog?.map((items) => <Logs key={items._id} data={items} />);
		}
	}, [activityLog]);

	useEffect(() => {
		if (auditLogError) {
			errorNotification(
				auditLogError ?? `we are facing issues to find logs at the moment`
			);
		}
	}, [auditLogError]);

	return (
		<div className="audit-logs">
			<Header title="Activities" />
			{isAuditLoading && !activityLog?.length ? (
				<AuditSkeleton count={6} />
			) : !activityLog?.length ? (
				<TableEmptyMessage height="90%" />
			) : (
				<div className="audit-logs__lists">{renderLists}</div>
			)}
		</div>
	);
};
