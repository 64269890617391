import { useEffect, useState } from 'react';

export const useDebounce = (val: any, delay = 250) => {
	const [debounceValue, setDebounceValue] = useState(val);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setDebounceValue(val);
		}, delay);

		return () => {
			clearTimeout(timeout);
		};
	}, [val, delay]);

	return debounceValue;
};
