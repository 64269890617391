import { FC } from 'react';
import { Button, Image } from '@storybook';

import './no-user-assigned.scss';

interface INoUserAssined {
	isUserPermissionWrite: boolean;
	handleInviteUser: () => void;
}

export const NoUserAssined: FC<INoUserAssined> = ({
	handleInviteUser,
	isUserPermissionWrite,
}) => {
	return (
		<div className="NoUserAssined--container">
			<div className="NoUserAssined--container__wrapper">
				<div className="NoUserAssined--container__wrapper--img">
					<Image fileName="noUser.svg" />
				</div>
				<div className="NoUserAssined--container__wrapper--text">
					<div className="NoUserAssined--container__wrapper--text__heading">
						No user assigned
					</div>
					<div className="NoUserAssined--container__wrapper--text__subHeading">
						Assign roles to user to manage their permission
					</div>
				</div>

				<div className="NoUserAssined--container__wrapper--btn">
					{isUserPermissionWrite ? (
						<Button
							label="Invite user"
							type="button__filled--primary"
							handleClick={handleInviteUser}
							disabled={false}
						/>
					) : null}
				</div>
			</div>
		</div>
	);
};
