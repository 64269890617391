import { FC } from 'react';
import { UserRoleHeaderData } from 'views/role-permission/constants';
type IAddRoleModalHeader = {
	navigate: string;
};
export const AddRoleModalHeader: FC<IAddRoleModalHeader> = ({ navigate }) => {
	return (
		<div className="AddNewRoleModal--container__header">
			<div className="AddNewRoleModal--container__title">
				<section className="AddNewRoleModalheader">
					<div className="AddNewRoleModalheader--container">
						<h1 className="AddNewRoleModalheader--container__heading">
							{UserRoleHeaderData[navigate]?.head}
						</h1>
						<p className="AddNewRoleModalheader--container__info">
							{UserRoleHeaderData[navigate]?.desc}
						</p>
					</div>
				</section>
			</div>
		</div>
	);
};
