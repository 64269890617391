import { FC, useEffect, useRef } from 'react';

import { useTreeGraphChart } from '../../hooks';
import { ITreeGraphChart } from '../../types';

import './tree-graph-chart.scss';

export const TreeGraphChart: FC<ITreeGraphChart> = ({
	isLoading,
	title,
	isInverted,
	seriesData,
	seriesLabelName,
	nodesColor,
	isInvite,
	onClick,
	isSessionModal,
}) => {
	const oldSeriesData = useRef<[string][]>([]);
	const oldNodeColorData = useRef<{ id: string; color: string }[]>([]);

	const { getTreeGraphConfig } = useTreeGraphChart();

	useEffect(() => {
		const seriesDataUpdated =
			JSON.stringify(oldSeriesData.current) !== JSON.stringify(seriesData);

		const nodeColorUpdated =
			JSON.stringify(oldNodeColorData.current) !== JSON.stringify(nodesColor);

		if (seriesDataUpdated || nodeColorUpdated) {
			getTreeGraphConfig({
				isLoading,
				seriesData,
				title,
				isInverted,
				seriesLabelName,
				nodesColor,
				isInvite,
				onClick,
				isSessionModal,
			});
			oldSeriesData.current = seriesData;
			oldNodeColorData.current = nodesColor;
		}
	}, [seriesData, nodesColor, oldNodeColorData]);

	return (
		<div className="tree-graph-chart-wrapper">
			<div
				id={title}
				className={`${
					isInvite || isSessionModal ? 'onboarding-invite-tree-graph-div' : ''
				}`}
			/>
		</div>
	);
};
