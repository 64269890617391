import { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import {
	Header,
	PERMISSION_SCREEN,
	useDashboard,
	useRolepermission,
} from 'views';
import {
	AddNewRoleModal,
	ComplainceAgent,
	RolesAndPermission,
	UserRoleSkeletonLoader,
} from './components';
import {
	PermissionNavigation,
	PermissionTableDataState,
	TemplateRoleState,
	UserRolePermissionNavigation,
	UserRoleState,
} from './store';
import { UserRoleNavigation } from './constants';

import './role-permission.scss';

export const RolePermission = () => {
	const [isModalOpen, setModalOpen] = useState(false);
	const resetRoleNavigation = useResetRecoilState(PermissionNavigation);
	const [userRoleNavigate, setUserRoleNavigate] = useRecoilState(
		UserRolePermissionNavigation
	);
	const [roleLoading, setRoleLoading] = useState(false);

	const templateRole = useRecoilValue(TemplateRoleState);
	const permissionList = useRecoilValue(PermissionTableDataState);
	const userRoleList = useRecoilValue(UserRoleState);

	// Hooks
	const { fetchUserRoles, fetchTemplates, fetchPermissionList } =
		useRolepermission();
	const { checkUserWritePermission } = useDashboard();

	const initRole = async () => {
		setRoleLoading(true);
		setUserRoleNavigate(UserRoleNavigation.AddedRoleCard);
		if (!userRoleList?.length) await fetchUserRoles();
		if (!templateRole?.length) await fetchTemplates();
		if (!permissionList?.length) await fetchPermissionList();
		setRoleLoading(false);
	};

	useEffect(() => {
		initRole();
	}, []);

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(false, PERMISSION_SCREEN.Roles_Permissions),
		[checkUserWritePermission]
	);

	const handleClickBtn = () => {
		resetRoleNavigation();
		setModalOpen(true);
	};

	const handleCloseModel = () => {
		setModalOpen(false);
		setTimeout(() => {
			resetRoleNavigation();
		}, 1000);
	};

	// render user role permission details
	const renderUserRolePermission = useMemo(() => {
		switch (userRoleNavigate) {
			case UserRoleNavigation.AddedRoleCard:
				return (
					<RolesAndPermission isUserPermissionWrite={isUserPermissionWrite} />
				);
			case UserRoleNavigation.ComplainceAgent:
				return (
					<ComplainceAgent isUserPermissionWrite={isUserPermissionWrite} />
				);
			default:
				return (
					<RolesAndPermission isUserPermissionWrite={isUserPermissionWrite} />
				);
		}
	}, [userRoleNavigate]);

	// Loading UI
	if (roleLoading) {
		return (
			<div className="Role-Permission">
				<Header title="Roles and permissions" />
				<UserRoleSkeletonLoader />
			</div>
		);
	}

	return (
		<div className="Role-Permission">
			<Header
				title="Roles and permissions"
				showBtn={
					isUserPermissionWrite &&
					userRoleNavigate !== UserRoleNavigation.ComplainceAgent
				}
				btnLabel="Add new role"
				handleClickBtn={handleClickBtn}
			/>

			{renderUserRolePermission}

			<AddNewRoleModal
				handleCloseModel={handleCloseModel}
				isAddRoleModalOpen={isModalOpen}
			/>
		</div>
	);
};
