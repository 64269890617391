import { REACT_APP_API_HOST as API_HOST } from "envs";

const host = () => {
  return window.location.origin + "/";
};
export const envUrl = host();

const isStage = /stage/gi.test(API_HOST);
const isPP = /pp/gi.test(API_HOST);
const isProd = /api.satschel.com/gi.test(API_HOST);
const isBeta = /beta/gi.test(API_HOST);

const environmentHost = () => {
  if (isStage) return "stage";
  if (isPP) return "pp";
  if (isProd) return "prod";
  if (isBeta) return "beta";
};

export const envHost = environmentHost();
