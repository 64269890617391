import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';

import { Image, LatterAvater } from '@storybook';
import { getInitials } from 'utils';
import { ConfirmationModal } from 'components';
import { useNetwork, useNotification } from 'hooks';
import { API_URL, message } from 'constant';

import { NoUserAssined } from '../no-user-assined';
import { InviteUserForm } from '../invite-user-form';
import { DropInfoOptions } from '../info-drop-options';
import {
	InviteFormInput,
	SelectedUserRoleState,
	useRolepermission,
	UserRoleState,
} from 'views';

import './assined-user.scss';

interface IAssinedUser {
	isUserPermissionWrite: boolean;
}

export const AssinedUser: FC<IAssinedUser> = ({ isUserPermissionWrite }) => {
	const [isBlockedData, setBlockedData] = useState<{
		isDeleted: boolean;
		isBlocked: boolean;
	}>({
		isDeleted: false,
		isBlocked: false,
	});
	const [selectedId, setSelectedId] = useState('');
	const [selectedUserName, setSelectedUserName] = useState('');
	const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
	const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [selectedUserRole, setSelectedUserRole] = useRecoilState(
		SelectedUserRoleState
	);
	const [userRoleState, setUserRoleState] = useRecoilState(UserRoleState);

	const { inviteUser, inviteUserLoader } = useRolepermission();
	const { successNotification, errorNotification } = useNotification();
	const { patch: patchBlockedUser } = useNetwork();

	useEffect(() => {
		if (userRoleState && selectedUserRole) {
			const editIndex = userRoleState.findIndex(
				(item) => item._id === selectedUserRole._id
			);
			const role = (userRoleState ?? []).find(
				(el) => el._id === selectedUserRole._id
			);

			const newResp = {
				...role,
				users: selectedUserRole.users,
				count: selectedUserRole.count,
			};
			setUserRoleState((pre: any) =>
				Object.values({ ...pre, [editIndex]: newResp })
			);
		}
	}, [selectedUserRole]);

	const handleInviteAssignedUser = () => {
		setIsInviteModalOpen(true);
	};

	const onBlockedUser = async () => {
		setIsLoading(true);

		const resp = await patchBlockedUser(`${API_URL.USERS}/${selectedId}`, {
			action: !isBlockedData.isBlocked ? 'blocked' : 'unblock',
			roleId: selectedUserRole?._id,
		});

		if (resp) {
			setIsOpenDeleteModal(false);
			successNotification(
				!isBlockedData.isBlocked
					? message.ROLE_INVITE_BLOCKED
					: message.ROLE_INVITE_UNBLOCKED
			);

			const assignUser = selectedUserRole?.users?.map((el) => {
				if (el._id === selectedId) {
					return {
						...el,
						isRoleBlocked: !isBlockedData.isBlocked,
					};
				} else {
					return {
						...el,
					};
				}
			});

			setSelectedId('');
			setBlockedData((prev) => ({
				...prev,
				isBlocked: false,
				isDeleted: false,
			}));
			setSelectedUserRole((pre) => ({ ...pre, users: assignUser }));
		} else {
			errorNotification(resp?.message ?? message.SomethingWentWrongMessage);
		}
		setIsLoading(false);
	};

	const handleDeleteUserRole = async (isOpen: boolean, isClose: boolean) => {
		if (!isClose && !isOpen) {
			setIsOpenDeleteModal(false);
			setBlockedData((prev) => ({
				...prev,
				isBlocked: false,
				isDeleted: false,
			}));

			return;
		}

		if (!isBlockedData.isDeleted) {
			onBlockedUser();
			return;
		}

		setIsLoading(true);
		const resp = await patchBlockedUser(`${API_URL.USERS}/${selectedId}`, {
			action: 'revoke',
			roleId: selectedUserRole?._id,
		});
		if (resp) {
			setSelectedUserRole((prev) => {
				const prevObj = structuredClone(prev);
				const prevObjUser = structuredClone(prevObj?.users);
				const index = prevObjUser?.findIndex((el) => el._id === selectedId);
				if (index !== -1) {
					prevObjUser?.splice(index, 1);
				}

				return { ...prevObj, users: prevObjUser, count: prevObj.count - 1 };
			});
			setIsOpenDeleteModal(false);
			successNotification(message.ROLE_INVITE_DELETE);
		}
		setIsLoading(false);
	};

	const renderArchiveConfirmationModal = useMemo(() => {
		if (isBlockedData.isBlocked) {
			return {
				desc: `Unblocking ${selectedUserName} user will unblock all the access. `,
				boldDes: `Do you still want to unblock ${selectedUserName} user?`,
			};
		} else if (isBlockedData.isDeleted) {
			return {
				desc: `Removing ${selectedUserName} user will remove user’s permissions from the role. `,
				boldDes: `Do you still want to remove ${selectedUserName} user?`,
			};
		} else if (!isBlockedData.isBlocked) {
			return {
				desc: `Blocking ${selectedUserName} user will block all the access. `,
				boldDes: `Do you still want to block ${selectedUserName} user?`,
			};
		} else {
			return {
				desc: '',
				boldDes: '',
			};
		}
	}, [isBlockedData, selectedUserName]);

	const handleCloseModal = useCallback(() => {
		setIsInviteModalOpen(false);
	}, []);

	const handleInviteUser = useCallback(
		async (payload: InviteFormInput) => {
			payload['roles'] = [{ role: selectedUserRole?._id ?? '' }];
			const resp = await inviteUser(payload);

			if (resp?._id) {
				setIsInviteModalOpen(false);
				setSelectedUserRole((pre) => ({
					...pre,
					users: [{ ...resp }, ...pre.users],
					count: (pre.count ?? 0) + 1,
				}));
			}
		},
		[selectedUserRole]
	);

	const confirmationLabel = useMemo(() => {
		if (isBlockedData.isBlocked) {
			return 'Unblock';
		} else if (isBlockedData.isDeleted) {
			return 'Remove';
		} else if (!isBlockedData.isBlocked) {
			return 'Block';
		} else {
			return 'Delete';
		}
	}, [isBlockedData]);

	const handleBlockedUser = (id: string, isBlocked: boolean, name: string) => {
		setIsOpenDeleteModal(true);
		setSelectedId(id);
		setBlockedData((prev) => ({ ...prev, isBlocked, isDeleted: false }));
		setSelectedUserName(name);
	};

	const handleDeleteUser = (id: string, name: string) => {
		setIsOpenDeleteModal(true);
		setSelectedId(id);
		setBlockedData((prev) => ({ ...prev, isDeleted: true }));
		setSelectedUserName(name);
	};

	return (
		<div className="AssinedUser--container">
			<div className="AssinedUser--container__header">
				<div className="AssinedUser--container__header__title">
					Assigned Users:{' '}
					<span className="AssinedUser--container__header__title--usersCount">
						({selectedUserRole?.count ?? 0}){' '}
					</span>
				</div>
				{isUserPermissionWrite && !!selectedUserRole?.count && (
					<div
						className="AssinedUser--container__header--inviteUser"
						onClick={handleInviteAssignedUser}
					>
						Invite user
					</div>
				)}
			</div>

			<div className="AssinedUser--container__userList">
				{selectedUserRole?.users?.length ? (
					selectedUserRole?.users?.map((user: any) => {
						return (
							<div
								className="AssinedUser--container__userList--data"
								key={user?._id}
							>
								<div className="AssinedUser--container__userList--data__profile">
									{user?.image ? (
										<Image fileName={`${user?.image}`} />
									) : (
										<LatterAvater
											firstName={`${getInitials(user?.firstName || '')}`}
											lastName={`${getInitials(user?.lastName || '')}`}
										/>
									)}
								</div>
								<div className="AssinedUser--container__userList--data__label">
									<div>
										<div className="AssinedUser--container__userList--data__label--name">
											{`${user?.firstName ?? 'A'} ${user?.lastName ?? 'B'}`}
										</div>
										<div className="AssinedUser--container__userList--data__label--account">
											{user?.email ?? 'NA'}
										</div>
									</div>
									<div className="AssinedUser--container__currentTag">
										{user.isRoleBlocked ? (
											<div className="AssinedUser--container__blockTag">
												Blocked
											</div>
										) : (
											user?.inviteStatus === 'pending' && (
												<div className="AssinedUser--container__pendingTag">
													Pending
												</div>
											)
										)}
									</div>
								</div>
								{isUserPermissionWrite ? (
									<DropInfoOptions
										showEdit={false}
										handleDelete={() =>
											handleDeleteUser(
												user._id ?? '',
												`${user?.firstName} ${user?.lastName}`
											)
										}
										deleteLabel="Remove user"
										blockedLabel={
											!user.isRoleBlocked ? 'Block access' : 'Unblock access'
										}
										handleBlockedUser={() =>
											handleBlockedUser(
												user._id ?? '',
												user.isRoleBlocked ?? false,
												`${user?.firstName} ${user?.lastName}`
											)
										}
									/>
								) : null}
							</div>
						);
					})
				) : (
					<NoUserAssined
						handleInviteUser={handleInviteAssignedUser}
						isUserPermissionWrite={isUserPermissionWrite}
					/>
				)}
			</div>

			{isInviteModalOpen && (
				<InviteUserForm
					handleInviteUser={handleInviteUser}
					handleCloseModal={handleCloseModal}
					isApiCalling={inviteUserLoader}
					isInviteModalOpen={isInviteModalOpen}
				/>
			)}
			{isOpenDeleteModal && (
				<ConfirmationModal
					description={renderArchiveConfirmationModal.desc}
					boldDescription={renderArchiveConfirmationModal.boldDes}
					label={confirmationLabel}
					title="Are you sure?"
					visible={isOpenDeleteModal}
					showLoader={isLoading}
					handleModal={handleDeleteUserRole}
				/>
			)}
		</div>
	);
};
