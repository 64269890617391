import { FC, useEffect, useMemo } from 'react';
import { NumberWithCommas } from 'utils';

const chartWidth = '100%';

interface IPieChart {
	isLoading: boolean;
	title: string;
	approved: number;
	rejected: number;
	underReview: number;
	pending: number;
}

export const PieChart: FC<IPieChart> = ({
	isLoading,
	title,
	approved,
	rejected,
	underReview,
	pending,
}) => {
	const totalNo = useMemo(() => {
		const value = approved + rejected + underReview + pending;
		return value || 0;
	}, [approved, rejected, underReview, pending]);

	useEffect(() => {
		const charts =
			(window as any).Highcharts?.chart(title, {
				chart: {
					type: 'pie',
					plotBackgroundColor: null,
					plotBorderWidth: null,
					plotShadow: false,
					animation: true,
					spacingTop: 0,
					spacingRight: 0,
					spacingBottom: 0,
					spacingLeft: 0,
					height: 250,
				},
				title: {
					text: title,
					align: 'center',
					style: {
						color: '#1D1E21',
						fontWeight: '600',
						fontSize: '20px',
						lineHeight: '32px',
					},
				},

				subtitle: {
					text: `Total Number : <span style="font-weight:600;color:#363A45">${NumberWithCommas(
						totalNo
					)}</span>`,
					align: 'right',
					verticalAlign: 'bottom',
					y: 0,
					x: -40,
					style: {
						color: '#7C8398',
						fontWeight: '500',
						fontSize: '14px',
						lineHeight: '20px',
					},
				},

				colors: ['#2E57DB', '#A5CFFD', '#4D80EF', '#75B1FB'],
				exporting: {
					enabled: false,
				},
				credits: {
					enabled: false,
				},
				tooltip: {
					headerFormat: '<b>{series.name}</b></br>',
					pointFormat: '{point.name}: <b>{point.y}</b>',
				},
				accessibility: {
					enabled: false,
				},

				legend: {
					labelFormatter: function () {
						return (
							'<div style="text-align: left; width:130px;float:left;">' +
							this.name +
							'</div><div style="width:40px; float:left;text-align:left;">' +
							'  &nbsp' +
							NumberWithCommas(this.y) +
							'</div>'
						);
					},
					title: {
						text: 'Status',
						style: {
							color: '#1D1E21',
							fontWeight: '600',
							fontSize: '16px',
							lineHeight: '24px',
						},
					},
					itemMarginTop: 4,
					align: 'left',
					alignColumns: true,
					useHTML: true,
					verticalAlign: 'middle',
					layout: 'vertical',
					x: 0,
					y: 0,
					symbolRadius: 0,
					itemStyle: {
						color: '#3D3D3D',
						fontWeight: '500',
						fontSize: '12px',
						lineHeight: '16px',
						cursor: 'default',
					},
				},

				plotOptions: {
					pie: {
						states: {
							inactive: {
								enabled: false,
							},
						},
						showInLegend: true,
						borderColor: null,
						borderRadius: 0,
						dataLabels: {
							enabled: false,
						},
						point: {
							events: {
								legendItemClick: function (e) {
									e.preventDefault();
								},
							},
						},
					},
				},

				series: [
					{
						states: {
							hover: {
								enabled: false,
							},
						},
						name: title,
						data: [
							{
								name: 'Approved',
								y: approved,
							},
							{
								name: 'Rejected',
								y: rejected,
							},
							{
								name: 'Under Review',
								y: underReview,
							},
							{
								name: 'Pending',
								y: pending,
							},
						],
					},
				],
				responsive: {
					rules: [
						{
							condition: {
								maxWidth: 230,
							},
							chartOptions: {
								chart: {
									height: 300,
								},
								legend: {
									itemMarginTop: 4,
									align: 'center',
									alignColumns: true,
									useHTML: false,
									verticalAlign: 'bottom',
									layout: 'vertical',
									x: null,
									y: null,
								},
							},
						},
					],
				},
			}) ?? {};

		if (isLoading) {
			charts?.showLoading();
		} else {
			charts?.hideLoading();
		}
	}, [isLoading, title, totalNo, approved, rejected, underReview, pending]);

	return (
		<div
			style={{
				backgroundColor: 'transparent',
				width: chartWidth,
				overflowX: 'auto',
			}}
		>
			<div id={title} />
		</div>
	);
};
