import { useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Button, Loader, ReactDropdown } from '@storybook';
import Modal from '@storybook/new-modal/modal';

import {
	TimeIntervalOptions,
	TimeIntervalVerifications,
} from '../../constants';
import { useVerificationTimeInterval } from '../../hooks';
import {
	IsVerificationModalEditableState,
	IsVerificationsModalOpenState,
	SelectedBusinessState,
	TimeIntervalVerificationDataState,
} from '../../states';

import './time-interval.scss';

export const VerificationTimeInterval = () => {
	// globle states
	const IsModalOpen = useRecoilValue(IsVerificationsModalOpenState);
	const [isEditable, setIsEditable] = useRecoilState(
		IsVerificationModalEditableState
	);
	const selectedBusiness = useRecoilValue(SelectedBusinessState);
	const verificationData = useRecoilValue(TimeIntervalVerificationDataState);

	// hooks
	const {
		handleCloseTimeIntervalModal,
		handleSaveTimeInterval,
		handleTimeChange,
		handleIntervalChange,
		loading,
	} = useVerificationTimeInterval();

	const { userName, userEmail } = useMemo(() => {
		const { users } = selectedBusiness ?? {};
		const { firstName, lastName, email } = users ?? {};
		return {
			userEmail: email ?? '--',
			userName: firstName && lastName ? `${firstName} ${lastName}` : '--',
		};
	}, [selectedBusiness]);

	const renderFooter = useMemo(
		() => (
			<div className="VerificationTimeInterval__footer">
				<Button
					type="button__filled button__filled--secondary button__large"
					label={'Cancel'}
					handleClick={handleCloseTimeIntervalModal}
				/>
				<Button
					type="button__filled button__filled--primary button__large"
					label={loading ? <Loader dimension={18} /> : 'Save'}
					handleClick={() =>
						handleSaveTimeInterval(selectedBusiness, verificationData)
					}
					disabled={loading}
				/>
			</div>
		),
		[selectedBusiness, verificationData, loading]
	);

	const renderModalBody = useMemo(
		() => (
			<div className="VerificationTimeInterval__body">
				<div className="VerificationTimeInterval__detailsWrapper">
					<div className="VerificationTimeInterval__companyName">
						{`${userName} ( ${userEmail} ) `}
					</div>
					{!isEditable ? (
						<i
							className="ri-edit-line VerificationTimeInterval__editBtn"
							onClick={() => setIsEditable(true)}
						/>
					) : (
						<></>
					)}
				</div>
				{TimeIntervalVerifications.map(({ key, label, time, interval }) => (
					<div
						key={key}
						className="VerificationTimeInterval__verificationWrapper"
					>
						<div className="VerificationTimeInterval__verificationLabel">
							{label}
						</div>
						{isEditable ? (
							<>
								<input
									type="text"
									className={`VerificationTimeInterval__input`}
									onChange={(e) => handleTimeChange(e, key)}
									value={verificationData[key as 'kyc']?.time ?? time}
								/>
								<ReactDropdown
									options={TimeIntervalOptions}
									defaultValue={
										verificationData[key as 'kyc']?.interval ?? interval
									}
									handleChangeSelect={(e) => handleIntervalChange(e, key)}
									Isdisable
								/>
							</>
						) : (
							<div className="VerificationTimeInterval__editWrapper">
								<div className="VerificationTimeInterval__editTime">
									: {verificationData[key as 'kyc']?.time ?? time}
								</div>
								<div className="VerificationTimeInterval__editDays">
									{interval.label}
								</div>
							</div>
						)}
					</div>
				))}
			</div>
		),
		[isEditable, userName, userEmail, verificationData]
	);

	return (
		<Modal
			isOpen={IsModalOpen}
			closeModal={handleCloseTimeIntervalModal}
			className="VerificationTimeInterval"
			modalName="verification-time-interval-modal"
			isStopOutsideClick={false}
			title={
				<div className="VerificationTimeInterval__header">
					<div className="VerificationTimeInterval__title">
						{isEditable
							? 'Set verification time intervals'
							: 'Verification time intervals'}
					</div>
					<div className="VerificationTimeInterval__sub-title">
						Customize how often end-users need to undergo verifications.
					</div>
				</div>
			}
		>
			{renderModalBody}
			{isEditable && renderFooter}
		</Modal>
	);
};
