import { FC } from 'react';
import Datasheet from 'react-datasheet';
import { Loader } from '@storybook/loader';

import './editable-table.scss';

interface IEditableTable {
	grid: any;
	handleChange?: any;
	loading?: boolean;
}
export const EditableTable: FC<IEditableTable> = ({
	grid: data,
	handleChange,
	loading = false,
}) => {
	const handleValueRenderer = (cell) => cell.value;
	const onCellsChanged = (changes) => {
		if (handleChange) {
			handleChange(changes);
		} else {
			const grid = structuredClone(data);
			changes.forEach(({ row, col, value }) => {
				grid[row][col] = { ...grid[row][col], value };
			});
		}
	};
	const handleOnContextMenu = (e, cell) =>
		cell.readOnly ? e.preventDefault() : null;

	return loading ? (
		<Loader />
	) : (
		<Datasheet
			data={data}
			valueRenderer={handleValueRenderer}
			onContextMenu={handleOnContextMenu}
			onCellsChanged={onCellsChanged}
		/>
	);
};
