import { useState } from 'react';
import { Loader } from '@storybook';

import './api-dashboard.scss';

const url =
	'https://p.datadoghq.com/sb/c7d8bf64-59f2-11ed-9b3b-da7ad0900002-39cf4e49d923a135250882cba6ed030e';

export const ApiDashboard = () => {
	const [isLoaderIframe, setIsLoaderIframe] = useState(true);

	return (
		<div style={{ position: 'relative' }}>
			{isLoaderIframe && (
				<div className="PipelineBody--iframe-loader">
					<Loader className="loader-blue" dimension={60} />
				</div>
			)}
			<iframe
				src={url}
				title="API Dashboard"
				width="100%"
				height="100%"
				allowFullScreen={true}
				style={{ backgroundColor: '#ffffff' }}
				onLoad={() => setIsLoaderIframe(false)}
				className='api-dashboard--iframe'
			/>
		</div>
	);
};
