import { Button, Loader } from '@storybook';
import './pagination.scss';

export const Pagination = ({
	isLoaded,
	pageNumber,
	pageCount,
	handlePrevClick,
	handleNextClick,
	optionalClass = '',
}) => {
	return (
		<div className={`pagination__container ${optionalClass}`}>
			<Button
				type="button button__filled--secondary button__small"
				handleClick={handlePrevClick}
				disabled={isLoaded || pageNumber === 1}
				icon="ri-arrow-left-s-line"
			/>

			<div className="loader-text">
				{isLoaded ? <Loader dimension={15} /> : pageNumber} of{' '}
				{isLoaded ? <Loader dimension={15} /> : pageCount}
			</div>
			<Button
				type="button button__filled--secondary button__small"
				handleClick={handleNextClick}
				disabled={isLoaded || pageNumber === pageCount}
				icon="ri-arrow-right-s-line"
			/>
		</div>
	);
};
