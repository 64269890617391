import { FC } from 'react';
import { ISessionInfoChild, ISkeleton } from 'views';

export const SessionDetailsChild: FC<ISkeleton> = ({ height, width }) => {
	return (
		<div className="session_container">
			<div
				className="session-loader-light"
				style={{ height: height, width: width }}
			/>{' '}
			<div>:</div>{' '}
			<div
				className="session-loader-light"
				style={{ height: height, width: width }}
			/>
		</div>
	);
};

export const SessionDetailsSkeletonLoader: FC = () => {
	const numberOfLoaders = [1, 2, 3, 4, 5];

	return (
		<div id="parent-session-details">
			{numberOfLoaders.map((keys) => (
				<SessionDetailsChild key={keys} height={8} width={200} />
			))}
		</div>
	);
};

export const SessionInfoChild: FC<ISessionInfoChild> = ({
	height,
	width,
	borderRadius,
	isPayment,
}) => {
	return (
		<div
			id="sessioninformation"
			style={{ width: isPayment ? `${width}vw` : `${width}px` }}
		>
			<span
				className="loaderlight"
				style={{ height: `${height}px`, borderRadius: `${borderRadius}px` }}
			/>
		</div>
	);
};

export const SessionInformationLoader = () => {
	const numberOfLoaders = [1, 2, 3, 4, 5, 6];

	return (
		<div className="session-information--loader">
			{numberOfLoaders.map((keys) => (
				<div
					className="session-info__body__details session-information--loader__subcontainer"
					key={keys}
				>
					<div className="session-info__body__icon-container">
						<SessionInfoChild height={40} width={40} borderRadius={8} />
					</div>
					<div className="session-info__body__detailed">
						<SessionInfoChild height={8} width={100} />
						<SessionInfoChild height={8} width={120} />
					</div>
				</div>
			))}
		</div>
	);
};

export const SessionActivitiyLogLoader = () => {
	const numberOfLoadersActivity = [1, 2, 3, 4, 5, 6];
	return (
		<div>
			{numberOfLoadersActivity.map((keys) => (
				<div
					className="activity-log-skelton-profile--container allactivity-details--container"
					key={keys}
				>
					<div className="activity-log-skelton-profile__skeleton_loader">
						<SessionInfoChild height={45} width={45} borderRadius={50} />
					</div>
					<div className="comment-head-container" id="allactivity-details">
						<div className="commentor-user-details">
							<SessionInfoChild height={8} width={300} borderRadius={50} />
						</div>
						<div className="comment-message">
							<SessionInfoChild height={8} width={150} borderRadius={50} />
						</div>
					</div>
				</div>
			))}
		</div>
	);
};
