import { useMemo } from 'react';

import { useRecoilValue } from 'recoil';
import {
	ActionComp,
	IEnvelopeListDetails,
	IsFileUploadState,
	IUploadRowData,
	UploadDocHeader,
	UploadedEnvelopeDocsState,
} from 'views';
import { DocumentTable } from '../doc-table';
import Loaders from './slider';
import { Image } from '@storybook';

export const UploadedFiles = () => {
	const envelopeData = useRecoilValue(UploadedEnvelopeDocsState);
	const isLoading = useRecoilValue(IsFileUploadState);

	const { data: addedFiles } = envelopeData;

	const renderRows = useMemo(() => {
		const rows: IUploadRowData[] = [];
		const filesToProcess = Array.isArray(addedFiles)
			? addedFiles
			: [addedFiles];

		filesToProcess.forEach((doc: IEnvelopeListDetails, index: number) => {
			if (doc) {
				let row = {} as IUploadRowData;
				UploadDocHeader.forEach(({ format, key }) => {
					if (format === 'string' && key === 'documentName') {
						return (row[key] = addedFiles?.originalname);
					}
					if (format === 'number' && key === 'size') {
						const sizeInMB = Number(addedFiles?.size) / (1024 * 1024);

						return (row[key] = sizeInMB.toFixed(2) + ' MB');
					}
					if (format === 'jsx' && key === 'actions') {
						return (row[key] = <ActionComp doc={doc} index={index} />);
					}

					return (row = {
						...row,
						[key]: doc[key as keyof IEnvelopeListDetails],
					});
				});
				rows.push(row);
			}
		});

		return rows;
	}, [addedFiles]);

	return (
		<div className="uploaded-files">
			{isLoading ? (
				<>
					<div className="loade-files">
						<div className="loade-files__image-doc">
							<Image fileName="DOCX.svg" />
						</div>

						<Loaders />
						<p className="loade-files__upload">Uploading...</p>
					</div>
				</>
			) : (
				addedFiles?.originalname && (
					<DocumentTable rows={renderRows} column={UploadDocHeader} />
				)
			)}
		</div>
	);
};
