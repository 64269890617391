import { Suspense, useEffect, useMemo } from 'react';
import {
	useNavigate,
	useLocation,
	Navigate,
	useRoutes,
} from 'react-router-dom';

import { ROUTES, useRoutesModule } from 'routes';
import { Loader } from '@storybook';
import { Sidebar } from 'views';
import { CONDITION_SIDEBAR_TAB, useDashboard } from './hooks';

import './dashboard.scss';

const paths = {
	'/signin': true,
	'/': true,
};

export const Dashboard = () => {
	const { BUSINESSES } = ROUTES;

	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { getUserDetail, customUserTab } = useDashboard();
	const { ALL_ROUTES_MODULE } = useRoutesModule();

	useEffect(() => {
		getUserDetail();
	}, []);

	useEffect(() => {
		if (!paths[pathname]) {
			navigate(pathname);
		} else {
			navigate('./businesses');
		}
	}, []);

	const getCustomRoutes = useMemo(() => {
		const allFilteredRoutes = [];

		for (let i = 0; i < customUserTab?.length; i++) {
			const isValidPath =
				ALL_ROUTES_MODULE[CONDITION_SIDEBAR_TAB[customUserTab?.[i]]];
			if (isValidPath) {
				allFilteredRoutes.push(isValidPath);
			}
		}

		if (!customUserTab.includes('businesses') && customUserTab?.length > 0) {
			const tabs = (ALL_ROUTES_MODULE as any)[
				CONDITION_SIDEBAR_TAB[customUserTab?.[0]] as string
			];

			allFilteredRoutes.push({
				path: '*',
				element: <Navigate to={tabs.path} />,
			});
		} else {
			allFilteredRoutes.push({
				path: '*',
				element: <Navigate to={BUSINESSES} />,
			});
		}

		return allFilteredRoutes;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customUserTab]);

	const elements = useRoutes(getCustomRoutes);

	if (customUserTab.length === 0) {
		return (
			<div className="dashboard-container">
				<div className="dashboard-body__loader">
					<Loader dimension={50} />
				</div>
			</div>
		);
	}

	return (
		<div className="dashboard-container">
			<div className="dashboard-body">
				<Sidebar />
				<Suspense fallback={<Loader dimension={20} />}>{elements}</Suspense>
			</div>
		</div>
	);
};
