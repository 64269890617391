import './revenue-card-skeleton.scss';

export const RevenueCardSkeleton = () => {
	return (
		<div className="revenue-details-skeleton-container">
			{Array(4)
				.fill(1)
				.map((index) => {
					return (
						<div key={`revenue_${index}`} className="revenue-details-overview">
							<div className="skeleton-loader-icon"></div>
							<div className="revenue-details-overview-text">
								<div className="revenue-details-profile-header">
									<p className="revenue-details-profit"></p>
									<p className="revenue-details-title"></p>
								</div>
							</div>
						</div>
					);
				})}
		</div>
	);
};
