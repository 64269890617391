import { useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import {
	ActivityActionName,
	ChartTitleActivity,
	TransactionsAnalyticsChartState,
} from 'views';
import { API_URL, HEADER_TITLE } from 'constant';
import { Header } from 'components';
import { useNetwork } from 'hooks';
import { PieChart } from '../analyticsChart';

import './onboarding-flow-analytics.scss';

export const OnboardingFlowAnalytics: React.FC<{ id: string }> = ({ id }) => {
	const [transactionsAnalyticsRevenue, setTransactionsAnalyticsRevenue] =
		useRecoilState(TransactionsAnalyticsChartState);
	const {
		get: getOnboardingStatus,
		data: onboardingStatusData,
		loading,
	} = useNetwork();

	useEffect(() => {
		if (!Object.keys(transactionsAnalyticsRevenue).length && id) {
			getOnboardingStatus(`${API_URL.ANALYTICS}?business=${id}`);
		}
	}, [transactionsAnalyticsRevenue, id]);

	useEffect(() => {
		if (onboardingStatusData?.data?.length) {
			setTransactionsAnalyticsRevenue(onboardingStatusData?.data?.[0]);
		}
	}, [onboardingStatusData?.data]);

	const renderCharts = useMemo(() => {
		return ActivityActionName.map((item) => (
			<div key={item} className="chart-container">
				<PieChart
					isLoading={loading}
					title={ChartTitleActivity?.[item]}
					approved={transactionsAnalyticsRevenue?.[item]?.completed || 0}
					rejected={transactionsAnalyticsRevenue?.[item]?.rejected || 0}
					underReview={transactionsAnalyticsRevenue?.[item]?.processing || 0}
					pending={transactionsAnalyticsRevenue?.[item]?.pending || 0}
				/>
			</div>
		));
	}, [loading, transactionsAnalyticsRevenue]);

	return (
		<div className="onboarding-flow-analytics">
			<Header title={HEADER_TITLE.ANALYTICS} />
			<div className="onboarding-flow-analytics_container">{renderCharts}</div>
		</div>
	);
};
