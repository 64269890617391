import { RevenueCardSkeleton } from '@storybook';
import { NumberWithCommas } from 'utils';

import Dollar from '../../assets/svg/dollar.svg';
import month from '../../assets/svg/month.svg';
import pay from '../../assets/svg/pay.svg';
import business from '../../assets/svg/business.svg';
import session from '../../assets/svg/session.svg';
import onboarding from '../../assets/svg/onboarding.svg';

import './detailed-revenue.scss';

export const DetailedRevenue = (props: any) => {
	const { loading, data } = props;
	const getIcon = (icon: string) => {
		if (icon === 'dollar') {
			return Dollar;
		} else if (icon === 'pay') {
			return pay;
		} else if (icon === 'month') {
			return month;
		} else if (icon === 'session') {
			return session;
		} else if (icon === 'onboarding') {
			return onboarding;
		} else {
			return business;
		}
	};

	// const getmarginColor = (value: string): string => {
	// 	if (value.includes('+')) {
	// 		return 'revenue-margin-positive';
	// 	} else {
	// 		return 'revenue-margin-negative';
	// 	}
	// };

	const renderRevenueOverview = () => {
		return data?.map((items: any) => {
			return (
				<div
					key={items.icon + '_' + items.title}
					className={`revenue-details-overview`}
				>
					<img
						className={items.icon}
						src={getIcon(items.icon)}
						alt={items.icon}
					></img>
					<div className="revenue-details-overview-text">
						<div className="revenue-details-profile-header">
							<p className={`revenue-details-profit  ${items.icon}`}>
								{NumberWithCommas(items.total)}
							</p>
							{/* <p
								className={`revenue-details-margin ${getmarginColor(
									items.profit
								)}`}
							>
								{items.profit}
							</p> */}
						</div>
						<p className="revenue-details-title">{items.title}</p>
					</div>
				</div>
			);
		});
	};

	return <>{loading ? <RevenueCardSkeleton /> : renderRevenueOverview()}</>;
};
