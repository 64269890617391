import { FC, ReactNode } from 'react';
import Modal from '@storybook/new-modal/modal';

type BusinessModaltype = {
	isOpen: boolean;
	handleCloseViewModal: () => void;
	className: string;
	modalName: string;
	ModalTitle: JSX.Element | ReactNode;
	children: ReactNode;
};

export const BsuinessDetailsModal: FC<BusinessModaltype> = (props) => {
	const {
		isOpen,
		handleCloseViewModal,
		className,
		modalName,
		ModalTitle,
		children,
	} = props;

	const renderModalTitle = () => {
		return <div>{ModalTitle}</div>;
	};
	return (
		<Modal
			isOpen={isOpen}
			closeModal={handleCloseViewModal}
			className={className}
			modalName={modalName}
			title={renderModalTitle()}
		>
			{children}
		</Modal>
	);
};
