import {
	ApiDashboard,
	AuditLog,
	Business,
	Revenue,
	RolePermission,
} from 'views';
import { ROUTES } from './constants';

const { BUSINESSES, ACTIVITIES, REVENUE, API_DASHBOARD, API_ROLE_PERMISSION } =
	ROUTES;

export const useRoutesModule = () => {
	const ALL_ROUTES_MODULE = {
		businesses: {
			path: BUSINESSES,
			element: <Business />,
		},
		auditLog: {
			path: ACTIVITIES,
			element: <AuditLog />,
		},
		revenue: {
			path: REVENUE,
			element: <Revenue />,
		},
		apiDashboard: {
			path: API_DASHBOARD,
			element: <ApiDashboard />,
		},
		userRoles: {
			path: API_ROLE_PERMISSION,
			element: <RolePermission />,
		},
	};

	return { ALL_ROUTES_MODULE };
};
